import { defineStore } from 'pinia'
import {
  ICreatedPattern,
  ICreatedPatternFromSend,
  ICreateProjectPattern,
  IFullTaskPattern,
  IOrganizationPatternCheck,
  IProjectPattern,
  ITaskPattern,
} from '@/types/interfaces'
import { OrganizationAPI } from '@/api/OrganizationAPI'
import { ProjectAPI } from '@/api/ProjectAPI'
import { Tools } from '@/types/enums'
import { TaskAPI } from '@/api/TaskAPI'

interface PatternStoreState {
  isShowPatternWindow: boolean
  isShowDangerModal: boolean
  modalContentCurrentStage: string
  deletedPatternIds: number[]
  deletedProjectIds: number[]
  projectPatterns: IProjectPattern[]
  createProjectPatterns: ICreateProjectPattern[]
  allPatterns: IOrganizationPatternCheck[] |  ICreatedPattern[]
  checkedProjectPatterns: IProjectPattern[]
  patternTasks: ITaskPattern[]
  fullPatternTasks: IFullTaskPattern[]
  //создание паттернов
  activeTool: string
  createdPatterns: ICreatedPatternFromSend[]
  creatingPattern: ICreatedPattern
  //паттерны в разметке
  patternsInMarkup: ICreatedPattern[]
}

export const usePatternStore = defineStore('patternStore', {
  state: (): PatternStoreState => {
    return {
      isShowPatternWindow: false,
      isShowDangerModal: false,
      modalContentCurrentStage: 'patternBase',
      deletedPatternIds: [],
      projectPatterns: [],
      deletedProjectIds: [],
      createProjectPatterns: [],
      allPatterns: [],
      checkedProjectPatterns: [],
      patternTasks: [],
      fullPatternTasks: [],
      //Создание паттернов
      activeTool: Tools.Point,
      createdPatterns: [],
      creatingPattern: {} as ICreatedPattern,
      patternsInMarkup: [],
      //паттерны в разметке
    }
  },

  actions: {
    // Общие
    async getAllPatterns(organizationId: number) {
      const organizationPatterns =
        await OrganizationAPI.getOrganizationPatterns(organizationId)

      this.allPatterns = organizationPatterns.map((item) => ({
        ...item,
        checked: false,
        userAdded: false,
      }))
    },
    resetPatterns() {
      this.allPatterns = []
    },
    resetAll() {
      this.activeTool = Tools.Point
      this.resetPatterns()
      this.resetPatternsForCreateProject()
      this.resetProjectPatterns()
      this.resetPatternTasks()
      this.resetCheckedProjectPatterns()
      this.resetDeletedProjectsIds()
      this.resetDeletedPatternIds()
      this.resetFullPatternTasks()
      this.resetCreatedPatterns()
      this.resetCreatingPattern()
    },
    async deleteCheckedPatternsOrganization() {
      await OrganizationAPI.deletePatternsOrganization(
        this.deletedPatternIds,
        this.patternTasks,
      )
      this.resetAll()
    },

    //Выбор паттернов при создании
    setAllPatternsForCreateProject(value: ICreateProjectPattern[]) {
      this.createProjectPatterns = value
    },
    setPatternForCreateProject(value: IOrganizationPatternCheck) {
      const newValue: ICreateProjectPattern = (({ id, name, userAdded, svg }) => ({
        id,
        name,
        userAdded,
        svg
      }))(value)
      this.createProjectPatterns.push(newValue)
    },
    removePatternForCreateProject(id: number) {
      this.createProjectPatterns = this.createProjectPatterns.filter(
        (item) => item.id !== id,
      )
    },
    resetPatternsForCreateProject() {
      this.createProjectPatterns = []
    },

    // База паттернов
    goToStage(stage: string) {
      this.modalContentCurrentStage = stage
    },
    setIsShowWindow(value: boolean) {
      this.isShowPatternWindow = value
    },
    setIsShowWindowAndHideModal(value: boolean) {
      this.isShowPatternWindow = value
      this.isShowDangerModal = !value
    },
    setIsShowDangerModal(value: boolean, action = 'default') {
      if (action === 'remove') {
        if (this.modalContentCurrentStage === 'patternInProjectTasks') {
          this.deletedPatternIds.pop()
        } else {
          this.resetDeletedPatternIds()
        }
        this.resetDeletedProjectsIds()
        this.resetPatternTasks()
        this.resetCheckedProjectPatterns()
        this.resetFullPatternTasks()
        this.resetProjectPatterns()
        this.modalContentCurrentStage = 'patternBase'
      }
      this.isShowDangerModal = value
    },

    //Удаление в организациях - Выбор паттернов
    setDeletedPatternId(value: number) {
      this.deletedPatternIds.push(value)
    },
    removeDeletedPatternId(value: number) {
      this.deletedPatternIds = this.deletedPatternIds.filter(
        (item) => item !== value,
      )
    },
    resetDeletedPatternIds() {
      this.deletedPatternIds = []
    },

    //Удаление в организациях - выбор проектов
    async getProjectsPatterns() {
      this.projectPatterns = await ProjectAPI.getProjectsPatterns(
        this.deletedPatternIds,
      )
    },
    resetProjectPatterns() {
      this.projectPatterns = []
    },
    setDeletedProjectsId(value: number) {
      this.deletedProjectIds.push(value)
    },
    removeDeletedProjectsId(value: number) {
      this.deletedProjectIds = this.deletedProjectIds.filter(
        (item) => item !== value,
      )
    },
    resetDeletedProjectsIds() {
      this.deletedProjectIds = []
    },
    setCheckedProjectPatterns(value: IProjectPattern[]) {
      this.checkedProjectPatterns = value
    },
    resetCheckedProjectPatterns() {
      this.checkedProjectPatterns = []
    },

    //Удаление в организациях - задачи
    setPatternTasks(value: ITaskPattern) {
      this.patternTasks.push(value)
    },
    setNewValueForTask(name: string, value: any, id: number) {
      this.patternTasks.forEach((task) => {
        if (task.id === id) {
          task[name] = value
        }
      })
    },
    resetPatternTasks() {
      this.patternTasks = []
    },

    // Удаление в проектах - задачи
    async getTasks(patternId: number, projectId: number) {
      return await TaskAPI.getTasksPatterns([patternId], [projectId])
    },
    async getPatternInProject(projectId: number) {
      return await ProjectAPI.getPatternsInProject(projectId)
    },
    async deletePatternInProject(projectId: number) {
      await ProjectAPI.deletePatternInProject(
        this.deletedPatternIds[0],
        projectId,
        this.patternTasks,
      )
      this.removePatternForCreateProject(this.deletedPatternIds[0])
      this.resetDeletedPatternIds()
      this.resetFullPatternTasks()
      this.resetPatternTasks()
    },
    resetFullPatternTasks() {
      this.fullPatternTasks = []
    },

    //Создание паттернов
    setActiveTool(value: string) {
      this.activeTool = value
    },
    addPatternInCreatedPatterns(pattern: ICreatedPatternFromSend) {
      this.createdPatterns.push(pattern)
    },
    setCreatingPattern(key: string, value: any) {
      this.creatingPattern[key] = value
    },
    resetCreatingPattern() {
      this.creatingPattern = {} as ICreatedPattern
    },
    resetCreatedPatterns() {
      this.createdPatterns = []
    },
    removeCreatedPattern(index: number) {
      this.createdPatterns.splice(index, 1)
    },
    setOrganizationInPatterns(organizationId: number) {
      this.createdPatterns.forEach((pattern) => {
        pattern.organization_id = organizationId
      })
    },
    async createNewPatterns() {
      await OrganizationAPI.createNewPatterns(this.createdPatterns)
      this.resetCreatedPatterns()
    },
  // Разметка
    setAllPatterns(value:  ICreatedPattern[]) {
      this.allPatterns = value
    },
    addPatternInMarkup(value: ICreatedPattern) {
      this.patternsInMarkup.push(value)
    }
  },


  getters: {},
})
