import { EgalActionConstructor } from '@/helpers/EgalActionConstructor'
import { APIModels, Microservices } from '@/types/enums'
import { LocalImageClass } from '@/types/types'
import { IFilter, IImage } from '@/types/interfaces'
import { logout } from '@/helpers/logout'

class ImageAPIModel {
  async getImage(id: number): Promise<any> {
    return EgalActionConstructor.getItem(
      Microservices.Core,
      APIModels.Image,
      id,
    )
      .withs(['image_classes', 'commentaries'])
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async getImageFromCloud(link: string): Promise<any> {
    return fetch(link, {
      headers: { 'Content-Type': 'image/*' },
    })
      .then((response) => {
        if (response.status === 404) throw { code: 404, message: 'Not found' }
        if (response.status === 403) throw { code: 403, message: 'No access' }
        return response.blob()
      })
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async getImages(
    id: number,
    perPage = 25,
    page = 1,
    filter: IFilter[] = [],
  ): Promise<any> {
    const innerFilter = [
      {
        field: 'task_id',
        operator: 'eq',
        value: id,
      },
      { field: 'is_uploaded', operator: 'eq', value: true },
      ...filter,
    ]
    const order = [['id', 'asc']]

    return EgalActionConstructor.getItems(Microservices.Core, APIModels.Image)
      .withs(['image_classes'])
      .filter(innerFilter)
      .order(order)
      .setPagination(perPage, page)
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async createImages(images: Partial<IImage>[]): Promise<any> {
    return EgalActionConstructor.createMany(
      Microservices.Core,
      APIModels.Image,
      images,
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async uploadImageToCloud(link: string, image: Blob) {
    return await fetch(link, {
      method: 'PUT',
      body: image,
    }).catch((error) => {
      logout(error)
      throw error
    })
  }

  async createImageClasses(data: LocalImageClass[]): Promise<any> {
    return EgalActionConstructor.createMany(
      Microservices.Core,
      APIModels.ImageClass,
      data,
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async deleteImageClasses(imageClassIds: number[]): Promise<any> {
    return EgalActionConstructor.deleteMany(
      Microservices.Core,
      APIModels.ImageClass,
      { ids: imageClassIds },
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async updateImage(data: Partial<IImage>): Promise<any> {
    return EgalActionConstructor.update(
      Microservices.Core,
      APIModels.Image,
      data,
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async updateManyImages(data: Partial<IImage>[]): Promise<any> {
    return EgalActionConstructor.updateMany(
      Microservices.Core,
      APIModels.Image,
      data,
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }
}

export const ImageAPI = new ImageAPIModel()
