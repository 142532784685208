import { EgalActionConstructor } from '@/helpers/EgalActionConstructor'
import { APIModels, Microservices } from '@/types/enums'
import { LocalLocatedObject } from '@/types/types'
import axios from 'axios'
import { useCookies } from 'vue3-cookies'
import { logout } from '@/helpers/logout'
const { cookies } = useCookies()

class LocatedObjectAPIModel {
  async createObjects(data: LocalLocatedObject[]): Promise<any> {
    return EgalActionConstructor.createMany(
      Microservices.Core,
      APIModels.LocatedObject,
      data,
    )
      .call()
      .then((resp) => {
        return resp
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async updateObjects(data: LocalLocatedObject[]): Promise<any> {
    return EgalActionConstructor.updateMany(
      Microservices.Core,
      APIModels.LocatedObject,
      data,
    )
      .call()
      .then((resp) => {
        return resp
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async saveObjectChanges(data: any): Promise<void> {
    return EgalActionConstructor.custom(
      Microservices.Core,
      APIModels.LocatedObject,
      'save',
      data,
    )
      .call()
      .then((resp) => {
        return resp
      })
      .catch((error) => {
        logout(error)
        return error
      })
  }

  async segmentObjectCreate(data: any): Promise<void> {
    if (cookies.get('core')) {
      // @ts-ignore
      return axios
        .post(`${process.env.VUE_APP_BASE_URL}/ml/segment/`, data, {
          headers: {
            Authorization: cookies.get('core'),
          },
        })
        .then((resp) => {
          return resp.data
        })
        .catch((error) => {
          logout(error)
          throw error
        })
    }
  }

  async createObjectAttribute(data: any): Promise<void> {
    return EgalActionConstructor.create(
      Microservices.Core,
      APIModels.LocatedObjectAttribute,
      data,
    )
      .call()
      .then((resp) => {
        return resp
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async updateObjectAttribute(data: any): Promise<void> {
    return EgalActionConstructor.update(
      Microservices.Core,
      APIModels.LocatedObjectAttribute,
      data,
    )
      .call()
      .then((resp) => {
        return resp
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async deleteObjectAttribute(id: number): Promise<void> {
    return EgalActionConstructor.delete(
      Microservices.Core,
      APIModels.LocatedObjectAttribute,
      {
        id,
      },
    )
      .call()
      .then((resp) => {
        return resp
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }
}

export const LocatedObjectAPI = new LocatedObjectAPIModel()
