import {
  ICreatedPattern,
  IFilter,
  IPaginationOption,
  IProject,
  IProjectAttribute,
  IProjectClass,
  IProjectPattern,
  IProjectStatistic,
  ITaskPattern,
} from '@/types/interfaces'
import { EgalActionConstructor } from '@/helpers/EgalActionConstructor'
import { APIModels, Microservices, ProjectStatuses } from '@/types/enums'
import { logout } from '@/helpers/logout'

class ProjectAPIModel {
  async getProjects({ page, perPage }: IPaginationOption): Promise<any> {
    return await EgalActionConstructor.getItems(
      Microservices.Core,
      APIModels.Project,
    )
      .withs(['organization', 'tasks'])
      .setPagination(perPage, page)
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async getProject(id: number): Promise<any> {
    return EgalActionConstructor.getItem(
      Microservices.Core,
      APIModels.Project,
      id,
    )
      .withs(['project_classes', 'project_class_attributes'])
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async getProjectTasksWithImages(projectId: number, filter: IFilter[]) {
    return await EgalActionConstructor.getItems(
      Microservices.Core,
      APIModels.Task,
    )
      .filter([
        { field: 'project_id', operator: 'eq', value: projectId },
        ...filter,
      ])
      .setPagination(1000, 1)
      .call()
      .then((response) => {
        return response.items
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async getProjectStatistic(
    ProjectId: number,
    taskType: string,
  ): Promise<IProjectStatistic> {
    return await EgalActionConstructor.custom(
      Microservices.Core,
      APIModels.Project,
      'ProjectStatistic',
      { id: ProjectId },
    )
      .filter([
        taskType ? { field: 'task_type', operator: 'eq', value: taskType } : [],
      ])
      .call()
  }

  async getProjectsWithOrganizations({
    page,
    perPage,
  }: IPaginationOption): Promise<any> {
    return await EgalActionConstructor.getItems(
      Microservices.Core,
      APIModels.Organization,
    )
      .withs(['projects'])
      .setPagination(perPage, page)
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async createProject(data: Partial<IProject>): Promise<any | Error> {
    return EgalActionConstructor.create(
      Microservices.Core,
      APIModels.Project,
      data,
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async updateProject(data: Partial<IProject>): Promise<any | Error> {
    return EgalActionConstructor.update(
      Microservices.Core,
      APIModels.Project,
      data,
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async createClasses(data: Partial<IProjectClass>[]): Promise<any | Error> {
    return EgalActionConstructor.createMany(
      Microservices.Core,
      APIModels.ProjectClass,
      data,
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async createClass(data: Partial<IProjectClass>): Promise<any | Error> {
    return EgalActionConstructor.create(
      Microservices.Core,
      APIModels.ProjectClass,
      data,
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async updateClass(data: Partial<IProjectClass>): Promise<any | Error> {
    return EgalActionConstructor.update(
      Microservices.Core,
      APIModels.ProjectClass,
      data,
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async updateClasses(data: Partial<IProjectClass>[]): Promise<any | Error> {
    return EgalActionConstructor.updateMany(
      Microservices.Core,
      APIModels.ProjectClass,
      data,
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async deleteClass(
    data: Pick<IProjectClass, 'id' | 'project_id'>,
  ): Promise<any | Error> {
    return EgalActionConstructor.delete(
      Microservices.Core,
      APIModels.ProjectClass,
      data,
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async createAttribute(
    // @ts-ignore
    data: Pick<IProjectAttribute, 'class_id' | 'project_id'>,
  ): Promise<any> {
    return EgalActionConstructor.create(
      Microservices.Core,
      APIModels.ProjectClassAttribute,
      data,
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async deleteAttribute(
    data: Pick<IProjectAttribute, 'id' | 'class_id'>,
  ): Promise<any> {
    return EgalActionConstructor.delete(
      Microservices.Core,
      APIModels.ProjectClassAttribute,
      data,
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async deleteProject(id: number): Promise<any | Error> {
    return EgalActionConstructor.update(Microservices.Core, APIModels.Project, {
      id,
      status: ProjectStatuses.Deleted,
    })
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async getProjectsPatterns(
    deletePatterns_id: number[],
    perPage = 1000,
    page = 1,
  ): Promise<IProjectPattern[]> {
    return EgalActionConstructor.custom(
      Microservices.Core,
      APIModels.Pattern,
      'getProjects',
      { ids: deletePatterns_id },
    )
      .setPagination(perPage, page)
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async deletePatternInProject(
    patternId: number,
    projectId: number,
    removedTasks: ITaskPattern[],
    perPage = 1000,
    page = 1,
  ): Promise<boolean> {
    return EgalActionConstructor.custom(
      Microservices.Core,
      APIModels.Pattern,
      'detachPatternFromProject',
      {
        pattern_id: patternId,
        project_id: projectId,
        tasks: removedTasks,
      },
    )
      .setPagination(perPage, page)
      .call()
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async getPatternsInProject(
    projectId: number,
    perPage = 1000,
    page = 1,
  ): Promise<ICreatedPattern[]> {
    return EgalActionConstructor.custom(
      Microservices.Core,
      APIModels.Pattern,
      'getPatternsInProject',
      { project_id: projectId },
    )
      .setPagination(perPage, page)
      .call()
      .then((response) => {
        return response.map((item: any) => {
          return {
            ...item,
            coordinates: JSON?.parse(item?.coordinates),
            point_links: JSON?.parse(item?.point_links),
            names: JSON?.parse(item?.names),
          }
        })
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }
}

export const ProjectAPI = new ProjectAPIModel()
