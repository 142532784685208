import { defineStore } from 'pinia'
import { UserAPI } from '@/api/UserAPI'
import {
  IAuthData,
  IPasswordRestoration,
  IRegisterConfirm,
  IRegisterData,
} from '@/types/interfaces'
import { useCookies } from 'vue3-cookies'
import { Microservices } from '@/types/enums'
import { IUser } from '@/types/interfaces'
import { PasswordChangingForm } from '@/types'
import { decipherJWT } from '@egalteam/framework'
import { logout } from '@/helpers/logout'

interface UserStoreState {
  id: string
  microservices: Microservices[]
  user: IUser
  token: string | null
}

const { cookies } = useCookies()

export const useUserStore = defineStore('userStore', {
  state: (): UserStoreState => {
    return {
      id: '',
      microservices: [Microservices.Auth, Microservices.Core],
      user: {} as IUser,
      token: cookies.get('core'),
    }
  },

  actions: {
    /**
     * Логин в системе
     * @param authData - Данные авторизации
     */
    login(authData: IAuthData): Promise<void> {
      this.removeCookies()

      return UserAPI.login(authData).catch((error) => {
        throw error
      })
    },

    register(registerData: IRegisterData): Promise<void> {
      return UserAPI.register(registerData).catch((error) => {
        throw error
      })
    },

    confirmUserWithEmail(confirmData: IRegisterConfirm): Promise<void> {
      return UserAPI.confirmUserWithEmail(confirmData).catch((error) => {
        throw error
      })
    },

    removeCookies() {
      this.microservices.forEach((microservice) => {
        cookies.remove(microservice)
      })
      this.token = null
    },

    // changeToken(token: string) {
    //   this.token = token
    // },

    async getUser(): Promise<void> {
      try {
        const resp = await UserAPI.getUser()
        this.id = resp.id
        this.user = resp
        return resp
      } catch (e) {
        logout(e)
        throw e
      }
    },

    async sendEmailToRestorePassword(email: string): Promise<void> {
      return UserAPI.sendEmailToRestorePassword(email).catch((error) => {
        throw error
      })
    },

    async sendNewPassword(
      newPasswordData: IPasswordRestoration,
    ): Promise<void> {
      return UserAPI.sendNewPassword(newPasswordData).catch((error) => {
        throw error
      })
    },

    async updateUser(
      userData: Pick<IUser, 'first_name' | 'last_name'>,
    ): Promise<void> {
      const requestData = {
        id: this.id,
        first_name: userData.first_name,
        last_name: userData.last_name,
      }

      return UserAPI.updateUser(requestData).then(() => {
        this.user.first_name = userData.first_name
        this.user.last_name = userData.last_name
      })
    },

    async changePassword(passwordData: PasswordChangingForm): Promise<void> {
      const requestData = Object.assign({ id: this.id }, passwordData)

      return UserAPI.changePassword(requestData)
    },

    checkAuth() {
      return !!cookies.get('core')
    },
  },

  getters: {
    getIsUserAdmin(state) {
      if (state.token) {
        const token = decipherJWT(state.token)
        return token.auth_information.roles.includes('admin')
      }
    },
  },
})
