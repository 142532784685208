import { ImageAPI } from '@/api/ImageAPI'
import { useFileConverter } from '@/composables/useFileConverter'
const { tiffImageToBase64, blobToBase64 } = useFileConverter()
/**
 * Calls the passed validators and writes the result
 * @param validators
 * @param value
 * @return {string}
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validate = (validators: any, value: any) => {
  if (validators.length) {
    let result
    for (const validator of validators) {
      if (validator === undefined) continue
      result = validator(value)
      if (result !== '') return result
    }
  }
  return ''
}

export const capitalize = (string: string): string => {
  if (!string) {
    return ''
  }
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Returns the index of the last element in the array where predicate is true, and -1
 * otherwise.
 * @param array The source array to search in
 * @param predicate find calls predicate once for each element of the array, in descending
 * order, until it finds one where predicate returns true. If such an element is found,
 * findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
 */
export function findLastIndex<T>(
  array: Array<T>,
  predicate: (value: T, index: number, obj: T[]) => boolean,
): number {
  let l = array.length
  while (l--) {
    if (predicate(array[l], l, array)) return l
  }
  return -1
}

export async function loadCloudImageWithFallback(
  links: string[],
): Promise<Blob | null> {
  if (!links.length) return null

  const [firstLink, ...fallbackLinks] = links

  try {
    return await ImageAPI.getImageFromCloud(firstLink)
  } catch (e: any) {
    if (e.status === 404) {
      return await loadCloudImageWithFallback(fallbackLinks)
    }

    throw e
  }
}

export async function loadMiniatureImages(images: any) {
  for (const item of images as any) {
    const imageBlob: Blob | null = await loadCloudImageWithFallback([
      item.getMiniaturesLink,
      item.getZipLink,
      item.getLink,
    ])
    if (!imageBlob) continue

    if (imageBlob.type === 'image/tiff') {
      item.base64 = await tiffImageToBase64(imageBlob)
    } else {
      item.base64 = (await blobToBase64(imageBlob)) as string
    }
  }
}
