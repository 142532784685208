export const useCrypto = () => {
  const base64ToSHA256 = async (imageBase64: string): Promise<string> => {
    const msgUnit8 = new TextEncoder().encode(imageBase64)
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUnit8)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('')

    return hashHex
  }

  return {
    base64ToSHA256,
  }
}
