import { EgalActionConstructor } from '@/helpers/EgalActionConstructor'
import { logout } from '@/helpers/logout'
import {
  SubscriptionFormType,
  APIModels,
  Microservices,
  SubscriptionPeriod,
  MySubscriptions, SubscriptionHistory,
} from '@/types'
import { SubscriptionUpdate } from '@/types/interfaces'

class SubscriptionAPIModel {
  async getCurrentSubscription(): Promise<MySubscriptions> {
    try {
      const response = await EgalActionConstructor.custom(
        Microservices.Core,
        APIModels.Subscription,
        'mySubscription',
        {},
      ).call()

      return response
    } catch (error) {
      logout(error)
      throw error
    }
  }
  async getSubscriptionHistory(name?: string, type?: string): Promise<SubscriptionHistory[]> {
    if (name && type) {
      const response = await EgalActionConstructor.getItems(
        Microservices.Core,
        APIModels.SubscriptionHistory,
      )
        .order([[name, type]])
        .call()

      return response.items
    } else {
      const response = await EgalActionConstructor.getItems(
        Microservices.Core,
        APIModels.SubscriptionHistory,
      ).call()

      return response.items
    }
  }

  async update(subscriptionData: SubscriptionUpdate) {
    try {
      return await EgalActionConstructor.update(
        Microservices.Core,
        APIModels.Subscription,
        subscriptionData,
      ).call()
    } catch (error) {
      logout(error)
      throw error
    }
  }

  async subscribe(
    rateId: number,
    form: SubscriptionFormType,
    period: SubscriptionPeriod | null,
  ) {
    try {
      const { name, email, companyName, iin, user_id } = form

      const params: Record<string, any> = {
        rate_id: rateId,
        name,
        email,
        user_id,
        ...(companyName && { organization_name: companyName }),
        ...(iin && { iin }),
      }

      if (period) {
        params.rate_period = period
      }

      const response = await EgalActionConstructor.create(
        Microservices.Core,
        APIModels.SubscriptionRequest,
        params,
      ).call()

      return response
    } catch (error) {
      logout(error)
      throw error
    }
  }
}

export const SubscriptionAPI = new SubscriptionAPIModel()
