import { defineStore } from 'pinia'
import {
  IOrganization,
  IProject,
  IProjectClass,
  IProjectStatistic,
  IProjectWithOrganization,
  ITask,
} from '@/types/interfaces'
import { ProjectAPI } from '@/api/ProjectAPI'
import { TaskStatuses } from '@/types/enums'
import {
  IProjectClassWithError,
  IProjectClassWithErrorAndFlags,
} from '@/types/types'
import { useImageProcessor } from '@/composables/useImageProcessor'
const { preprocessBeforeUpload } = useImageProcessor()
import { ImageAPI } from '@/api/ImageAPI'
type ProjectOrganization = Pick<IOrganization, 'id' | 'name'>

interface ProjectStoreStoreState {
  organizations: ProjectOrganization[]
  projects: IProjectWithOrganization[]
  createProjectData: Partial<IProject>
  createProjectClassesData: IProjectClassWithError[]
  currentProject: IProject
  totalCount: number
  perPage: number
  page: number
  projectStatistic: IProjectStatistic
  currentProjectTasks: ITask[]
  projectLogo: any
  isProjectsLoad: boolean
}

export const useProjectStore = defineStore('projectStore', {
  state: (): ProjectStoreStoreState => {
    return {
      organizations: [] as ProjectOrganization[],
      projects: [],
      createProjectData: {},
      createProjectClassesData: [],
      currentProject: {} as IProject,
      totalCount: 0,
      perPage: 40,
      page: 1,
      projectStatistic: {
        task_statistic: [],
        project_classes_statistic: [],
        images_count: 0,
        tasks_count: 0,
      },
      currentProjectTasks: [],
      projectLogo: null,
      isProjectsLoad: false,
    }
  },

  actions: {
    async getProjects(): Promise<void> {
      this.isProjectsLoad = true
      await ProjectAPI.getProjects({
        page: this.page,
        perPage: this.perPage,
      })
        .then((response) => {
          const organizations = this.getOrganizationFromProjects(response.items)
          this.projects = this.formatProjectData(organizations, response.items)
          this.totalCount = response.total_count
          this.isProjectsLoad = false
        })
        .catch((error) => {
          console.log(error)
        })
    },

    async getProject(id: number): Promise<void> {
      return new Promise((resolve, reject) => {
        ProjectAPI.getProject(id)
          .then(async (organizationData) => {
            this.currentProject = organizationData
            resolve(organizationData)
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
      })
    },

    async getProjectTasksWithImages(
      projectId: number,
      taskName = '',
      filterByStatus: TaskStatuses | '' = '',
    ) {
      const filter = [{ field: 'name', operator: 'coi', value: taskName }]

      if (filterByStatus) {
        filter.push({ field: 'status', operator: 'eq', value: filterByStatus })
      }

      await ProjectAPI.getProjectTasksWithImages(projectId, filter)
        .then((response) => {
          this.currentProjectTasks = response
        })
        .catch((error) => console.log(error))
    },

    async getProjectStatistic(projectId: number, taskType = '') {
      await ProjectAPI.getProjectStatistic(projectId, taskType)
        .then((response) => {
          this.projectStatistic = response
        })
        .catch((error) => console.log(error))
    },

    getOrganizationFromProjects(projects: IProject[]): ProjectOrganization[] {
      const organizations: ProjectOrganization[] = []

      projects.forEach((project) => {
        const isUnique = !organizations.some(
          (organization) => organization.id === project.organization_id,
        )

        if (isUnique) {
          organizations.push({
            id: project.organization.id,
            name: project.organization.name,
          })
        }
      })

      return organizations
    },

    formatProjectData(
      organizations: ProjectOrganization[],
      projects: IProject[],
    ) {
      return organizations.map((organization) => {
        const filteredProjects = projects.filter(
          (project) => project.organization_id === organization.id,
        )

        return {
          organization: organization,
          projects: filteredProjects,
        }
      })
    },

    async loadMoreProjects(): Promise<void> {
      await ProjectAPI.getProjects({
        page: this.page,
        perPage: this.perPage,
      })
        .then((response) => {
          const organizations = this.getOrganizationFromProjects(response.items)
          this.projects.push(
            ...this.formatProjectData(organizations, response.items),
          )
          this.totalCount = response.total_count
        })
        .catch((error) => {
          console.log(error)
        })
    },

    async createProject(projectData: Partial<IProject>): Promise<void> {
      return await ProjectAPI.createProject(projectData)
        .then(async (response) => {
          this.createProjectData.id = response.id

          await this.uploadLogo(response.uploadLink)
        })
        .catch((error) => {
          throw error
        })
    },
    async updateProject(projectData: Partial<IProject>): Promise<void> {
      return await ProjectAPI.updateProject(projectData)
        .then(async (response) => {
          this.createProjectData.id = response.id

          await this.uploadLogo(response.uploadLink)
        })
        .catch((error) => {
          throw error
        })
    },

    async uploadLogo(link: string) {
      if (this.projectLogo) {
        const logoObj = {
          image: await preprocessBeforeUpload(this.projectLogo.file.file),
          link: link,
        }

        await ImageAPI.uploadImageToCloud(logoObj.link, logoObj.image)

        this.projectLogo = null
      }
    },

    async createProjectClasses(
      projectClasses: IProjectClassWithError[],
      projectId: number,
    ) {
      // @ts-ignore
      const projectClassesForRequest = projectClasses.map((projectClass) => ({
        name: projectClass.name,
        color: projectClass.color,
        shades: projectClass.shades,
        project_id: projectId,
        attributes: projectClass.attributes || [],
      }))

      await ProjectAPI.createClasses(projectClassesForRequest).catch(
        (error) => {
          throw error
        },
      )
    },

    async createProjectClass(
      projectClass: IProjectClassWithError | IProjectClassWithErrorAndFlags,
      projectId: number,
    ): Promise<IProjectClass> {
      const projectClassForRequest: Partial<IProjectClass> = {
        name: projectClass.name,
        color: projectClass.color,
        shades: projectClass.shades,
        project_id: projectId,
        attributes: projectClass.attributes || [],
      }

      return await ProjectAPI.createClass(projectClassForRequest)
        .then((resp) => {
          return resp
        })
        .catch((error) => {
          throw error
        })
    },

    async updateProjectClass(
      projectClass: IProjectClassWithErrorAndFlags | IProjectClassWithError,
      projectId: number,
    ): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        const projectClassForRequest = {
          id: projectClass.id,
          name: projectClass.name,
          color: projectClass.color,
          shades: projectClass.shades,
          project_id: projectId,
          attributes: projectClass.attributes || [],
        }

        ProjectAPI.updateClass(projectClassForRequest)
          .then((resp) => {
            resolve(resp)
          })
          .catch((error) => {
            console.error(error)
            reject(error)
          })
      })
    },

    async updateProjectClasses(
      projectClasses:
        | IProjectClassWithErrorAndFlags[]
        | IProjectClassWithError[],
    ): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        const projectClassesForRequest = projectClasses.map(
          (item: IProjectClassWithErrorAndFlags | IProjectClassWithError) => {
            return {
              id: item.id,
              name: item.name,
              color: item.color,
              shades: item.shades,
              project_id: item.project_id,
              attributes: item.attributes || [],
            }
          },
        )

        ProjectAPI.updateClasses(projectClassesForRequest)
          .then((resp) => {
            resolve(resp)
          })
          .catch((error) => {
            console.error(error)
            reject(error)
          })
      })
    },

    async deleteProjectClass(id: number, projectId: number) {
      const projectClassForRequest = {
        id: id,
        project_id: projectId,
      }

      await ProjectAPI.deleteClass(projectClassForRequest).catch((error) => {
        throw error
      })
    },

    async deleteProject(id: number) {
      return await ProjectAPI.deleteProject(id)
        .then(() => {
          return
        })
        .catch((error) => {
          throw error
        })
    },
  },
  getters: {
    getProjectLoad: (state) => state.isProjectsLoad,
  },
})
