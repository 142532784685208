import { onUnmounted } from 'vue'

export const useContextMenu = (
  onOpen: (x: number, y: number, e: MouseEvent) => void,
  onClose: (x: number, y: number, e: MouseEvent) => void,
) => {
  let listenedElements: HTMLElement[] = []
  let lastMouseEvent: MouseEvent = {} as MouseEvent
  let isEventOnElement = false

  const initListeners = (elements: HTMLElement[]) => {
    elements.forEach((element) => {
      element.addEventListener('contextmenu', contextMenuHandler, {
        capture: true,
      })
    })
    document.addEventListener('keyup', contextMenuHandlerCloseHandler)
    document.addEventListener('contextmenu', contextMenuHandlerCloseHandler, {
      capture: true,
    })
    document.addEventListener('click', contextMenuHandlerCloseHandler)

    listenedElements = elements
  }

  const contextMenuHandler = (e: MouseEvent) => {
    if (!e.target) {
      return
    }

    isEventOnElement = true

    e.preventDefault()
    lastMouseEvent = e
    onOpen(e.offsetX, e.offsetY, e)
  }

  const contextMenuHandlerCloseHandler = (e: MouseEvent | KeyboardEvent) => {
    if (e instanceof KeyboardEvent) {
      if (e.key !== 'Escape') {
        return
      }

      onClose(
        lastMouseEvent.offsetX ?? 0,
        lastMouseEvent.offsetY ?? 0,
        lastMouseEvent,
      )
      return
    }

    setTimeout(() => {
      isEventOnElement
        ? (isEventOnElement = false)
        : onClose(
            lastMouseEvent.offsetX ?? 0,
            lastMouseEvent.offsetY ?? 0,
            lastMouseEvent,
          )
    })
  }

  const removeListeners = () => {
    listenedElements.forEach((el) => {
      el.removeEventListener('contextmenu', contextMenuHandler)
    })
    document.removeEventListener('keyup', contextMenuHandlerCloseHandler)
    document.removeEventListener(
      'contextmenu',
      contextMenuHandlerCloseHandler,
      {
        capture: true,
      },
    )
    document.removeEventListener('click', contextMenuHandlerCloseHandler)
  }

  onUnmounted(() => {
    removeListeners()
  })

  return {
    initListeners,
    removeListeners,
  }
}
