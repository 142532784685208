import { EgalActionConstructor } from '@/helpers/EgalActionConstructor'
import { APIModels, Microservices } from '@/types/enums'
import {
  ICreatedPatternFromSend,
  IOrganization,
  IOrganizationPattern,
  IOrganizations,
  IPaginationOption,
} from '@/types/interfaces'
import { ITaskPattern } from '@/types/interfaces'
import { logout } from '@/helpers/logout'

class OrganizationAPIModel {
  /**
   * Получить список организаций
   */
  async getOrganizations({ page, perPage }: IPaginationOption): Promise<any> {
    //TODO: Сделать фильтрацию по членам организации
    // const currentUserFilter = [
    //   // ['owner_id', 'eq', userStore.id],
    //   // 'or',
    //   // ['executors.id', 'eq', userStore.id],
    //   // 'or',
    //   // ['responsibles.id', 'eq', userStore.id],
    // ]

    // const currentUserFilter = [['roles', 'eq', ['owner']]]

    return (
      EgalActionConstructor.getItems(Microservices.Core, APIModels.Organization)
        .withs(['executors', 'responsibles'])
        // .filter(currentUserFilter, 'custom')
        .setPagination(perPage, page)
        .call()
        .then((response) => {
          return response
        })
        .catch((error) => {
          logout(error)
          return error
        })
    )
  }

  async getParticipantOrganizations({
    page,
    perPage,
  }: IPaginationOption): Promise<any> {
    return (
      EgalActionConstructor.custom(
        Microservices.Core,
        APIModels.Organization,
        'getParticipantOrganizations',
        {},
      )
        .withs(['executors', 'responsibles'])
        // .filter(currentUserFilter, 'custom')
        .setPagination(perPage, page)
        .call()
        .then((response) => {
          return response
        })
        .catch((error) => {
          logout(error)
          return error
        })
    )
  }

  /**
   * Получить данные конкретной организации
   * @param id - ID организации
   */
  async getOrganization(id: number): Promise<any> {
    return EgalActionConstructor.getItem(
      Microservices.Core,
      APIModels.Organization,
      id,
    )
      .withs(['owner', 'executors', 'responsibles'])
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        return error
      })
  }

  /**
   * Создать организацию
   * @param data - Данные организации
   */
  async createOrganization(
    data: Partial<IOrganization>,
  ): Promise<number | Error> {
    return EgalActionConstructor.create(
      Microservices.Core,
      APIModels.Organization,
      data,
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  /**
   * Обновить данные организации
   * @param data - Данные организации
   * @param id - ID организации
   */
  async updateOrganization(
    data: Partial<IOrganization>,
    id: number,
  ): Promise<number | Error> {
    return EgalActionConstructor.update(
      Microservices.Core,
      APIModels.Organization,
      { id, ...data },
    )
      .call()
      .then(({ id }) => {
        return id
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  /**
   * Удалить организацию
   * @param id - ID организации
   */
  async deleteOrganization(id: number): Promise<number | Error> {
    return EgalActionConstructor.delete(
      Microservices.Core,
      APIModels.Organization,
      { id },
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  /**
   * Добавить исполнителя
   * @param organization_id
   * @param email
   */
  async addExecutor(organization_id: number, email: string): Promise<any> {
    return EgalActionConstructor.create(
      Microservices.Core,
      APIModels.Executor,
      { organization_id, email },
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  /**
   * Добавить ответственного
   * @param data - Email и ID организации
   */
  async addResponsible(data: {
    organization_id: number
    email: string
  }): Promise<any> {
    return EgalActionConstructor.create(
      Microservices.Core,
      APIModels.Responsible,
      data,
    ).call()
  }

  async getOrganizationPatterns(
    organization_id: number,
    perPage = 1000,
    page = 1,
  ): Promise<IOrganizationPattern[]> {
    return EgalActionConstructor.getItems(Microservices.Core, APIModels.Pattern)
      .filter([
        { field: 'organization_id', operator: 'eq', value: organization_id },
      ])
      .setPagination(perPage, page)
      .call()
      .then((response) => {
        return response.items
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async deletePatternsOrganization(
    patternIds: number[],
    removedTasks: ITaskPattern[],
    perPage = 1000,
    page = 1,
  ): Promise<boolean> {
    return EgalActionConstructor.custom(
      Microservices.Core,
      APIModels.Pattern,
      'removePatterns',
      {
        ids: patternIds,
        tasks: removedTasks,
      },
    )
      .setPagination(perPage, page)
      .call()
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async createNewPatterns(createdPatterns: ICreatedPatternFromSend[]) {
    const dataForBack = createdPatterns.map((item) => ({
      ...item,
      coordinates:
        item.coordinates &&
        JSON.stringify(
          item.coordinates.map((point) => ({
            id: point.id,
            x: point.x,
            y: point.y,
          })),
        ),
      point_links: item.point_links && JSON.stringify(item.point_links),
      names:
        item.coordinates &&
        JSON.stringify(
          item.coordinates
            .filter((point) => point.name)
            .map((point) => ({
              id: point.id,
              name: point.name,
            })),
        ),
    }))
    return EgalActionConstructor.createMany(
      Microservices.Core,
      APIModels.Pattern,
      dataForBack,
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async getExecutorsOrganization(
    perPage = 1000,
    page = 1,
  ): Promise<IOrganizations> {
    return EgalActionConstructor.custom(
      Microservices.Core,
      APIModels.Organization,
      'getParticipantOrganizations',
      {},
    )
      .withs(['executors', 'responsibles'])
      .setPagination(perPage, page)
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }
}

export const OrganizationAPI = new OrganizationAPIModel()
