import {useWorkspaceStore} from "@/store/workspaceStore";
import {computed} from "vue";
import {Tools} from "@/types/enums";

export const useWorkspaceToolbar = () => {
  const workspaceStore = useWorkspaceStore()

  const cursorStyle = computed(() => {
    const cursorMap = {
      [Tools.BoundingBox]: 'crosshair',
      [Tools.Pentagon]: 'crosshair',
      [Tools.Identificator]: 'crosshair',
      [Tools.Hand]: 'grab',
    }

    return `--cursor: ${cursorMap[workspaceStore.selectedTool] || 'default'};`
  })

  return { cursorStyle }
}
