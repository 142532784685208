import {
  MySubscriptions,
  SubscriptionHistory,
  SubscriptionLimit,
} from '@/types'
import { defineStore } from 'pinia'
import { SubscriptionAPI } from '@/api/Subscription'

interface SubscriptionStoreState {
  subscriptionLimit: SubscriptionLimit
  mySubscriptions: MySubscriptions
  subscriptionHistory: SubscriptionHistory[] | null
}

export const useSubscriptionStore = defineStore('subscriptionStore', {
  state: (): SubscriptionStoreState => {
    return {
      subscriptionLimit: {},
      mySubscriptions: {
        organizations: 0,
        organization_members: 0,
        projects: 0,
        tasks: 0,
        period: 0,
        dataset_storage_usage: 0,
        end_at: '',
      },
      subscriptionHistory: null,
    }
  },

  actions: {
    async getSubscriptions(): Promise<void> {
      const mySubscriptions = await SubscriptionAPI.getCurrentSubscription()
      if (!mySubscriptions.rate) return
      let organizations = false
      let projects = false
      let tasks = false
      let members = true
      // let members = mySubscriptions.rate.organization_members
      let exceeded = false

      const organizationsRemains =
        mySubscriptions.rate.organizations - mySubscriptions.organizations
      const projectsRemains =
        mySubscriptions.rate.projects - mySubscriptions.projects
      const tasksRemains = mySubscriptions.rate.tasks - mySubscriptions.tasks
      const membersRemains = mySubscriptions.rate.organization_members - mySubscriptions.organization_members

      if (
        mySubscriptions.rate.organizations === -1 ||
        organizationsRemains > 0
      ) {
        organizations = true
      } else if (organizationsRemains < 0) {
        exceeded = true
      }

      if (mySubscriptions.rate.projects === -1 || projectsRemains > 0) {
        projects = true
      } else if (projectsRemains < 0) {
        exceeded = true
      }

      if (mySubscriptions.rate.tasks === -1 || tasksRemains > 0) {
        tasks = true
      } else if (tasksRemains < 0) {
        exceeded = true
      }

      if (mySubscriptions.rate.organization_members !== -1 && membersRemains < 0) {
        exceeded = true
      }

      if (exceeded) {
        tasks = false
        projects = false
        organizations = false
        members = false
      }

      this.subscriptionLimit = {
        organizations,
        projects,
        tasks,
        membersLimit: members,
      }

      this.mySubscriptions = mySubscriptions
    },
    async getSubscriptionHistory(name?: string, type?: string): Promise<void> {
      this.subscriptionHistory = await SubscriptionAPI.getSubscriptionHistory(
        name,
        type,
      )
    },
  },
})
