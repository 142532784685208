import { Ref, ref } from 'vue'
import {IAnnotation, IAnnotationCoordinatesPolygon, IContextMenuOption} from '@/types/interfaces'
import { WorkspaceContextMenuOptions } from '@/types/enums'
import { useWorkspaceStore } from '@/store/workspaceStore'
import OpenSeadragon from 'openseadragon'
import {injectEventBus} from "@/helpers/EventBus";

export const useWorkspaceContextMenu = (viewer: Ref<OpenSeadragon.Viewer>) => {
  const workspaceStore = useWorkspaceStore()
  const eventBus = injectEventBus()
  const contextMenuState = ref({
    x: 0,
    y: 0,
    state: 'hide',
  })

  const contextMenuOptions: IContextMenuOption[] = [
    {
      label: 'Копировать',
      value: WorkspaceContextMenuOptions.Copy,
      hotKey: 'Ctrl + C',
    },
    {
      label: 'Вставить',
      value: WorkspaceContextMenuOptions.Insert,
      hotKey: 'Ctrl + V',
    },
    {
      label: 'Удалить',
      value: WorkspaceContextMenuOptions.Delete,
      hotKey: 'Delete',
      divider: true,
    },
    {
      label: 'Изменить класс',
      value: WorkspaceContextMenuOptions.ChangeClass,
      hotKey: 'Ctrl + Shift + R',
    },
    {
      label: 'Изменить экземпляр класса',
      value: WorkspaceContextMenuOptions.ChangeInstanceClass,
    },
    {
      label: 'Изменить значение атрибута',
      value: WorkspaceContextMenuOptions.ChangeAttribute,
    },
    // {
    //   label: 'На передний план',
    //   value: WorkspaceContextMenuOptions.FirstPlan,
    //   hotKey: 'Ctrl + ]',
    // },
    // {
    //   label: 'На задний план',
    //   value: WorkspaceContextMenuOptions.LastPlan,
    //   hotKey: 'Ctrl + [',
    // },
  ]

  const onOpenContextMenuCallback = (x: number, y: number) => {
    contextMenuState.value.x = x
    contextMenuState.value.y = y
    contextMenuState.value.state = 'show'
  }

  const onOptionSelected = (option: IContextMenuOption) => {
    switch (option.value) {
      case WorkspaceContextMenuOptions.Copy:
        copyObjects()
        return
      case WorkspaceContextMenuOptions.Insert:
        insertCopiedObject(contextMenuState.value.x, contextMenuState.value.y)
        return
      case WorkspaceContextMenuOptions.Delete:
        if (!Object.keys(workspaceStore.selectedContextMenuObject).length) {
          return
        }

        deleteObject(workspaceStore.selectedContextMenuObject)
        return
      case WorkspaceContextMenuOptions.ChangeClass:
        if (workspaceStore.selectedContextMenuObject.body[4].value) return

        workspaceStore.annotationsFlags.classChanging = true
        return
      case WorkspaceContextMenuOptions.ChangeInstanceClass:
        if (workspaceStore.selectedContextMenuObject.body[4].value) return

        workspaceStore.annotationsFlags.instanceClassChanging = true
        return
      case WorkspaceContextMenuOptions.ChangeAttribute:
        if (workspaceStore.selectedContextMenuObject.body[4].value) return

        workspaceStore.annotationsFlags.attributesChanging = true
        return
      // case WorkspaceContextMenuOptions.FirstPlan:
      //   return
      // case WorkspaceContextMenuOptions.LastPlan:
      //   return
    }
  }

  const onCloseContextMenuCallback = (x: number, y: number) => {
    // Если ивент события появился, когда закрыто контекстное меню, то значит надо вырубить изменение класса
    if (contextMenuState.value.state === 'hide') {
      workspaceStore.annotationsFlags.classChanging = false
      workspaceStore.annotationsFlags.attributesChanging = false
      workspaceStore.annotationsFlags.instanceClassChanging = false
    }

    contextMenuState.value.x = x
    contextMenuState.value.y = y
    contextMenuState.value.state = 'hide'
  }

  const copyObjects = () => {
    const uniqueObjectIds = [
      ...new Set(
        [
          workspaceStore.selectedContextMenuObject,
          workspaceStore.selectedObject,
          ...workspaceStore.selectedObjectGroup,
        ].map((object) => object.id),
      ),
    ].filter((id) => id)

    workspaceStore.copiedObjects = workspaceStore.currentImageObjects.filter(
      (object) => uniqueObjectIds.includes(object.id),
    )
  }
  const insertCopiedObject = (x: number, y: number) => {
    if (!workspaceStore.copiedObjects.length || workspaceStore.copyImageLoader) {
      return
    }

    workspaceStore.copyImageLoader = true

    const isRect = workspaceStore.copiedObjects[0].target.selector.type === 'FragmentSelector'

    const newObjectsIds: string[] = []

    const osdPoint = new OpenSeadragon.Point(x, y)
    const convertedPoint = convertPointerCoordinatesToImage(osdPoint)

    const firstObjectCoordinates = isRect
      ? workspaceStore.convertCoordinatesToCreateRect(workspaceStore.copiedObjects[0].target.selector.value)
      : workspaceStore.convertCoordinatesToCreatePolygon(workspaceStore.copiedObjects[0].target.selector.value)

    // @ts-ignore
    const firstX = isRect ? firstObjectCoordinates.xmin : firstObjectCoordinates[0].x
    // @ts-ignore
    const firstY = isRect ? firstObjectCoordinates.ymin : firstObjectCoordinates[0].y

    const deltaX = firstX - convertedPoint.x
    const deltaY = firstY - convertedPoint.y

    workspaceStore.copiedObjects.forEach((object, index) => {
      const copiedObject: IAnnotation = workspaceStore.cloneDeep(object)
      if (object.target.selector.type === 'FragmentSelector') {
        const copiedObjectCoordinates =
          workspaceStore.convertCoordinatesToCreateRect(
            copiedObject.target.selector.value,
          )

        copiedObjectCoordinates.xmin =
          index === 0 ? convertedPoint.x : copiedObjectCoordinates.xmin - deltaX
        copiedObjectCoordinates.ymin =
          index === 0 ? convertedPoint.y : copiedObjectCoordinates.ymin - deltaY

        copiedObject.target.selector.value =
          workspaceStore.convertRectCoordinatesToAnnotationFormat(
            copiedObjectCoordinates,
          )
      } else {
        const copiedObjectCoordinates =
          workspaceStore.convertCoordinatesToCreatePolygon(
            copiedObject.target.selector.value,
          )

        // Вычисляем новые координаты для перемещения на основе дельты
        const newCoords: IAnnotationCoordinatesPolygon[] = copiedObjectCoordinates.map(
          (point) => {
            return {
              x: point.x - deltaX,
              y: point.y - deltaY,
            }
          },
        )
        // Джоиним координаты в строку
        const stringCoordData = newCoords
          .map((coord) => `${coord.x},${coord.y}`)
          .join(' ')

        copiedObject.target.selector.value = workspaceStore.convertPolygonCoordinatesToAnnotationFormat(stringCoordData)
      }

      copiedObject.id = `${copiedObject.id}-copy-${Date.now()}`

      copiedObject.body[4].value = false
      workspaceStore.annotationsFlags.copied = true
      workspaceStore.currentImageObjects.push(copiedObject)

      newObjectsIds.push(copiedObject.id)
    })



    workspaceStore.dropSelectedObjects()
    workspaceStore.createHook(newObjectsIds)
    workspaceStore.saveChanges().then()
  }

  const convertPointerCoordinatesToImage = (point: OpenSeadragon.Point) => {
    const viewportPoint = viewer.value.viewport.pointFromPixel(point)

    return viewer.value.viewport.viewportToImageCoordinates(viewportPoint)
  }

  const deleteObject = (deletingObject: IAnnotation) => {

    if (deletingObject.body[4].value) return

    const uniqueObjectIds = [
      ...new Set(
        [
          workspaceStore.selectedObject,
          deletingObject,
          ...workspaceStore.selectedObjectGroup,
        ].map((object) => object.id),
      ),
    ].filter((object) => object)

    uniqueObjectIds.forEach((objectId) => {
      const deletingObjectIndex = workspaceStore.currentImageObjects.findIndex(
        (storedObject) => storedObject.id === objectId,
      )
      workspaceStore.annotationsFlags.deleted = true
      workspaceStore.currentImageObjects.splice(deletingObjectIndex, 1)
    })

    workspaceStore.dropSelectedObjects()
    workspaceStore.deleteHook(uniqueObjectIds)
    eventBus.$emit('resetPatternChange')
  }

  return {
    contextMenuState,
    contextMenuOptions,
    onOpenContextMenuCallback,
    onCloseContextMenuCallback,
    onOptionSelected,
    copyObjects,
    insertCopiedObject,
    deleteObject,
  }
}
