import { computed, reactive } from 'vue'

export const useFormValidation = (fieldsCount: number) => {
  const errors = reactive<boolean[]>(new Array(fieldsCount).fill(false))

  const setError = (fieldIndex: number, value: boolean): void => {
    errors[fieldIndex] = value
  }

  const hasErrors = computed(() => {
    return errors.includes(true)
  })

  return { errors, setError, hasErrors }
}
