import { defineStore } from 'pinia'
import { IOrganization, IOrganizationMember } from '@/types/interfaces'
import { OrganizationAPI } from '@/api/OrganizationAPI'
import { UserRoles } from '@/types/enums'

interface OrganizationStoreState {
  organizations: IOrganization[]
  currentOrganization: IOrganization
  sortedMembersData: Omit<IOrganizationMember, 'email'>[]
  totalCount: number
  perPage: number
  page: number
  isOrganizationsLoad: boolean
}

export const useOrganizationStore = defineStore('organizationsStore', {
  state: (): OrganizationStoreState => {
    return {
      organizations: [],
      currentOrganization: {} as IOrganization,
      sortedMembersData: [] as Omit<IOrganizationMember, 'email'>[],
      totalCount: 0,
      perPage: 40,
      page: 1,
      isOrganizationsLoad: false,
    }
  },

  actions: {
    /**
     * Получает список организации и записывает их в стор
     */
    async getOrganizations(): Promise<void> {
      this.isOrganizationsLoad = true

      await OrganizationAPI.getParticipantOrganizations({
        page: this.page,
        perPage: this.perPage,
      })
        .then((response) => {
          // TODO: Фильтр это временный костыль. Фильтровать нужно на бэке
          this.organizations = response.items.filter(
            (organization: any) => organization.isParticipant,
          )
          this.totalCount = response.total_count
          this.isOrganizationsLoad = false
        })
        .catch((error) => {
          console.log(error)
        })
    },

    /**
     * Получает список организаций и добавляет их к существующим в сторе
     */
    async loadMoreOrganizations(): Promise<void> {
      await OrganizationAPI.getParticipantOrganizations({
        page: this.page,
        perPage: this.perPage,
      })
        .then((response) => {
          this.organizations.push(
            ...response.items.filter(
              (organization: any) => organization.isParticipant,
            ),
          )
        })
        .catch((error) => {
          console.log(error)
        })
    },

    /**
     * Получить конкретную организацию
     * @param id - ID организации
     */
    async getOrganization(id: number): Promise<void> {
      await OrganizationAPI.getOrganization(id)
        .then(async (organizationData) => {
          this.currentOrganization = organizationData
          this.currentOrganization.members = this.getMembers(organizationData)
        })
        .catch((error) => {
          console.log(error)
        })
    },

    getMembers(organizationData: any) {
      const owner = {
        id: organizationData.owner.id,
        email: organizationData.owner.email,
        role: UserRoles.Owner,
      }
      let responsibles = []
      let executors = []

      if (organizationData.responsibles) {
        responsibles = organizationData.responsibles.map(
          (responsible: IOrganizationMember) => {
            return {
              id: responsible.id,
              email: responsible.email,
              role: UserRoles.Responsible,
            }
          },
        )
      }

      if (organizationData.executors) {
        executors = organizationData.executors.map(
          (executor: IOrganizationMember) => {
            return {
              id: executor.id,
              email: executor.email,
              role: UserRoles.Executor,
            }
          },
        )
      }

      return [owner, ...responsibles, ...executors]
    },

    /**
     * Создаёт организацию и добавляет в неё участников
     * @param organizationData - Данные организации
     * @param members - Участники организации
     */
    async createOrganization(
      organizationData: Partial<IOrganization>,
      members: IOrganizationMember[],
    ): Promise<void> {
      const requestOrganizationData = {
        ...organizationData,
        executors: members
          .filter((member) => member.role === UserRoles.Executor)
          .map((member) => ({ email: member.email })),
        responsibles: members
          .filter((member) => member.role === UserRoles.Responsible)
          .map((member) => ({ email: member.email })),
      }

      const response: any = await OrganizationAPI.createOrganization(
        requestOrganizationData,
      )
      return response
    },

    /**
     * Редактирует организацию и её участников
     * @param organizationData - Данные организации
     * @param members - Участники организации
     * @param id - ID организации
     */
    async updateOrganization(
      organizationData: Partial<IOrganization>,
      members: IOrganizationMember[],
      id: number,
    ): Promise<void> {
      const requestOrganizationData = {
        ...organizationData,
        executors: members
          .filter((member) => member.role === UserRoles.Executor)
          .map((member) => ({ email: member.email })),
        responsibles: members
          .filter((member) => member.role === UserRoles.Responsible)
          .map((member) => ({ email: member.email })),
      }

      await OrganizationAPI.updateOrganization(
        requestOrganizationData,
        id,
      ).catch((error) => {
        throw error
      })
    },

    /**
     * Удалить организацию.
     * @param id - ID организации
     */
    async deleteOrganization(id: number) {
      return await OrganizationAPI.deleteOrganization(id)
        .then(() => {
          return
        })
        .catch((error) => {
          throw error
        })
    },
  },

  getters: {
    isEmpty: (state) => !state.organizations.length,
    getOrganizationsLoad: (state) => state.isOrganizationsLoad,
  },
})
