import Compressor from 'compressorjs'
import { useFileConverter } from '@/composables/useFileConverter'

export function useImageProcessor() {
  const { tiffToJpeg } = useFileConverter()

  const DEFAULT_COMPRESSOR_OPTIONS: Compressor.Options = {
    quality: 0.6,
    mimeType: 'image/jpeg',
  }

  const compressImage = (
    file: File | Blob,
    options: Compressor.Options = DEFAULT_COMPRESSOR_OPTIONS,
  ): Promise<File | Blob> => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        ...options,
        success: (image: File | Blob) => {
          resolve(image)
        },
        error: (error: Error) => reject(error),
      })
    })
  }

  const preprocessBeforeUpload = async (
    file: File | Blob,
    options?: Compressor.Options,
  ) => {
    let processingFile = file

    if (file.type === 'image/tiff') {
      processingFile = await tiffToJpeg(file)
    }

    return compressImage(processingFile, options)
  }

  return {
    compressImage,
    preprocessBeforeUpload,
  }
}
