import {
  IProjectAttribute,
  IDuplicatesAttributeIndex,
} from '@/types/interfaces'

/**
 * Есть ли дубликаты имени атрибутов
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function hasDuplicateNames(
  objects: IProjectAttribute[] | null | undefined,
) {
  if (objects) {
    const nameSet = new Set()
    for (const obj of objects) {
      const name = obj.name?.toLowerCase().trim()
      if (nameSet.has(name)) {
        return true
      }
      nameSet.add(name)
    }
    return false
  }
}

/**
 * Есть ли дубликаты значений в рамках хотя бы одного атрибута в массиве
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function hasDuplicateValues(
  objects: IProjectAttribute[] | null | undefined,
) {
  if (objects) {
    for (const obj of objects) {
      const values = obj.values
      const uniqueValues = new Set()

      // @ts-ignore
      for (let value: string of values) {
        if (typeof value !== undefined) {
          // @ts-ignore
          value = String(value).toLowerCase().trim()
          if (uniqueValues.has(value)) {
            return true
          }
          uniqueValues.add(value)
        }
      }
    }
    return false
  }
}

/**
 * Объединить все найденые индексы ошибок в рамках 1 атрибута и удалить дубликаты
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function mergeObjectsWithSameObjectIndex(
  objects: IDuplicatesAttributeIndex[],
) {
  const mergedObjects: IDuplicatesAttributeIndex[] = []
  const objectMap = new Map()

  objects.forEach((obj) => {
    const objectIndex = obj.objectIndex

    if (!objectMap.has(objectIndex)) {
      objectMap.set(objectIndex, [])
    }

    objectMap.get(objectIndex).push(...obj.duplicateIndexes)
  })

  objectMap.forEach((duplicateIndexes, objectIndex) => {
    mergedObjects.push({
      objectIndex: objectIndex,
      duplicateIndexes: [...new Set(duplicateIndexes)],
    })
  })

  return mergedObjects
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// @ts-ignore
export function deepClone(obj: any) {
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  if (Array.isArray(obj)) {
    const clonedArray = []
    for (let i = 0; i < obj.length; i++) {
      clonedArray[i] = deepClone(obj[i])
    }
    return clonedArray
  }

  const clonedObj = {}
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      clonedObj[key] = deepClone(obj[key])
    }
  }
  return clonedObj
}
