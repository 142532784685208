import { useUserStore } from '@/store/userStore'

export const logout = (error: any) => {
  if (error?.code === 401 || error?.code === 403 || error?.response?.status === 401 || error?.response?.status === 403 || typeof error === 'string') {
    const userStore = useUserStore()
    userStore.removeCookies()
    // @ts-ignore
    window.location = "/login"
  }
}
