import { defineStore } from 'pinia'
import { ImageAPI } from '@/api/ImageAPI'
import {
  IAnnotation,
  IAnnotationCoordinatesPolygon,
  IAnnotationCoordinatesRect,
  IBinaryObject,
  IClassificationObject,
  IFilter,
  IImage,
  IImageClass,
  ILocatedObject,
  IPaginationOption,
  IProjectAttribute,
  IProjectClass,
  ISelectorType,
  ITask,
} from '@/types/interfaces'
import { TaskAPI } from '@/api/TaskAPI'
import {
  AreaType,
  CheckupStatuses,
  ExecutorRoles,
  MarkupStatuses,
  MarkupTypes,
  TaskStatuses,
  Tools,
  UserRoles,
} from '@/types/enums'
import { LocatedObjectAPI } from '@/api/LocatedObjectAPI'
import { ProjectAPI } from '@/api/ProjectAPI'
import { LocalImageClass } from '@/types/types'
import { useUserStore } from '@/store/userStore'
import { useTaskStore } from '@/store/taskStore'
import { CommentaryApi } from '@/api/CommentaryAPI'
import { BinaryObjectAPI } from '@/api/BinaryObjectAPI'
import { wb } from '@/WorkboxWindow'
import { useWorkspaceMarkup } from '@/composables/useWorkspaceMarkup'
import getAreaTypeId from '@/helpers/getAreaTypeId'
import { injectEventBus } from '@/helpers/EventBus'
import { logout } from '@/helpers/logout'

// TODO: Нужно рефакторить. Разбить стор на композаблы, подчистить и описать методы. Особенно флаги
interface IObjectActionsHistory {
  update: number[]
  delete: number[]
  create: string[]
}

interface WorkspaceStoreState {
  currInd: number
  imageList: IImage[]
  filteredImageList: IImage[]
  imageListRequestOptions: {
    pagination: IPaginationOption
    filter: IFilter[]
  }
  isCheckSaveWhenSwitchingToTheNextImage: boolean
  isCheckSaveWhenSwitchingToThePrevImage: boolean
  сheckSaveWhenSwitchingImageIndex: number
  currentImage: IImage
  currentImageIndex: number
  currentTask: ITask
  currentImageObjects: IAnnotation[]
  projectClasses: IProjectClass[]
  projectAttributes: IProjectAttribute[]
  imageClasses: IImageClass[]
  selectedTool: Tools
  selectedClass: IProjectClass
  selectedClasses: IProjectClass[]
  selectedImageClass: IImageClass
  selectedObject: IAnnotation
  selectedObjectGroup: IAnnotation[] // multiselect
  selectedPattern: any
  userRole: ExecutorRoles | UserRoles
  objectActionsHistory: IObjectActionsHistory
  workspaceHistory: {
    actionsHistory: IObjectActionsHistory[]
    objectsHistory: IAnnotation[][]
  }
  selectedHistoryIndex: number
  selectedContextMenuObject: IAnnotation
  copiedObjects: IAnnotation[]
  autoCopiedObjects: IAnnotation[]
  changedPositionObjects: IAnnotation[]
  annotationsFlags: {
    copied: boolean
    deleted: boolean
    classChanging: boolean
    instanceClassChanging: boolean
    attributesChanging: boolean
    visibilityChanging: boolean
    selectingAll: boolean
    readyForAnnotationSetting: boolean
    clearSelectedAnnotations: boolean
    objectOrderChanged: boolean
  }
  copyObjectsFromPrev: boolean
  viewerProperties: {
    initialZoom: number
    zoomLevel: number
    zoomScale: number
    zoomMagnifier: number
  }
  isImageRendered: boolean
  isCopyOperationsComplete: boolean
  isMaxCopyObjects: boolean
  isWorkspaceFullscreen: boolean
  isImageLoading: boolean
  isImageListUpdating: boolean // Флаг выставляется при подгрузке новой странице imageList. Меняется в useImagePlayer

  segmentedImageListOptions: Omit<IPaginationOption, 'page'>
  segmentedImageList: IImage[][]

  binaryObjectsHistory: {
    create: number[]
    delete: number[]
  }
  fakePoints: any
  distance: number
  showDistance: boolean
  segmentLoader: boolean
  copyImageLoader: boolean
  createObjectLoader: boolean
  segmentError: boolean
  segmentErrorText: string
  lastShadeIndex: number
  lastPosition: number
  isNotUpdateImage: boolean
  eventBus: any
  currentProjectClass: IProjectClass
}

export const useWorkspaceStore = defineStore('workspaceStore', {
  state: (): WorkspaceStoreState => {
    return {
      currentProjectClass: {} as IProjectClass,
      currInd: 0,
      fakePoints: [],
      distance: 20,
      showDistance: false,
      imageList: [],
      filteredImageList: [],
      imageListRequestOptions: {
        pagination: {
          page: 1,
          perPage: 100,
          totalCount: 0,
        },
        filter: [],
      },
      isCheckSaveWhenSwitchingToTheNextImage: false,
      isCheckSaveWhenSwitchingToThePrevImage: false,
      сheckSaveWhenSwitchingImageIndex: 0,
      currentImage: {} as IImage,
      currentImageIndex: 0,
      currentImageObjects: [],
      currentTask: {} as ITask,
      projectClasses: [],
      projectAttributes: [],
      imageClasses: [],
      selectedTool: Tools.Arrow,
      selectedClass: {} as IProjectClass,
      selectedClasses: [],
      selectedImageClass: {} as IImageClass,
      selectedObject: {} as IAnnotation,
      selectedObjectGroup: [], // multiselect
      userRole: undefined as unknown as ExecutorRoles,
      objectActionsHistory: {
        update: [],
        delete: [],
        create: [],
      },
      workspaceHistory: {
        actionsHistory: [],
        objectsHistory: [],
      },
      selectedHistoryIndex: 0,
      selectedContextMenuObject: {} as IAnnotation,
      selectedPattern: null as any,
      copiedObjects: [] as IAnnotation[],
      autoCopiedObjects: [] as IAnnotation[],
      annotationsFlags: {
        copied: false,
        deleted: false,
        classChanging: false,
        instanceClassChanging: false,
        attributesChanging: false,
        visibilityChanging: false,
        selectingAll: false,
        readyForAnnotationSetting: false,
        clearSelectedAnnotations: false,
        objectOrderChanged: false,
      },
      copyObjectsFromPrev: false,
      viewerProperties: {
        initialZoom: 100,
        zoomLevel: 0,
        zoomScale: 50,
        zoomMagnifier: 1.5,
      },
      isImageRendered: false,
      isCopyOperationsComplete: true,
      isMaxCopyObjects: false,
      isWorkspaceFullscreen: false,
      isImageLoading: false,
      isImageListUpdating: false,

      segmentedImageListOptions: {
        totalCount: 0,
        perPage: 100,
      },
      segmentedImageList: [],
      binaryObjectsHistory: {
        create: [],
        delete: [],
      },
      changedPositionObjects: [] as IAnnotation[],
      segmentLoader: false,
      copyImageLoader: false,
      createObjectLoader: false,
      segmentError: false,
      segmentErrorText: 'Что-то пошло не так :(',
      lastShadeIndex: 0,
      lastPosition: 1,
      isNotUpdateImage: false,
      eventBus: injectEventBus(),
    }
  },

  actions: {
    setCurrInd(ids: number) {
      this.currInd = ids
    },

    setChangedPositionObjects(arr: IAnnotation[]) {
      if (arr) {
        this.changedPositionObjects = arr
      }
    },

    setDistance(number: number) {
      this.distance = number
    },

    setShowDistance(value: boolean) {
      this.showDistance = value
    },

    setSegmentLoader(value: boolean) {
      if (this.segmentError) {
        this.segmentLoader = value
        this.segmentError = false
        this.copyImageLoader = value
        this.segmentErrorText = 'Контур не найден :('
      }
    },

    setUserRole() {
      const userStore = useUserStore()

      if (userStore.getIsUserAdmin) {
        this.userRole =
          this.currentTask.status === TaskStatuses.OnMarkup
            ? ExecutorRoles.Marker
            : ExecutorRoles.Reconciliator
        return
      }

      if (
        userStore.id === this.currentTask.reconciliator_id &&
        userStore.id === this.currentTask.executor_id
      ) {
        this.userRole =
          this.currentTask.status === TaskStatuses.OnMarkup ||
          this.currentTask.status === TaskStatuses.SentToMarkup
            ? ExecutorRoles.Marker
            : ExecutorRoles.Reconciliator

        return
      }

      switch (userStore.id) {
        case this.currentTask.executor_id:
          this.userRole = ExecutorRoles.Marker
          return
        case this.currentTask.reconciliator_id:
          this.userRole = ExecutorRoles.Reconciliator
          return
        default:
          this.userRole = UserRoles.Owner
      }
    },

    async getImage(id: number): Promise<void> {
      return ImageAPI.getImage(id)
        .then((resp) => {
          this.currentImage = resp
          this.imageClasses = resp.image_classes
          this.selectedImageClass = resp.image_classes[0]

          const objects: IAnnotation[] = []

          const classificationObjects: IClassificationObject[] =
            resp.image_classes
              .map(
                (imageClass: IImageClass) => imageClass.classification_objects,
              )
              .flat()

          classificationObjects.forEach((classification) => {
            classification.located_objects.forEach((object) => {
              if (!classification.project_class.id) return

              const annotation = this.convertLocatedObjectToAnnotation(
                object,
                classification.project_class.id,
              )

              if (
                annotation &&
                !objects.some(
                  (storedAnnotation) => storedAnnotation.id === annotation.id,
                )
              ) {
                objects.push(annotation)
              }
            })
          })

          this.currentImageObjects = objects
          this.imageList.forEach((image) => {
            if (image.id === id) {
              image.image_classes = resp.image_classes
            }
          })
          this.initHistory()
          this.isImageRendered = true
        })
        .catch((error) => {
          throw error.message
        })
    },

    async getImageObjects(id: number): Promise<IAnnotation[]> {
      return ImageAPI.getImage(id)
        .then(async (resp) => {
          this.currentProjectClass = {} as IProjectClass
          if (!this.copyObjectsFromPrev) {
            this.imageClasses = resp.image_classes
            this.currentImage.markup_status = resp.markup_status
            this.currentImage.checkup_status = resp.checkup_status
          }

          this.segmentedImageList[this.currentImageListSegmentIndex][
            this.currentImageIndexInSegment
          ].image_classes = resp.image_classes

          const objects: IAnnotation[] = []
          const classificationObjects: IClassificationObject[] =
            resp.image_classes
              .map(
                (imageClass: IImageClass) => imageClass.classification_objects,
              )
              .flat()

          classificationObjects.forEach((classification) => {
            classification.located_objects.forEach((object) => {
              if (!classification.project_class.id) return

              this.currentProjectClass = classification.project_class

              const annotation = this.convertLocatedObjectToAnnotation(
                object,
                classification.project_class.id,
              )
              if (annotation) {
                objects.push(annotation)
              }
            })
          })

          return objects
        })
        .catch((error) => {
          throw error.message
        })
    },

    async getImageList(
      id: number,
      fullList = false,
      page = 1,
      perPage = 100,
    ): Promise<void> {
      return ImageAPI.getImages(id, perPage, page)
        .then(async (resp) => {
          let result = resp.items

          if (fullList) {
            const images = await this.loadMoreImages(resp.total_count)
            result = [...result, ...images]
          }

          this.imageList = result
          this.imageListRequestOptions.pagination.totalCount = resp.total_count
          this.segmentedImageListOptions.totalCount = resp.total_count

          // await this.precacheImages()
        })
        .catch((error) => {
          throw error.message
        })
    },

    async getImageListSegment(
      id: number,
      segmentIndex: number,
    ): Promise<IImage[]> {
      return ImageAPI.getImages(
        id,
        this.segmentedImageListOptions.perPage,
        segmentIndex,
      )
        .then(async (resp) => {
          // TODO: Обдумать необходимость precache
          // await this.precacheImages()

          return resp.items
        })
        .catch((error) => {
          throw error.message
        })
    },

    // setImageListSegment(segmentIndex: number, items: IImage[]) {
    //   this.segmentedImageList[segmentIndex] = items
    // },

    async loadMoreImages(totalCount: number) {
      const lastPage = Math.ceil(totalCount / 100)

      let result: IImage[] = []
      const promises: Promise<void>[] = []

      for (let page = 2; page < lastPage + 1; page++) {
        promises.push(
          this.loadMoreImageList(this.currentTask.id, page).then((images) => {
            result = [...result, ...images]
          }),
        )
      }

      return Promise.all(promises).then(() => result)
    },

    async getImageListWithFilter(
      id: number,
      filter: IFilter[] = [] as IFilter[],
      perPage: number,
    ): Promise<void> {
      return ImageAPI.getImages(id, perPage, undefined, filter)
        .then((resp) => {
          this.imageListRequestOptions.filter = filter
          this.imageListRequestOptions.pagination.page = 1
          this.imageListRequestOptions.pagination.totalCount = resp.total_count

          if (filter.length && filter[0].value === CheckupStatuses.Checked) {
            this.filteredImageList = resp.items.filter(
              (item: IImage) => item.markup_status !== MarkupStatuses.ToDelete,
            )
            // this.precacheImages()
            return
          }

          this.filteredImageList = resp.items
          // this.precacheImages()
        })
        .catch((error) => {
          throw error.message
        })
    },

    async loadMoreImageListWithFilter(
      id: number,
      perPage = 100,
    ): Promise<void> {
      return ImageAPI.getImages(
        id,
        perPage,
        this.imageListRequestOptions.pagination.page,
        this.imageListRequestOptions.filter,
      )
        .then((resp) => {
          resp.items.forEach((image: IImage) => {
            this.filteredImageList.push(image)
          })
          // this.precacheImages()
          return resp.items
        })
        .catch((error) => {
          throw error.message
        })
    },

    async loadMoreImageList(
      id: number,
      page: number,
      perPage?: number,
    ): Promise<IImage[]> {
      return ImageAPI.getImages(id, perPage, page)
        .then((resp) => {
          return resp.items
        })
        .catch((error) => {
          throw error.message
        })
    },

    // async getImageClasses(id: number): Promise<void> {
    //   return ImageAPI.getImage(id)
    //     .then((resp) => {
    //       this.imageClasses = resp.image_classes
    //     })
    //     .catch((error) => {
    //       throw error.message
    //     })
    // },

    async getTaskWithImages(id: number): Promise<void> {
      return TaskAPI.getTaskWithImages(id)
        .then((resp: ITask) => {
          const formattedResp = resp

          if (formattedResp.images) {
            formattedResp.images = this.sortImagesById(formattedResp.images)
          }
          this.currentTask = resp
        })
        .catch((error) => {
          throw error
        })
    },

    // async precacheImages(): Promise<any> {
    //   const urls: string[] = []
    //   let counter = 0
    //
    //   // TODO: Провернуть схему с fallback на оригинальные изображения
    //   // TODO: Нужно пересмотреть в какие моменты стоит прекэшировать изображения
    //   for (
    //     let i = this.currentImageIndex;
    //     i < this.desegmentedImageList.length;
    //     i++
    //   ) {
    //     if (counter >= 15) break
    //     if (urls.includes(this.desegmentedImageList[i].getZipLink)) continue
    //
    //     urls.push(this.desegmentedImageList[i].getZipLink)
    //     counter++
    //   }
    //
    //   // wb.messageSW({ type: 'PRECACHE_IMAGES', payload: urls })
    // },

    async getProjectClasses(projectId: number): Promise<void> {
      return ProjectAPI.getProject(projectId)
        .then((response) => {
          this.projectClasses = response.project_classes

          // если у класоов проекта есть атрибуты,
          // то подствляем каждый атрибут в его класс
          if (response.project_class_attributes) {
            this.projectAttributes = response.project_class_attributes
            const classAttributesMap = {}
            response.project_class_attributes.forEach((attribute: any) => {
              const classId = attribute.class_id
              if (!classAttributesMap[classId]) {
                classAttributesMap[classId] = []
              }
              classAttributesMap[classId].push(attribute)
            })

            this.projectClasses.forEach((projectClass) => {
              const classId = projectClass.id
              if (classId && classAttributesMap[classId]) {
                projectClass.attributes = classAttributesMap[classId]
              }
            })
          }
        })
        .catch((error) => {
          throw error
        })
    },

    sortImagesById(images: IImage[]) {
      return images.sort((a, b) => {
        if (a.id > b.id) return 1
        else if (a.id < b.id) return -1
        else return 0
      })
    },

    //Строку в координаты для rect
    convertCoordinatesToCreateRect(
      annotationCoordinates: string,
    ): IAnnotationCoordinatesRect {
      const rawCoordinates = annotationCoordinates
        .replace('xywh=pixel:', '')
        .split(',')

      return {
        xmin: Number(rawCoordinates[0]),
        ymin: Number(rawCoordinates[1]),
        width: Number(rawCoordinates[2]),
        height: Number(rawCoordinates[3]),
      }
    },

    //Координаты в строку для rect
    convertRectCoordinatesToAnnotationFormat(
      coordinates: IAnnotationCoordinatesRect,
      keypoint = false,
    ): string {
      const { xmin, ymin, width, height } = coordinates

      return `xywh=pixel:${xmin},${ymin},${width},${height}`
    },

    //Координаты для polygon
    convertCoordinatesToCreatePolygon(
      svgString: string,
    ): IAnnotationCoordinatesPolygon[] {
      const matches = svgString.match(/points=("[^"]+"|'[^']+')/)
      if (matches && matches.length > 0) {
        const pointsString = matches[0]
          .replace('points=', '')
          .replace(/['"]+/g, '') // Убираем кавычки из строки
        const coordinates = pointsString.split(' ').map((pointString) => {
          const [x, y] = pointString.split(',')
          return { x: parseFloat(x), y: parseFloat(y) }
        })
        return coordinates
      } else {
        return []
      }
    },

    //Координаты в строку для polygon
    convertPolygonCoordinatesToAnnotationFormat(
      coordString: string | null,
      isArray?: boolean,
    ): string {
      let newCoords: string | null = coordString

      if (isArray && coordString !== null) {
        const jsonArray = JSON?.parse(coordString)
        if (Array.isArray(jsonArray)) {
          newCoords = jsonArray
            .map((point: { x: number; y: number }) => `${point.x},${point.y}`)
            .join(' ')
        }
      }

      return `<svg><polygon points='${newCoords}'></polygon></svg>`
    },

    convertLocatedObjectToAnnotation(
      locatedObject: ILocatedObject,
      projectClassId: number,
    ): IAnnotation | undefined {
      const projectClass = this.projectClasses.find(
        (projectClass) => projectClass.id === projectClassId,
      )

      // Это костыль проверка на массив. Если есть xmin - то это rect, если нет - полигон
      function itIsRect(inputString: string) {
        if (inputString.includes('xmin')) return true
      }

      // Если есть xmin - это rect
      const getSelector = (): ISelectorType => {
        if (itIsRect(locatedObject.coordinates)) {
          return {
            type: 'FragmentSelector',
            conformsTo: 'http://www.w3.org/TR/media-frags/',
            value: this.convertRectCoordinatesToAnnotationFormat(
              JSON?.parse(locatedObject.coordinates),
              true,
            ),
          }
        } else {
          return {
            type: 'SvgSelector',
            conformsTo: 'http://www.w3.org/TR/media-frags/',
            value: this.convertPolygonCoordinatesToAnnotationFormat(
              locatedObject.coordinates,
              true,
            ),
          }
        }
      }

      const pattern =
        // @ts-ignore
        locatedObject && locatedObject.keypoint
          ? // @ts-ignore
            JSON?.parse(locatedObject.keypoint)
          : null
      // @ts-ignore
      const displaying = locatedObject.displaying
      // @ts-ignore
      const locking = locatedObject.locking

      let convertPattern = null
      if (pattern) {
        convertPattern = {
          coordinates: pattern.coordinates,
          point_links: pattern.point_links,
          svg: pattern.svg,
          name: pattern.name,
          id: pattern.id,
        }
      }

      if (!projectClass || !projectClass.id) return

      return {
        type: 'Annotation',
        body: [
          {
            type: 'TextualBody',
            value: projectClass.name,
            format: 'classifying',
          },
          {
            type: 'TextualBody',
            value: projectClass.color,
            format: 'classifying',
          },
          {
            type: 'TextualBody',
            value: projectClass.id,
            format: 'classifying',
          },
          {
            type: 'TextualBody',
            purpose: 'displaying',
            value: displaying,
          },
          {
            type: 'TextualBody',
            purpose: 'locking',
            value: locking,
          },
          {
            type: 'TextualBody',
            value:
              locatedObject && locatedObject.shade
                ? locatedObject.shade
                : { index: 0, value: projectClass.color },
            format: 'classifying',
          },
          {
            type: 'TextualBody',
            value:
              locatedObject && locatedObject.position
                ? locatedObject.position
                : 1,
            format: 'classifying',
          },
          {
            type: 'TextualBody',
            value: convertPattern ? convertPattern : '',
            format: 'classifying',
          },
          {
            type: 'TextualBody',
            value: getSelector()?.value ? getSelector().value : '',
            format: 'classifying',
          },
        ],
        target: {
          selector: getSelector(),
        },
        id: locatedObject.id,
      }
    },

    async completeTask(): Promise<void> {
      const taskStatus =
        this.userRole === ExecutorRoles.Marker
          ? TaskStatuses.SentToCheck
          : TaskStatuses.Completed

      return useTaskStore()
        .changeTaskStatus(this.currentTask.id, taskStatus)
        .catch((error) => {
          throw error
        })
    },

    async sendTaskToMarkup(): Promise<void> {
      return useTaskStore()
        .changeTaskStatus(this.currentTask.id, TaskStatuses.SentToMarkup)
        .catch((error) => {
          throw error
        })
    },

    async changeMarkupStatus(
      status: MarkupStatuses,
      targetId?: number,
    ): Promise<void> {
      this.currentImage.markup_status = status
      const data = {
        id: targetId ?? this.currentImage.id,
        markup_status: status,
      }

      return ImageAPI.updateImage(data).catch((error) => {
        throw error
      })
    },

    async setProblem(value: boolean): Promise<void> {
      const data = {
        id: this.currentImage.id,
        is_difficult: value,
      }

      return ImageAPI.updateImage(data).catch((error) => {
        throw error
      })
    },

    async changeCheckupStatus(
      status: CheckupStatuses,
      imageId: number,
    ): Promise<void> {
      const data = {
        id: imageId,
        checkup_status: status,
      }

      return ImageAPI.updateImage(data).catch((error) => {
        throw error
      })
    },

    async createBinaryObjects(imageClassIds: number[], isPresented = true) {
      const objects = imageClassIds.map((id) => ({
        image_class_id: id,
        is_presented: isPresented,
        area_type_id: getAreaTypeId(AreaType.Bbox),
      }))

      return BinaryObjectAPI.createObjects(objects).catch((error) => {
        throw error
      })
    },

    async updateBinaryObjects(isPresented: boolean) {
      if (!this.currentBinaryObject) {
        return
      }

      const object = {
        id: this.currentBinaryObject.id,
        image_class_id: this.imageClasses[0].id,
        is_presented: isPresented,
        area_type_id: getAreaTypeId(AreaType.Bbox),
      }

      return BinaryObjectAPI.updateObjects([object]).catch((error) => {
        throw error
      })
    },

    async deleteBinaryObjects(binaryObjectId: number[]) {
      return BinaryObjectAPI.deleteObjects(binaryObjectId).catch((error) => {
        throw error
      })
    },

    // Change history
    async saveChanges(updateObjectsData = true): Promise<void> {
      if (this.createObjectLoader) {
        return
      }

      this.createObjectLoader = true

      const changedObjectsIds = [
        ...this.objectActionsHistory.update,
        ...this.objectActionsHistory.create,
      ]

      const changedObjects = this.currentImageObjects.filter((object) =>
        changedObjectsIds.includes(object.id),
      )

      if (!this.isHasImageClass()) {
        await this.createImageClasses(
          changedObjects.map((object) => object.body[2].value),
        )
      }

      const formattedObjects = this.formatObjectDataForRequest(changedObjects)
      const requestData = {
        create_attributes: {
          objects: formattedObjects.filter((object) =>
            this.objectActionsHistory.create.includes(String(object.id)),
          ),
        },
        update_attributes: {
          objects: formattedObjects.filter((object) =>
            this.objectActionsHistory.update.includes(Number(object.id)),
          ),
        },
        delete_attributes: {
          objects: this.objectActionsHistory.delete,
        },
      }

      return LocatedObjectAPI.saveObjectChanges(requestData)
        .then(async () => {
          if (this.isNotUpdateImage) {
            this.isNotUpdateImage = false
            return
          }
          await this.updateLocatedObjectsAfterSave(requestData)
          this.createObjectLoader = false
          this.copyImageLoader = false
        })
        .catch((error) => {
          logout(error)
          throw error
        })
    },

    getClassObjects() {
      return this.currentImageFromSegmentedList?.image_classes?.find(
        (item) => item.project_class_id === this.selectedClass.id,
      )?.classification_objects
    },

    getLastIndex() {
      const classObjects = this.getClassObjects()
      const sortClassObjects = classObjects?.sort(
        (a, b) =>
          // @ts-ignore
          new Date(a.created_at) - new Date(b.created_at),
      )
      const lastIndex =
        sortClassObjects &&
        // @ts-ignore
        sortClassObjects[sortClassObjects.length - 1]?.located_objects[0]?.shade
          ?.index
      // @ts-ignore
      this.lastShadeIndex = lastIndex === undefined ? 0 : (lastIndex + 1) % 9
    },

    getLastPosition() {
      const classObjects = this.getClassObjects()
      if (classObjects) this.lastPosition = classObjects.length + 1
    },

    isHasImageClass() {
      return (
        this.currentImageFromSegmentedList?.image_classes?.length &&
        this.currentImageFromSegmentedList?.image_classes?.find(
          (item) => item.project_class_id === this.selectedClass.id,
        )
      )
    },

    async segmentObjectCreate(data: any): Promise<void> {
      this.segmentLoader = true
      this.segmentError = false
      this.selectedTool = Tools.Arrow
      const requestData = {
        image_id: this.currentImageFromSegmentedList.id,
        interval_length: this.distance,
        method: data.method,
        bbox: data.method === 'identifier' ? data.coordinates : undefined,
        points: data.method === 'interactor' ? data.coordinates : undefined,
        point_labels:
          data.method === 'interactor' ? data.point_labels : undefined,
      }
      return LocatedObjectAPI.segmentObjectCreate(requestData)
        .then(async (resp: any) => {
          if (resp?.contours && resp.contours.length) {
            this.segmentLoader = false
            const imageClass = this.imageClasses.length
              ? this.imageClasses.find(
                  (imageClass) =>
                    imageClass.project_class_id === this.selectedClass.id,
                )
              : this.projectClasses[0]

            const changedObjectsIds = [...this.objectActionsHistory.update]

            const changedObjects = this.currentImageObjects.filter((object) =>
              // @ts-ignore
              changedObjectsIds.includes(object.id),
            )

            const formattedObjects =
              this.formatObjectDataForRequest(changedObjects)

            this.getLastIndex()
            this.getLastPosition()

            if (!this.isHasImageClass()) {
              // @ts-ignore
              await this.createImageClasses([this?.selectedClass?.id])
            }

            const isAddIndex = () => {
              const markupType = this.currentTask.project?.markup_type

              return (
                markupType === MarkupTypes.Instance ||
                markupType === MarkupTypes.Panoptic
              )
            }
            const dataForRequest = {
              create_attributes: {
                objects: [
                  {
                    area_type_id: getAreaTypeId(AreaType.Polygon),
                    id: '#e3a36740-da86-40aa-a434-d1ac69306431',
                    // @ts-ignore
                    coordinates: JSON.stringify(resp.contours),
                    image_class_id: imageClass?.id,
                    type: 'polygon',
                    shade_index: isAddIndex() ? this.lastShadeIndex : undefined,
                    position: this.lastPosition,
                    displaying: true,
                    locking: false,
                  },
                ],
              },
              update_attributes: {
                objects: formattedObjects.filter((object) =>
                  this.objectActionsHistory.update.includes(Number(object.id)),
                ),
              },
              delete_attributes: {
                objects: this.objectActionsHistory.delete,
              },
            }
            // eslint-disable-next-line no-useless-catch
            try {
              await LocatedObjectAPI.saveObjectChanges(dataForRequest)
              await this.updateLocatedObjectsAfterSave()
            } catch (error) {
              throw error
            }
          } else {
            this.segmentErrorText = 'Контур не найден :('
            this.segmentError = true
            if (data.id) {
              this.removeElementById(data.id)
            }
          }
        })
        .catch((error) => {
          switch (error.response.status) {
            case 502:
              this.segmentErrorText =
                'Не получен ответ от сервера, попробуйте позже :('
              break
            case 504:
              this.segmentErrorText =
                'Превышено количество запросов к серверу, попробуйте снова :('
              break
            default:
              this.segmentErrorText = 'Что-то пошло не так :('
          }
          this.segmentError = true
          this.selectedTool = Tools.Arrow
          if (data.id) {
            this.removeElementById(data.id)
          }
        })
    },

    removeElementById(dataIdString: string) {
      const elements = document.querySelectorAll(`[data-id="${dataIdString}"]`)

      elements.forEach((element) => {
        element.remove()
      })
    },

    async updateLocatedObjectsAfterSave(dataForRequest?: any) {
      const { updateMarkup } = useWorkspaceMarkup()
      await updateMarkup(dataForRequest)
      if (
        this.isCheckSaveWhenSwitchingToTheNextImage ||
        this.isCheckSaveWhenSwitchingToThePrevImage
      ) {
        this.segmentedImageList[this.currentImageListSegmentIndex][
          this.сheckSaveWhenSwitchingImageIndex
        ] = await ImageAPI.getImage(this.currentImage.id)
        this.currentImageObjects = this.getObjectsFromImageList(
          this.currentImageListSegmentIndex,
          this.сheckSaveWhenSwitchingImageIndex,
        )
        this.isCheckSaveWhenSwitchingToTheNextImage = false
        this.isCheckSaveWhenSwitchingToThePrevImage = false
      } else {
        this.segmentedImageList[this.currentImageListSegmentIndex][
          this.currentImageIndexInSegment
        ] = await ImageAPI.getImage(this.currentImage.id)
        this.currentImageObjects = this.getObjectsFromImageList(
          this.currentImageListSegmentIndex,
          this.currentImageIndexInSegment,
        )
      }

      this.updateObjectInFilterImageList()

      this.selectedObjectGroup = []
      this.selectedObject = {} as IAnnotation

      this.clearChanges()
      this.objectActionsHistory.update = []
      if (
        (this.selectedTool === Tools.Pentagon ||
          this.selectedTool === Tools.Identificator ||
          this.selectedTool === Tools.Interactor) &&
        !dataForRequest?.update_attributes?.objects[0]?.keypoint
      ) {
        this.selectedTool = Tools.Arrow
      }
    },

    updateObjectInFilterImageList() {
      if (this.filteredImageList) {
        const index = this.filteredImageList.findIndex(
          (item) => item.id === this.currentImage.id,
        )
        if (index !== -1) {
          // Найден элемент, обновляем свойство image_classes
          // @ts-ignore
          this.filteredImageList[index].image_classes = this.currentImageObjects
        }
      }
    },

    async saveAutocopiedChanges(
      copiedObjects: IAnnotation[],
      imageClasses: IImageClass[],
      imageIndex: number,
    ): Promise<void> {
      const newImageClasses =
        (await this.createImageClassesForAutocopiedObjects(
          copiedObjects?.map((object) => object?.body[2]?.value),
          imageClasses,
          this.segmentedImageList[this.currentImageListSegmentIndex][imageIndex]
            ?.id,
        )) || []
      const formattedObjects = this.formatObjectDataForRequest(
        copiedObjects,
        [...newImageClasses, ...imageClasses],
        true,
      )

      const requestData = {
        create_attributes: {
          objects: formattedObjects,
        },
        update_attributes: {
          objects: [],
        },
        delete_attributes: {
          objects: [],
        },
      }

      if (newImageClasses.length) {
        const imageClassesWithClassificationObjects = newImageClasses.map(
          (imageClass) => ({ ...imageClass, classification_objects: [] }),
        )
        this.segmentedImageList[this.currentImageListSegmentIndex][
          imageIndex
        ].image_classes = [
          ...imageClassesWithClassificationObjects,
          ...imageClasses,
        ]
      }

      return LocatedObjectAPI.saveObjectChanges(requestData).catch((error) => {
        throw error
      })
    },

    clearChanges() {
      this.objectActionsHistory.create = []
      // this.objectActionsHistory.update = [] // todo: очистка этого массива создает баг: объекты перестают автокопироваться при быстром прокликивании. Нужно ли где то очищать этот массив отдельно
      this.objectActionsHistory.delete = []
      this.initHistory()
    },

    async createImageClasses(
      projectClassIds: number[],
    ): Promise<IImageClass[] | []> {
      const projectClassIdsForCreation: number[] = []

      projectClassIds?.forEach((id) => {
        if (
          !this.imageClasses?.some(
            (imageClass) => id === imageClass.project_class_id,
          ) &&
          !projectClassIdsForCreation?.includes(id)
        ) {
          projectClassIdsForCreation?.push(id)
        }
      })

      if (!projectClassIdsForCreation?.length) {
        return []
      }

      const requestData = projectClassIdsForCreation?.map((projectClassId) => {
        if (projectClassId) {
          return {
            image_id: this.currentImage.id,
            project_class_id: projectClassId,
          }
        }
      })

      return ImageAPI.createImageClasses(requestData as LocalImageClass[])
        .then((resp) => {
          this.imageClasses.push(...resp)

          const foundImageClass = this.imageClasses.find(
            (imageClass) =>
              imageClass.project_class_id === this.selectedClass.id,
          )

          if (foundImageClass) {
            this.selectedImageClass = foundImageClass
          }

          return resp
        })
        .catch((error) => {
          if (error.message.includes('exists')) {
            return this.imageClasses
          }

          throw error
        })
    },

    async createImageClassesForAutocopiedObjects(
      projectClassIds: number[],
      imageClasses: IImageClass[],
      imageId: number,
    ): Promise<IImageClass[] | void> {
      const projectClassIdsForCreation: number[] = []

      projectClassIds?.forEach((id) => {
        if (
          !imageClasses?.some(
            (imageClass) => id === imageClass.project_class_id,
          ) &&
          !projectClassIdsForCreation?.includes(id)
        ) {
          projectClassIdsForCreation.push(id)
        }
      })

      if (!projectClassIdsForCreation?.length) {
        return
      }

      const requestData = projectClassIdsForCreation?.map((projectClassId) => {
        if (projectClassId) {
          return {
            image_id: imageId,
            project_class_id: projectClassId,
          }
        }
      })

      return ImageAPI.createImageClasses(requestData as LocalImageClass[])
        .then((resp) => {
          return resp
        })
        .catch((error) => {
          throw error
        })
    },

    async deleteImageClasses(imageClassId: number[]) {
      return ImageAPI.deleteImageClasses(imageClassId).catch((error) => {
        throw error
      })
    },

    formatObjectDataForRequest(
      annotations: IAnnotation[],
      targetImageClasses?: IImageClass[],
      autoCopy = false,
    ) {
      const imageClasses = targetImageClasses ?? this.imageClasses

      const objects = annotations.map((annotation) => {
        const imageClass = imageClasses.find(
          (imageClass) =>
            imageClass.project_class_id === annotation.body[2].value,
        )

        const getObjectCoordinates = () => {
          if (annotation.target.selector.type === 'FragmentSelector') {
            return this.convertCoordinatesToCreateRect(
              annotation.target.selector.value,
            )
          } else if (annotation.target.selector.type === 'SvgSelector') {
            return this.convertCoordinatesToCreatePolygon(
              annotation.target.selector.value,
            )
          }
        }

        const getObjectType = () => {
          if (annotation.target.selector.type === 'FragmentSelector') {
            return 'rect'
          } else if (annotation.target.selector.type === 'SvgSelector') {
            return 'polygon'
          }
        }

        this.getLastIndex()

        const isAddIndex = () => {
          const markupType = this.currentTask.project?.markup_type

          return (
            markupType === MarkupTypes.Instance ||
            markupType === MarkupTypes.Panoptic
          )
        }

        let objectsWithAttributes = null
        if (
          typeof annotation.id !== 'number' &&
          annotation.id?.includes('-copy-') &&
          !autoCopy
        ) {
          objectsWithAttributes = this.copiedObjects.map((object) => {
            // @ts-ignore
            const getFoundLocatedObject =
              this.currentImageFromSegmentedList &&
              this.currentImageFromSegmentedList.image_classes &&
              // @ts-ignore
              this.currentImageFromSegmentedList.image_classes
                .map(
                  (imageClass: IImageClass) =>
                    imageClass.classification_objects,
                )
                .flat()
                .find((item) =>
                  item.located_objects.find(
                    (locatedObj) => locatedObj.id === object.id,
                  ),
                )

            return {
              attributes:
                getFoundLocatedObject &&
                getFoundLocatedObject?.located_objects &&
                getFoundLocatedObject?.located_objects.length
                  ? //@ts-ignore
                    getFoundLocatedObject?.located_objects[0].located_object_attributes.map(
                      (item: any) => {
                        return {
                          project_class_attribute_id:
                            item.project_class_attribute_id,
                          value: item.value,
                        }
                      },
                    )
                  : undefined,
            }
          })
        }

        const keypoint = annotation?.body[7]?.value
          ? {
              coordinates:
                typeof annotation?.body[7]?.value.coordinates === 'string'
                  ? JSON?.parse(annotation?.body[7]?.value.coordinates)
                  : annotation?.body[7]?.value.coordinates,
              point_links: annotation?.body[7]?.value.point_links,
              svg: annotation?.body[7]?.value.svg,
              name: annotation?.body[7]?.value.name,
              id: annotation?.body[7]?.value.id,
            }
          : undefined

        // @ts-ignore
        return {
          type: getObjectType(),
          id: annotation.id,
          image_class_id: imageClass?.id,
          coordinates: JSON.stringify(getObjectCoordinates()),
          area_type_id: getAreaTypeId(
            annotation?.body[7]?.value
              ? AreaType.Keypoints
              : getObjectType() === 'polygon'
              ? AreaType.Polygon
              : AreaType.Bbox,
          ),
          // @ts-ignore
          shade_index: isAddIndex()
            ? typeof annotation.id !== 'number' &&
              annotation.id?.includes('-copy-') &&
              !autoCopy
              ? this.lastShadeIndex
              : annotation?.body[5]?.value?.index
            : undefined,
          // @ts-ignore
          attributes: objectsWithAttributes
            ? objectsWithAttributes[0].attributes
            : annotation.attributes
            ? annotation.attributes
            : undefined,
          position: annotation?.body[6]?.value,
          displaying: annotation?.body[3]?.value,
          locking: annotation?.body[4]?.value,
          pattern_id: annotation?.body[7]?.value
            ? annotation?.body[7]?.value.id
            : undefined,
          keypoint: keypoint ? JSON.stringify(keypoint) : undefined,
        }
      })

      return objects
    },

    async createObjectAttribute(data: any) {
      const dataForRequest = {
        located_object_id: data.located_object_id,
        project_class_attribute_id: data.project_class_attribute_id,
        value: data.value,
      }
      return LocatedObjectAPI.createObjectAttribute(dataForRequest).catch(
        (error) => {
          throw error
        },
      )
    },

    async updateObjectAttribute(data: any) {
      const dataForRequest = {
        located_object_id: data.located_object_id,
        id: data.id,
        value: data.value,
      }
      return LocatedObjectAPI.updateObjectAttribute(dataForRequest).catch(
        (error) => {
          throw error
        },
      )
    },

    async deleteObjectAttribute(id: number) {
      return LocatedObjectAPI.deleteObjectAttribute(id).catch((error) => {
        throw error
      })
    },

    createHook(ids: string[]) {
      this.objectActionsHistory.create.push(...ids)
      this.addHistoryRecord()
    },

    updateHook(ids: (number | string)[]) {
      ids.forEach((id) => {
        if (
          typeof id === 'string' ||
          this.objectActionsHistory.update.includes(id)
        ) {
          return
        }

        this.objectActionsHistory.update.push(id)
      })

      this.addHistoryRecord()
    },

    deleteHook(ids: (string | number)[]) {
      ids.forEach((id) => {
        if (
          typeof id === 'string' &&
          this.objectActionsHistory.create.includes(id)
        ) {
          const deletedObjectIndex =
            this.objectActionsHistory.create.indexOf(id)
          this.objectActionsHistory.create.splice(deletedObjectIndex, 1)
          return
        }

        const deletedObjectIndex = this.objectActionsHistory.update.indexOf(
          id as number,
        )
        if (deletedObjectIndex !== -1) {
          this.objectActionsHistory.update.splice(deletedObjectIndex, 1)
        }

        this.objectActionsHistory.delete.push(id as number)
        if (this.eventBus) this.eventBus.$emit('deselectPattern')
      })

      this.addHistoryRecord()
    },

    // workspace history
    addHistoryRecord() {
      if (this.selectedHistoryIndex >= 9) {
        this.removeFirstHistoryRecord()
        this.selectedHistoryIndex = 8
      }

      if (
        this.selectedHistoryIndex <
        this.workspaceHistory.objectsHistory.length - 1
      ) {
        this.removeNextHistoryRecords()
      }

      this.workspaceHistory.objectsHistory.push(
        this.cloneDeep(this.currentImageObjects),
      )
      this.workspaceHistory.actionsHistory.push(
        this.cloneDeep(this.objectActionsHistory),
      )

      this.selectedHistoryIndex++
    },

    initHistory() {
      this.workspaceHistory.objectsHistory = []
      this.workspaceHistory.actionsHistory = []
      this.selectedHistoryIndex = 0

      this.workspaceHistory.objectsHistory.push(
        this.cloneDeep(this.currentImageObjects),
      )

      this.workspaceHistory.actionsHistory.push(
        this.cloneDeep(this.objectActionsHistory),
      )
    },

    removeFirstHistoryRecord() {
      this.workspaceHistory.actionsHistory.splice(0, 1)
      this.workspaceHistory.objectsHistory.splice(0, 1)
    },

    removeNextHistoryRecords() {
      this.workspaceHistory.actionsHistory.splice(this.selectedHistoryIndex + 1)
      this.workspaceHistory.objectsHistory.splice(this.selectedHistoryIndex + 1)
    },

    cloneDeep(original: any) {
      return JSON?.parse(JSON?.stringify(original))
    },

    goToPrevState() {
      this.dropSelectedObjects()
      this.selectedHistoryIndex--
      this.updateWorkspace()
    },

    goToNextState() {
      this.dropSelectedObjects()
      this.selectedHistoryIndex++
      this.updateWorkspace()
    },

    updateWorkspace() {
      this.currentImageObjects = this.cloneDeep(
        this.workspaceHistory.objectsHistory[this.selectedHistoryIndex],
      )
      this.objectActionsHistory = this.cloneDeep(
        this.workspaceHistory.actionsHistory[this.selectedHistoryIndex],
      )
    },

    getObjectsFromImageList(segmentIndex: number, imageIndex: number) {
      const image_classes =
        this.segmentedImageList[segmentIndex]?.[imageIndex]?.image_classes

      if (!image_classes) return []

      const objects: IAnnotation[] = []
      const classificationObjects: IClassificationObject[] = image_classes
        .map((imageClass: IImageClass) => imageClass.classification_objects)
        .flat()

      classificationObjects.forEach((classification) => {
        classification.located_objects.forEach((object) => {
          if (!classification.project_class.id) return

          const annotation = this.convertLocatedObjectToAnnotation(
            object,
            classification.project_class.id,
          )
          if (annotation) {
            objects.push(annotation)
          }
        })
      })
      return objects
    },

    dropSelectedObjects() {
      this.selectedObject = {} as IAnnotation
      this.selectedObjectGroup = []
    },

    async createComment(comment: string) {
      const payload = { image_id: this.currentImage.id, text: comment }
      await CommentaryApi.createComment(payload)
    },

    async updateComment(commentId: number, commentText: string) {
      await CommentaryApi.updateComment(commentId, commentText)
    },

    async deleteComment(commentId: number) {
      await CommentaryApi.deleteComment(commentId)
    },

    updateZoomLevel(value: 1 | -1) {
      this.viewerProperties.zoomLevel += value
    },

    resetZoomLevel() {
      this.viewerProperties.zoomLevel = 0
    },

    checkImageListSegmentIsEmpty(segmentIndex: number): boolean {
      return !this.segmentedImageList[segmentIndex].length
    },

    addBinaryObjectHistory(objectId: number) {
      this.binaryObjectsHistory.create.push(objectId)
      this.binaryObjectsHistory.delete =
        this.binaryObjectsHistory.delete.filter(
          (deletedId) => deletedId !== objectId,
        )
    },

    async onClassSelect(id: number) {
      this.addBinaryObjectHistory(id)
      const resp = await this.createImageClasses([id])
      if (resp && resp[0]) {
        await this.createBinaryObjects([resp[0]?.id]).then(async () => {
          await this.getImageObjects(this.currentImage.id).then(async () => {
            const status = this.createdBinaryObjects.length
              ? MarkupStatuses.HaveMarkup
              : MarkupStatuses.NoMarkup

            if (this.currentImage.markup_status !== status)
              await this.changeMarkupStatus(status)
          })
        })
      }
    },

    removeBinaryObjectHistory(objectId: number) {
      this.binaryObjectsHistory.delete.push(objectId)
      this.binaryObjectsHistory.create =
        this.binaryObjectsHistory.create.filter(
          (createdId) => createdId !== objectId,
        )
    },

    async onClassDeselect(data: IProjectClass) {
      if (data.id) {
        this.removeBinaryObjectHistory(data.id)
        await this.deleteBinaryObjects([
          data?.classification_objects[0].binary_objects[0].id,
        ])
        await this.deleteImageClasses([
          data?.classification_objects[0].image_class_id,
        ])
        await this.getImageObjects(this.currentImage.id).then(async () => {
          const status = this.createdBinaryObjects.length
            ? MarkupStatuses.HaveMarkup
            : MarkupStatuses.NoMarkup

          if (this.currentImage.markup_status !== status)
            await this.changeMarkupStatus(status)
        })
      }
    },

    resetBinaryObjectHistory() {
      this.binaryObjectsHistory.create = []
      this.binaryObjectsHistory.delete = []
    },

    async createBinaryMarkup() {
      if (!this.binaryObjectsHistory.create.length) return

      const createdImageClasses = await this.createImageClasses(
        this.binaryObjectsHistory.create,
      )

      const combinedImageClasses = [
        ...createdImageClasses,
        this.imageClasses,
      ] as IImageClass[]

      const createdImageClassesFromHistory = combinedImageClasses.filter(
        (imageClass) => {
          return this.binaryObjectsHistory.create.includes(
            imageClass.project_class_id,
          )
        },
      )

      const imageClassIds = createdImageClassesFromHistory.map(
        (imageClass) => imageClass.id,
      )

      await this.createBinaryObjects(imageClassIds)
    },

    async removeBinaryMarkup() {
      const imageClasses = this.imageClasses?.filter((imageClass) =>
        this.currentBinaryObjects?.some(
          (object) => object?.image_class_id === imageClass?.id,
        ),
      )

      const removedImageClasses = imageClasses?.filter((imageClass) =>
        this.binaryObjectsHistory?.delete?.includes(
          imageClass.project_class_id,
        ),
      )

      const removedBinaryObjects = this.currentBinaryObjects?.filter((object) =>
        removedImageClasses?.some(
          (imageClass) => imageClass?.id === object?.image_class_id,
        ),
      )

      const removedImageClassIds = removedBinaryObjects?.map(
        (object) => object?.image_class_id,
      )
      const removedBinaryObjectsIds = removedBinaryObjects?.map(
        (object) => object?.id,
      )

      if (!removedBinaryObjectsIds?.length) return

      await this.deleteBinaryObjects(removedBinaryObjectsIds)
      await this.deleteImageClasses(removedImageClassIds)
    },

    async createBinaryObjectAttribute(data: any) {
      const dataForRequest = {
        binary_object_id: data.binary_object_id,
        project_class_attribute_id: data.project_class_attribute_id,
        value: data.value,
      }
      return BinaryObjectAPI.createBinaryObjectAttributes(dataForRequest).catch(
        (error) => {
          throw error
        },
      )
    },

    async updateBinaryObjectAttribute(data: any) {
      const dataForRequest = {
        binary_object_id: data.binary_object_id,
        id: data.id,
        value: data.value,
      }
      return BinaryObjectAPI.updateBinaryObjectAttribute(dataForRequest).catch(
        (error) => {
          throw error
        },
      )
    },

    async deleteBinaryObjectAttribute(id: number) {
      return BinaryObjectAPI.deleteBinaryObjectAttribute(id).catch((error) => {
        throw error
      })
    },

    async saveNonDetectionObjects() {
      await Promise.all([this.createBinaryMarkup(), this.removeBinaryMarkup()])
      this.resetBinaryObjectHistory()
    },
  },

  getters: {
    getImages(state): IImage[] {
      return state.currentTask.images || []
    },

    hasPrevStates(state): boolean {
      return state.selectedHistoryIndex !== 0
    },

    hasNextStates(state): boolean {
      return (
        state.selectedHistoryIndex <
        state.workspaceHistory.objectsHistory.length - 1
      )
    },

    isNoObjectsChanges(state): boolean {
      return !(
        state.objectActionsHistory.update.length ||
        state.objectActionsHistory.create.length ||
        state.objectActionsHistory.delete.length
      )
    },

    currentBinaryObject(state): IBinaryObject | void {
      if (
        !state.currentImage ||
        !state.currentImage?.image_classes ||
        !state.currentImage?.image_classes?.length
      ) {
        return
      }

      if (
        !state.currentImage?.image_classes?.[0]?.classification_objects?.length
      ) {
        return
      }

      return state.currentImage?.image_classes?.[0]?.classification_objects?.[0]
        ?.binary_objects?.[0]
    },

    // @ts-ignore
    currentBinaryObjects(state): IBinaryObject[] {
      if (
        !state.currentImage ||
        !state.currentImage?.image_classes ||
        !state.currentImage?.image_classes?.length
      ) {
        return []
      }
      const objects: IBinaryObject[] = []

      if (
        state.currentImage ||
        //@ts-ignore
        state.currentImage?.image_classes ||
        //@ts-ignore
        state.currentImage?.image_classes?.length
      ) {
        state.currentImage?.image_classes?.forEach((imageClass) => {
          if (imageClass?.classification_objects?.length) {
            const object =
              imageClass?.classification_objects[0].binary_objects[0]
            if (object) objects?.push(object)
          }
        })

        return objects
      }
    },

    getCurrentZoom(state): string {
      return (
        state.viewerProperties.initialZoom +
        state.viewerProperties.zoomScale * state.viewerProperties.zoomLevel
      ).toString()
    },

    getZoomLevel(state): number {
      return state.viewerProperties.zoomLevel
    },

    /**
     * Определяет можно ли листать изображения дальше
     * @return {boolean}
     **/
    canGoToNextImage(): boolean {
      return (
        this.currentImageIndex !==
        // @ts-ignore
        this?.segmentedImageListOptions?.totalCount - 1
      )
    },

    desegmentedImageList(): IImage[] {
      return this.segmentedImageList.flat()
    },

    currentImageListSegment(): number {
      return (
        Math.floor(
          this.currentImageIndex / this.segmentedImageListOptions.perPage,
        ) + 1
      )
    },

    currentImageListSegmentIndex(): number {
      return this.currentImageListSegment - 1
    },

    currentImageIndexInSegment(): number {
      return this.currentImageIndex % this.segmentedImageListOptions.perPage
    },

    isCurrentImageListSegmentEmpty(): boolean {
      return !this.segmentedImageList[this.currentImageListSegmentIndex]?.length
    },

    segmentsCount(): number {
      return Math.ceil(
        (this.segmentedImageListOptions.totalCount as number) /
          this.segmentedImageListOptions.perPage,
      )
    },

    currentImageFromSegmentedList(): IImage {
      return this.segmentedImageList?.[this.currentImageListSegmentIndex]?.[
        this.currentImageIndexInSegment
      ]
    },

    createdBinaryObjects(): IProjectClass[] {
      if (!this.currentImage.image_classes) return []

      const projectClassesIds = this.currentImage.image_classes.map(
        (imageClass) => imageClass.project_class_id,
      )

      return this.projectClasses.filter((projectClass) => {
        return (
          (projectClassesIds.includes(projectClass.id as number) ||
            this.binaryObjectsHistory.create.includes(
              projectClass.id as number,
            )) &&
          !this.binaryObjectsHistory.delete.includes(projectClass.id as number)
        )
      })
    },

    availableBinaryObjects(): IProjectClass[] {
      console.debug('this.projectClasses', this.projectClasses)
      console.debug('this?.currentProjectClass', this?.currentProjectClass)
      if (!this?.currentProjectClass) return [this?.currentProjectClass]
      return this.projectClasses.filter((projectClass) => {
        return !this.createdBinaryObjects.some(
          (createdObject) => createdObject.id === projectClass.id,
        )
      })
    },

    currentImageIndexFromImageList(): number {
      const { id } = this.currentImage
      return this.filteredImageList.findIndex((image) => image.id === id)
    },
  },
})
