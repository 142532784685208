import UTIF from 'utif'

export const useFileConverter = () => {
  const fileToBlob = async (file: File) => {
    const arrayBuffer = await file.arrayBuffer()
    return new Blob([new Uint8Array(arrayBuffer)], { type: file.type })
  }

  const blobToBase64 = async (blob: Blob): Promise<string> => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result as string)
      reader.readAsDataURL(blob)
    })
  }

  // function arrayBufferToBase64(buffer: Uint8Array) {
  //   return new Promise((resolve) => {
  //     const blob = new Blob([buffer], { type: 'application/octet-binary' })
  //     const reader = new FileReader()
  //     reader.onload = function (evt) {
  //       if (!evt.target) return
  //       const dataurl = evt.target.result as string
  //       resolve(dataurl.substring(dataurl.indexOf(',') + 1))
  //     }
  //     reader.readAsDataURL(blob)
  //   })
  // }

  const tiffToRgba8canvas = async (file: File | Blob) => {
    const arrayBuffer = await file.arrayBuffer()
    const ifd = UTIF.decode(arrayBuffer)
    UTIF.decodeImage(arrayBuffer, ifd[0])
    const rgba8Image = UTIF.toRGBA8(ifd[0])

    const clamped = new Uint8ClampedArray(rgba8Image)
    const imageData = new ImageData(clamped, ifd[0].width, ifd[0].height)

    const canvas = document.createElement('canvas')
    canvas.width = ifd[0].width
    canvas.height = ifd[0].height
    const ctx = canvas.getContext('2d')

    // @ts-ignore
    ctx.putImageData(imageData, 0, 0)
    return canvas
  }

  const tiffImageToBase64 = async (blob: File | Blob) => {
    const canvas = await tiffToRgba8canvas(blob)

    return canvas.toDataURL()
  }

  const tiffToJpeg = async (blob: File | Blob): Promise<Blob> => {
    const canvas = await tiffToRgba8canvas(blob)

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) =>
          blob
            ? resolve(blob)
            : reject(new Error('canvas.toBlob() method is failed')),
        'image/jpeg',
      )
    })
  }

  return {
    fileToBlob,
    blobToBase64,
    tiffImageToBase64,
    // arrayBufferToBase64,
    tiffToJpeg,
  }
}
