import { EgalActionConstructor } from '@/helpers/EgalActionConstructor'
import { APIModels, Microservices } from '@/types/enums'
import { IBinaryObject } from '@/types/interfaces'
import { logout } from '@/helpers/logout'

class BinaryObjectAPIModel {
  async createObjects(objects: Omit<IBinaryObject, 'id'>[]): Promise<any> {
    return EgalActionConstructor.createMany(
      Microservices.Core,
      APIModels.BinaryObject,
      objects,
    )
      .call()
      .then((resp) => {
        return resp
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async updateObjects(objects: IBinaryObject[]): Promise<any> {
    EgalActionConstructor.updateMany(
      Microservices.Core,
      APIModels.BinaryObject,
      objects,
    )
      .call()
      .then((resp) => {
        return resp
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async deleteObjects(objects: number[]): Promise<any> {
    EgalActionConstructor.deleteMany(
      Microservices.Core,
      APIModels.BinaryObject,
      { ids: objects },
    )
      .call()
      .then((resp) => {
        return resp
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async createBinaryObjectAttributes(data: any): Promise<void> {
    return EgalActionConstructor.create(
      Microservices.Core,
      APIModels.BinaryObjectAttributes,
      data,
    )
      .call()
      .then((resp) => {
        return resp
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async updateBinaryObjectAttribute(data: any): Promise<void> {
    return EgalActionConstructor.update(
      Microservices.Core,
      APIModels.BinaryObjectAttributes,
      data,
    )
      .call()
      .then((resp) => {
        return resp
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async deleteBinaryObjectAttribute(id: number): Promise<void> {
    return EgalActionConstructor.delete(
      Microservices.Core,
      APIModels.BinaryObjectAttributes,
      {
        id,
      },
    )
      .call()
      .then((resp) => {
        return resp
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }
}

export const BinaryObjectAPI = new BinaryObjectAPIModel()
