import { provide, inject, ref, Ref } from 'vue'

const eventBusSymbol = Symbol()

interface EventBus {
  $on(eventName: string, callback: (...args: any[]) => void): void
  $emit(eventName: string, ...args: any[]): void
}

export function useEventBus(): EventBus {
  const events: Ref<Record<string, ((...args: any[]) => void)[]>> = ref({})

  function $on(eventName: string, callback: (...args: any[]) => void): void {
    if (!events.value[eventName]) {
      events.value[eventName] = []
    }
    events.value[eventName].push(callback)
  }
  function $emit(eventName: string, ...args: any[]): void {
    if (events.value[eventName]) {
      for (const callback of events.value[eventName]) {
        callback(...args)
      }
    }
  }

  provide(eventBusSymbol, {
    $on,
    $emit,
  })

  return {
    $on,
    $emit,
  }
}

export function injectEventBus(): EventBus {
  return inject(eventBusSymbol) as EventBus
}
