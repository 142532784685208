export const parseTransformString = (transformString: string) => {
  const regex =
    /translate\((-?\d+\.?\d*(?:e[+-]\d+)?), (-?\d+\.?\d*(?:e[+-]\d+)?)\) scale\((-?\d+\.?\d*(?:e[+-]\d+)?), (-?\d+\.?\d*(?:e[+-]\d+)?)\)(?: rotate\((-?\d+\.?\d*(?:e[+-]\d+)?)\))?/
  const matches = transformString.match(regex)

  if (matches) {
    const translateX = parseFloat(matches[1])
    const translateY = parseFloat(matches[2])
    const scaleX = parseFloat(matches[3])
    const scaleY = parseFloat(matches[4])

    return {
      translate: { x: translateX, y: translateY },
      scale: { x: scaleX, y: scaleY },
    }
  } else {
    return {
      translate: {
        x: 0,
        y: 0,
      },
      scale: {
        x: 1,
        y: 1,
      },
    } // В случае, если строка не соответствует ожидаемому формату
  }
}

export const mathHelp = (
  number: number,
  contentWidth: number,
  svgWidth: number,
) => {
  return (number * contentWidth) / svgWidth
}

let strokeLine: number,
  radius: number,
  stroke: number,
  fontSize: number,
  patternNamesY: number

const SIZE_SMALL = 300
const SIZE_MEDIUM = 600
const SIZE_LARGE = 1200
const SIZE_VERY_LARGE = 2400

const getSizeImage = (imgWidth: number) => {
  const sizeMapping = {
    [SIZE_SMALL]: 'small',
    [SIZE_MEDIUM]: 'medium',
    [SIZE_LARGE]: 'large',
    [SIZE_VERY_LARGE]: 'very large',
  }

  for (const size in sizeMapping) {
    if (imgWidth <= Number(size)) {
      return sizeMapping[size]
    }
  }

  return 'big'
}

const setSizePatternDependingOnImageSize = (
  imgWidth: number,
  svgWidth: any,
) => {
  setStrokeLineSize(imgWidth, svgWidth)
  setCircleRadiusSize(imgWidth, svgWidth, false)
  setCircleStrokeSize(imgWidth, svgWidth)
  setPatternNamesSize(imgWidth, svgWidth)
  setPatternNamesY(imgWidth, svgWidth)
}

const sizeCoefficientMapping = {
  small: 0.12,
  medium: 0.2,
  large: 0.45,
  'very large': 0.8,
}

export const setSizeCoefficient = (imgWidth: number) => {
  const size = getSizeImage(imgWidth)
  return sizeCoefficientMapping[size] || 1
}

export const drawSvg = (pattern: any, color: any, imgWidth: number) => {
  const coordinates =
    typeof pattern.coordinates === 'string'
      ? JSON?.parse(pattern.coordinates)
      : pattern.coordinates
  const pointLinks = pattern.point_links
    ? typeof pattern.point_links === 'string'
      ? JSON?.parse(pattern.point_links)
      : pattern.point_links
    : []

  const points: { [id: string]: any } = {}
  coordinates.forEach((point: any) => {
    points[point.id] = point
  })

  const coords = extractViewBoxData(pattern.svg)
  setSizePatternDependingOnImageSize(imgWidth, {
    width: coords?.width,
    height: coords?.height,
  })

  const coefficient = returnTransformNumber(48, imgWidth)
  const coefficientPoint = returnTransformNumber(12, imgWidth)
  const coefficientPointNameX = returnTransformNumber(16, imgWidth)
  const coefficientPointNameY = returnTransformNumber(patternNamesY, imgWidth)

  const svgHeader = `<svg viewBox="0 0 ${
    coords?.width ? coords?.width + coefficient : coefficient
  } ${coords?.height ? coords?.height + coefficient : coefficient}">`
  const lines = pointLinks.map((link: any) => {
    const start = points[link.start]
    const end = points[link.end]
    return `<line 
      id="${link.start} ${link.end}"
      x1="${start.x + coefficientPoint}" 
      y1="${start.y + coefficientPoint}" 
      x2="${end.x + coefficientPoint}" 
      y2="${end.y + coefficientPoint}" 
      stroke="${color}" 
      stroke-width="${strokeLine}"
    ></line>`
  })

  const circles = coordinates.map((coordinate: any) => {
    return `<circle 
      id="${coordinate.id}"
      cx="${coordinate.x + coefficientPoint}"
      cy="${coordinate.y + coefficientPoint}"
      r="${radius}"
      fill="#FFFFFF"
      stroke="${color}"
      stroke-width="${stroke}"
    ></circle>`
  })

  const pointNames = coordinates
    .filter((coordinate: any) => coordinate.name)
    .map((coordinate: any) => {
      return `<text class="point-name"
        x="${coordinate.x + coefficientPointNameX}"
        y="${coordinate.y - coefficientPointNameY}"
        font-size="${fontSize}"
        fill="#fefefe"
        dominant-baseline="middle"
        font-weight="500"
        text-anchor="${
          coords && coords?.width / 2 < coordinate.x ? 'end' : 'start'
        }"
      >
          ${coordinate.name}
      </text>`
    })

  const svgFooter = `</svg>`

  return `${svgHeader}${lines.join('')}${circles.join('')}${pointNames.join(
    '',
  )}${svgFooter}`
}

export const extractViewBoxData = (svgString: string) => {
  const pattern =
    /viewBox="(-?\d+\.?\d*(?:e[+-]\d+)?) (-?\d+\.?\d*(?:e[+-]\d+)?) (-?\d+\.?\d*(?:e[+-]\d+)?) (-?\d+\.?\d*(?:e[+-]\d+)?)"/
  const match = svgString.match(pattern)
  if (match) {
    const [, x, y, width, height] = match.map(Number)
    return { x, y, width, height }
  } else {
    return null
  }
}

export const findRightMostTextWidth = (coords: any, imgWidth: number) => {
  let rightmostText = null // Переменная для хранения самого правого элемента 'text'
  let rightmostTextWidth = 0 // Переменная для хранения ширины самого правого элемента 'text'

  // Создание временного элемента svg
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  svg.setAttribute('width', '0')
  svg.setAttribute('height', '0')
  document.body.appendChild(svg)

  for (const coordinate of coords) {
    if (coordinate.name) {
      const textElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'text',
      )
      const text = document.createTextNode(coordinate.name)
      textElement.appendChild(text)

      // Установка атрибутов текстового элемента
      textElement.setAttribute('font-size', '10')
      textElement.setAttribute('fill', '#fefefe')
      textElement.setAttribute('dominant-baseline', 'middle')
      textElement.setAttribute('font-weight', '500')

      // Добавление текстового элемента к временному элементу svg
      svg.appendChild(textElement)

      // Рассчитываем ширину текста
      const textWidth = textElement.getComputedTextLength()

      // Проверяем, является ли текущий текст самым правым
      if (coordinate.x + textWidth > rightmostTextWidth) {
        rightmostText = coordinate.name
        rightmostTextWidth = textWidth + returnTransformNumber(12, imgWidth)
      }

      // Удаление текстового элемента из временного элемента svg
      svg.removeChild(textElement)
    }
  }

  // Удаление временного элемента svg
  document.body.removeChild(svg)

  return rightmostTextWidth
}

export const resizeSVGWithSizeCoefficient = (
  svgString: string,
  setSizeCoefficient: number,
) => {
  // Находим значение viewBox
  const viewBoxRegex = /viewBox="([^"]+)"/
  const viewBoxMatch = svgString.match(viewBoxRegex)

  if (viewBoxMatch) {
    const viewBoxValues = viewBoxMatch[1].split(' ')

    if (viewBoxValues.length === 4) {
      // Получаем значения ширины и высоты и умножаем их на коэффициент
      const newWidth = parseFloat(viewBoxValues[2]) * setSizeCoefficient
      const newHeight = parseFloat(viewBoxValues[3]) * setSizeCoefficient

      // Формируем новую строку для viewBox с измененными значениями
      const newViewBox = `${viewBoxValues[0]} ${viewBoxValues[1]} ${newWidth} ${newHeight}`

      // Заменяем значения ширины и высоты в строке SVG
      return svgString.replace(viewBoxRegex, `viewBox="${newViewBox}"`)
    }
  }

  return svgString
}

export const returnTransformNumber = (number: number, imgWidth: number) => {
  return number * setSizeCoefficient(imgWidth)
}

export const setCircleRadiusSize = (
  imgWidth: number,
  svgWidth: any,
  edit: boolean,
) => {
  const imageSize = getSizeImage(imgWidth)
  const svgWidthValue = svgWidth.width

  const radiusMap = {
    small: {
      '<=44': edit ? 1 : 0.7,
      '<=88': edit ? 1.8 : 1.5,
      default: edit ? 2.5 : 2.3,
    },
    medium: {
      '>400': edit ? 4.5 : 3.7,
      '>300': edit ? 4 : 3,
      '>200': edit ? 3.5 : 2.5,
      '>120': edit ? 3 : 2,
      default: edit ? 2.3 : 1.5,
    },
    large: {
      '>500': edit ? 9.5 : 8,
      '>400': edit ? 8.2 : 7,
      '>250': edit ? 7.2 : 6,
      default: edit ? 5 : 4,
    },
    'very large': {
      '>900': edit ? 14 : 12,
      '>400': edit ? 11 : 8.5,
      default: edit ? 7 : 5,
    },
    big: {
      '>1200': edit ? 21 : 16,
      '>600': edit ? 16 : 10,
      default: edit ? 8 : 6,
    },
  }

  radius =
    radiusMap[imageSize]?.[`${svgWidthValue}`] ??
    radiusMap[imageSize]?.default ??
    (edit ? 8 : 6)
}

const setStrokeLineSize = (imgWidth: number, svgWidth: any) => {
  const imageSize = getSizeImage(imgWidth)
  const svgWidthValue = svgWidth.width

  const strokeLineMap = {
    small: {
      '<=44': 0.5,
      '<=88': 1,
      default: 1.2,
    },
    medium: {
      '>400': 2.5,
      '>200': 2,
      default: 1.5,
    },
    large: {
      '>500': 3.5,
      '>250': 3,
      default: 2,
    },
    'very large': {
      '>900': 8,
      '>400': 6,
      default: 4,
    },
    big: {
      '>1200': 10,
      '>600': 7,
      default: 4,
    },
  }

  strokeLine =
    strokeLineMap[imageSize]?.[`${svgWidthValue}`] ??
    strokeLineMap[imageSize]?.default ??
    4
}

export const setCircleStrokeSize = (imgWidth: number, svgWidth: any) => {
  const imageSize = getSizeImage(imgWidth)
  const svgWidthValue = svgWidth.width

  const strokeMap = {
    small: {
      '<=44': 0.3,
      '<=88': 0.5,
      default: 0.7,
    },
    medium: {
      '>400': 1.4,
      '>200': 1.2,
      default: 1,
    },
    large: {
      '>500': 2.7,
      '>250': 2.5,
      default: 2,
    },
    'very large': {
      default: 4,
    },
    big: {
      '>1200': 8,
      '>600': 6,
      default: 4,
    },
  }

  stroke =
    strokeMap[imageSize]?.[`${svgWidthValue}`] ??
    strokeMap[imageSize]?.default ??
    4
}

export const setPatternNamesSize = (imgWidth: number, svgWidth: any) => {
  const imageSize = getSizeImage(imgWidth)
  const svgWidthValue = svgWidth.width

  const fontSizeMap = {
    small: {
      '<=50': 2,
      default: 3,
    },
    medium: {
      '>400': 6,
      '>200': 5,
      default: 4,
    },
    large: {
      '>500': 12,
      default: 10,
    },
    'very large': {
      '>800': 22,
      default: 20,
    },
    big: {
      '>1000': 24,
      default: 20,
    },
  }

  fontSize =
    fontSizeMap[imageSize]?.[`${svgWidthValue}`] ??
    fontSizeMap[imageSize]?.default ??
    20
}

const setPatternNamesY = (imgWidth: number, svgWidth: any) => {
  const imageSize = getSizeImage(imgWidth)
  const svgWidthValue = svgWidth.width

  const patternNamesYMap = {
    small: {
      '<=50': 1,
      default: 4,
    },
    medium: {
      '>400': 24,
      '>200': 16,
      default: 4,
    },
    large: {
      '>500': 20,
      '>300': 16,
      '>200': 8,
      default: 4,
    },
    'very large': {
      '>1500': 32,
      '>1200': 30,
      '>1000': 28,
      '>800': 24,
      '>600': 20,
      '>400': 16,
      default: 8,
    },
    big: {
      '>2000': 30,
      '>1500': 24,
      '>1000': 20,
      '>500': 16,
      default: 8,
    },
  }

  patternNamesY =
    patternNamesYMap[imageSize]?.[`${svgWidthValue}`] ??
    patternNamesYMap[imageSize]?.default ??
    8
}

export const setEditPattern = (imgWidth: any, svgWidth: any) => {
  const selected = document.querySelector('.selected')
  if (selected) {
    selected.classList.add('edit')
    const pattern = selected.querySelector('.svg-pattern')
    // @ts-ignore
    const circles = pattern.querySelectorAll('circle')
    const coords = extractViewBoxData(svgWidth)
    setEditCircleRadiusSize(imgWidth, coords, circles)
  }
}

export const setEditCircleRadiusSize = (
  imgWidth: any,
  svgCoords: any,
  circles: any,
) => {
  setCircleRadiusSize(imgWidth, svgCoords, true)
  circles.forEach((item: any) => {
    // @ts-ignore
    item.setAttribute('r', radius)
  })
}

export const patternTransform = () => {
  const annotationlayer = document.querySelector('.a9s-annotationlayer')

  if (annotationlayer) {
    // @ts-ignore
    const childG = annotationlayer?.firstChild

    if (childG) {
      const svgPatternList = document.querySelectorAll('.svg-pattern')
      svgPatternList.forEach(function (svgPattern) {
        // @ts-ignore
        const parentSvgG = svgPattern.parentNode.parentNode
        // @ts-ignore
        if (parentSvgG && parentSvgG.tagName == 'g') {
          // @ts-ignore
          const gTransform = parentSvgG.getAttribute('transform')
          const scaleValue = gTransform.match(/scale\((.*?)\)/)[1]
          const reverseScale = 1 / parseFloat(scaleValue)
          // @ts-ignore
          const styleValue = svgPattern
            .getAttribute('style')
            .replace(/transform: scale\(.+?\);/g, '')
          svgPattern.setAttribute(
            'style',
            // @ts-ignore
            `${styleValue}; transform: scale(${reverseScale})`,
          )
        }
      })
    }
  }
}
export const hidePattern = (hidden: boolean) => {
  const annotationlayer = document.querySelector('.a9s-annotationlayer')

  if (annotationlayer) {
    // @ts-ignore
    const childG = annotationlayer?.firstChild

    if (childG) {
      const svgPatternList = document.querySelectorAll('.svg-pattern')
      svgPatternList.forEach(function (svgPattern) {
        if (hidden) {
          // @ts-ignore
          svgPattern.classList.add('hidden')
        } else {
          svgPattern.classList.remove('hidden')
        }
      })
    }
  }
  if (!hidden) {
    patternTransform()
  }
}
