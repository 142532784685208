import { IFilter, IFullTaskPattern, IPaginationOption, ITask } from '@/types/interfaces'
import { EgalActionConstructor } from '@/helpers/EgalActionConstructor'
import { APIModels, Microservices, TaskStatuses } from '@/types/enums'
import { TaskStatistic } from '@/types/types'
import { logout } from '@/helpers/logout'

class TaskAPIModel {
  async getTasks(
    { page, perPage }: IPaginationOption,
    filter: IFilter[],
  ): Promise<any> {
    return await EgalActionConstructor.getItems(
      Microservices.Core,
      APIModels.Task,
    )
      .withs(['project'])
      .setPagination(perPage, page)
      .filter(filter)
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async getTask(id: number): Promise<any> {
    return await EgalActionConstructor.getItem(
      Microservices.Core,
      APIModels.Task,
      id,
    )
      .withs(['executor', 'reconciliator'])
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async getTaskWithImages(id: number): Promise<any> {
    return await EgalActionConstructor.getItem(
      Microservices.Core,
      APIModels.Task,
      id,
    )
      .withs(['project', 'uploadedImages'])
      .call()
      .then((response) => {
        return { ...response, images: response.uploaded_images }
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async getTaskStatistic(taskId: number): Promise<TaskStatistic> {
    return await EgalActionConstructor.custom(
      Microservices.Core,
      APIModels.Task,
      'TaskStatistic',
      { id: taskId },
    ).call()
  }

  async createTask(taskData: Partial<ITask>): Promise<any> {
    return await EgalActionConstructor.create(
      Microservices.Core,
      APIModels.Task,
      taskData,
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async updateTask(taskData: Partial<ITask>): Promise<any> {
    return await EgalActionConstructor.update(
      Microservices.Core,
      APIModels.Task,
      taskData,
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async deleteTask(id: number): Promise<any | Error> {
    return EgalActionConstructor.update(Microservices.Core, APIModels.Task, {
      id,
      status: TaskStatuses.Deleted,
    })
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async getTasksPatterns(
    deletePatterns_id: number[],
    selectedProject_ids: number[],
    perPage = 1000,
    page = 1,
  ): Promise<IFullTaskPattern[]> {
    return EgalActionConstructor.custom(Microservices.Core, APIModels.Pattern, 'getTasks',
      {ids: deletePatterns_id, project_ids: selectedProject_ids},)
      .setPagination(perPage, page)
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }
}

export const TaskAPI = new TaskAPIModel()
