export const errorDictionary = {
  'Validation failed! The name has already been taken.': 'Название уже занято',
  'Validation failed! The selected email is invalid.':
    'Данного пользователя не существует',
  'User does not exists': 'Данного пользователя не существует',
  'Project exists': 'Проект уже существует',
  'Task exists': 'Задача уже существует',
  'Organization members limit has been exceeded':
    'Лимит добавления участников превышен',
}
