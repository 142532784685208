export const buttonStyleConfig = {
  backgroundColor: '#3099EB',
  borderRadius: '4px',
  fontFamily: 'Inter, sans-serif',
  fontWeight: 500,
  padding: '11.5px 16px',
  lineHeight: '17px',
  hover: {
    backgroundColor: '#44ABFC',
  },
  active: {
    backgroundColor: '#1D87DA',
  },
  disabled: {
    color: '#A0AEC0',
    backgroundColor: '#E2E8F0',
  },
}

export const buttonStyleConfigOutline = {
  backgroundColor: 'transparent',
  color: '#3099EB',
  border: '1px solid #3099EB',
  borderRadius: '4px',
  fontFamily: 'Inter, sans-serif',
  fontWeight: 500,
  padding: '11.5px 16px',
  lineHeight: '17px',
  hover: {
    color: '#44ABFC',
    border: '1px solid #44ABFC',
  },
  active: {
    color: '#1D87DA',
    border: '1px solid #1D87DA',
  },
  disabled: {
    color: '#A0AEC0',
    border: '1px solid #A0AEC0',
  },
}

export const buttonStyleConfigSecondary = {
  backgroundColor: '#DEEBFC',
  color: '#3099EB',
  borderRadius: '4px',
  fontFamily: 'Inter, sans-serif',
  fontWeight: 500,
  padding: '11.5px 16px',
  lineHeight: '17px',
  hover: {
    backgroundColor: '#A6C8FA',
  },
  active: {
    backgroundColor: '#76ACFB',
  },
  disabled: {
    color: '#A0AEC0',
    backgroundColor: '#E2E8F0',
  },
}

export const buttonStyleConfigClear = {
  backgroundColor: 'transparent',
  fontFamily: 'Inter, sans-serif',
  fontWeight: 500,
  padding: '11.5px 16px',
  lineHeight: '17px',
  color: '#3099EB',
  hover: {
    color: '#44ABFC',
  },
  active: {
    color: '#1D87DA',
  },
  disabled: {
    color: '#A0AEC0',
  },
}

// TODO: Спросить у Данила состояния
export const buttonVariantConfigClearBlack = {
  color: '#2D3748',
  hover: {
    color: '#2D3748',
  },
  active: {
    color: '#2D3748',
  },
  disabled: {
    borderColor: '#e2e8f0',
    color: '#A0AEC0',
  },
}

export const buttonVariantConfigDanger = {
  backgroundColor: '#F16063',
  color: '#FFFFFF',
  hover: {
    backgroundColor: '#FF7880',
  },
  active: {
    backgroundColor: '#DB373D',
  },
  disabled: {
    borderColor: '#e2e8f0',
    color: '#A0AEC0',
  },
}

export const buttonVariantConfigOutlineDanger = {
  borderRadius: '4px',
  fontFamily: 'Inter, sans-serif',
  fontWeight: 500,
  padding: '11.5px 16px',
  lineHeight: '17px',
  backgroundColor: 'transparent',
  color: '#F16063',
  border: '1px solid #F16063',
  hover: {
    color: '#FF7880',
    border: '1px solid #FF7880',
  },
  active: {
    color: '#DB373D',
    border: '1px solid #DB373D',
  },
  disabled: {
    borderColor: '#e2e8f0',
    color: '#A0AEC0',
  },
}

export const buttonVariantConfigClearDanger = {
  backgroundColor: 'transparent',
  color: '#F16063',
  hover: {
    color: '#FF7880',
  },
  active: {
    color: '#DB373D',
  },
  disabled: {
    borderColor: '#e2e8f0',
    color: '#A0AEC0',
  },
}

export const buttonStyleConfigSuccess = {
  backgroundColor: '#66CB9F',
  borderRadius: '4px',
  fontFamily: 'Inter, sans-serif',
  fontWeight: 500,
  padding: '11.5px 16px',
  lineHeight: '17px',
  hover: {
    backgroundColor: '#6DEFB6',
  },
  active: {
    backgroundColor: '#40B481',
  },
  disabled: {
    color: '#A0AEC0',
    backgroundColor: '#E2E8F0',
  },
}
