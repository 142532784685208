export const getSVGParams = (svgString: string) => {
  const viewBoxStart = svgString.indexOf('viewBox="') + 'viewBox="'.length;
  const viewBoxEnd = svgString.indexOf('"', viewBoxStart);

// Извлечь подстроку, содержащую значения ширины и высоты
  const viewBoxValue = svgString.substring(viewBoxStart, viewBoxEnd);

// Разделить значения ширины и высоты
  const [x, y, width, height] = viewBoxValue.split(' ');
  
  return {width, height}
}