import { DatasetApi } from '@/api/DatasetAPI'
import { IDataset, IDatasetForCreation } from '@/types/interfaces'
import { defineStore } from 'pinia'

interface DatasetStoreState {
  datasets: IDataset[]
  downloadingDataset: IDataset | null
}

export const useDatasetStore = defineStore('datasetStore', {
  state: (): DatasetStoreState => {
    return {
      datasets: [],
      downloadingDataset: null,
    }
  },

  actions: {
    async getDatasets() {
      await DatasetApi.getDatasets()
        .then((response) => {
          this.datasets = response
        })
        .catch((error) => {
          console.log(error)
        })
    },

    async getDataset(datasetId: number) {
      await DatasetApi.getDataset(datasetId)
        .then((response) => {
          this.downloadingDataset = response
        })
        .catch((error) => {
          console.log(error)
        })
    },

    async createDataset(datasetInfo: IDatasetForCreation) {
      await DatasetApi.createDataset(datasetInfo).catch((error) => {
        throw error
      })
    },
  },
})
