import { EgalAuthConstructor } from '@egalteam/framework'
import { EgalActionConstructor } from '@/helpers/EgalActionConstructor'
import { APIModels, Microservices } from '@/types/enums'
import {
  IAuthData,
  IPasswordRestoration,
  IRegisterConfirm,
  IRegisterData,
  IUser,
} from '@/types/interfaces'
import { useCookies } from 'vue3-cookies'
import { PasswordChangingForm } from '@/types/types'
import axios from 'axios'
import { logout } from '@/helpers/logout'

const { cookies } = useCookies()

class UserAPIModel {
  authModel: EgalAuthConstructor
  microservices: Microservices[]

  constructor() {
    this.microservices = [Microservices.Auth, Microservices.Core]

    this.authModel = new EgalAuthConstructor({
      modelName: APIModels.User,
      url: process.env.VUE_APP_API_BASE_URL || '',
      connectionType: 'axios',
    })
  }

  /**
   * Основной логин.
   * Получает UMT для авторизации в микросервисах
   * @param authData - Данные авторизации
   */
  async login(authData: IAuthData): Promise<void> {
    return this.authModel
      .authUser(authData)
      .then(async ([responseData]) => {
        const loginPromiseArr = this.microservices.map(
          async (microservice) =>
            await this.loginToService(
              responseData.user_master_token,
              microservice,
            ),
        )

        await Promise.all(loginPromiseArr)
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async register(registerData: IRegisterData): Promise<void> {
    return (
      this.authModel
        //@ts-ignore Типизация егала неправильная!
        .registerNewUser({ attributes: registerData })
        .catch((error) => {
          logout(error)
          throw error
        })
    )
  }

  async confirmUserWithEmail(confirmData: IRegisterConfirm): Promise<any> {
    return axios
      .post(
        `${process.env.VUE_APP_API_BASE_URL}/${Microservices.Auth}/${APIModels.User}/confirmRegistration`,
        { attributes: confirmData },
      )
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  /**
   * Авторизация в выбранном микросервисе
   * @param token - UMT
   * @param service_name - Имя микросервиса
   * @protected
   */
  protected async loginToService(token: string, service_name: Microservices) {
    const [ust] = await this.authModel.loginToService({
      token,
      service_name,
    })

    cookies.set(service_name, ust)
    return
  }

  // async getUsers(filter: (string[] | string)[] = []): Promise<any> {
  //   return EgalActionConstructor.getItems(Microservices.Core, APIModels.User)
  //     .filter(filter, 'custom')
  //     .call()
  //     .then((response) => {
  //       return response
  //     })
  //     .catch((error) => {
  //       logout(error)
  //       throw error
  //     })
  // }

  async getUser(): Promise<any> {
    return EgalActionConstructor.custom(
      Microservices.Core,
      APIModels.User,
      'getUser',
      {},
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async sendEmailToRestorePassword(email: string): Promise<void> {
    return EgalActionConstructor.custom(
      Microservices.Auth,
      APIModels.User,
      'dropPassword',
      { email },
    )
      .call()
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async sendNewPassword(newPasswordData: IPasswordRestoration): Promise<void> {
    return EgalActionConstructor.custom(
      Microservices.Auth,
      APIModels.User,
      'confirmDropPassword',
      newPasswordData,
    )
      .call()
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async updateUser(
    userData: Pick<IUser, 'id' | 'first_name' | 'last_name'>,
  ): Promise<any> {
    return EgalActionConstructor.update(
      Microservices.Core,
      APIModels.User,
      userData,
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async changePassword(
    passwordData: PasswordChangingForm & { id: string },
  ): Promise<any> {
    return EgalActionConstructor.custom(
      Microservices.Auth,
      APIModels.User,
      'changePassword',
      passwordData,
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async getCaptcha(): Promise<any> {
    return EgalActionConstructor.custom(
      Microservices.Auth,
      APIModels.User,
      'getCaptcha',
      {},
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  }

  async validateCaptcha(token: string): Promise<any> {
    return EgalActionConstructor.custom(
      Microservices.Auth,
      APIModels.User,
      'validateCaptcha',
      {
        'g-recaptcha-response': token,
      },
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  }
}

export const UserAPI = new UserAPIModel()
