export enum Microservices {
  Auth = 'auth',
  Core = 'core',
}

export enum APIModels {
  User = 'User',
  Organization = 'Organization',
  Executor = 'Executor',
  Responsible = 'Responsible',
  Project = 'Project',
  ProjectClass = 'ProjectClass',
  ProjectClassAttribute = 'ProjectClassAttribute',
  Pattern = 'Pattern',
  Task = 'Task',
  Image = 'Image',
  ImageClass = 'ImageClass',
  LocatedObject = 'LocatedObject',
  LocatedObjectAttribute = 'LocatedObjectAttribute',
  BinaryObject = 'BinaryObject',
  BinaryObjectAttributes = 'BinaryObjectAttribute',
  ClassificationObject = 'ClassificationObject',
  Subscription = 'Subscription',
  Rate = 'Rate',
  Dataset = 'Dataset',
  Commentary = 'Commentary',
  Segmentation = 'Segmentation',
  SubscriptionHistory = 'SubscriptionHistory',
  SubscriptionRequest = 'SubscriptionRequest',
  Service = 'ServiceRequest',
}

export enum UserRoles {
  Owner = 'Владелец',
  Executor = 'Исполнитель',
  Responsible = 'Ответственный',
  Admin = 'Админ',
}

export enum ExecutorRoles {
  Marker = 'Разметчик',
  Reconciliator = 'Сверщик',
}

export enum MarkupTypes {
  Detection = 'detection',
  Binary = 'binary',
  MultiClass = 'multiclass',
  MultiLabel = 'multilabel',
  Semantic = 'semantic',
  Instance = 'instance',
  Panoptic = 'panoptic',
  Keypoints = 'keypoints',
}

export enum MarkupLabeles {
  Detection = 'Object detection (+ Keypoints)',
  Binary = 'Binary image classification',
  MultiClass = 'Multiclass image classification',
  MultiLabel = 'Multilabel image classification',
  Semantic = 'Semantic segmentation (+ Keypoints)',
  Instance = 'Instance segmentation (+ Keypoints)',
  Panoptic = 'Panoptic segmentation (+ Keypoints)',
  Keypoints = 'Keypoints',
}

export enum ProjectStatuses {
  Active = 'Активный',
  Deleted = 'Удален',
}

export enum TaskStatuses {
  Created = 'Создана',
  SentToMarkup = 'Отправлена на разметку',
  OnMarkup = 'На разметке',
  SentToCheck = 'Отправлена на проверку',
  OnCheck = 'На проверке',
  Completed = 'Завершена',
  Deleted = 'Удалена',
}

export enum TaskTypes {
  Educational = 'Обучающая',
  Validation = 'Валидационная',
  Test = 'Тестовая',
}

export enum Tools {
  Arrow = 'ARROW',
  Hand = 'HAND',
  SelectAll = 'SELECT_ALL',
  Rotate = 'ROTATE',
  Commentary = 'COMMENTARY',
  FullSize = 'FULL_SIZE',
  Magnifier = 'MAGNIFIER',
  MagnifierOut = 'MAGNIFIER_OUT',
  BoundingBox = 'BOUNDING_BOX',
  Pentagon = 'PENTAGON',
  Dots = 'DOTS',
  Identificator = 'IDENTIFICATOR',
  Interactor = 'INTERACTOR',
  Point = 'POINT',
  Line = 'LINE',
  Dump = 'DUMP',
  Text = 'TEXT',
}

export enum MarkupStatuses {
  NoMarkup = 'нет разметки',
  HaveMarkup = 'есть разметка',
  ToDelete = 'необходимо удалить',
}

export enum CheckupStatuses {
  NotChecked = 'не проверено',
  Wrong = 'неверно',
  Checked = 'проверено',
}

export enum DatasetStatuses {
  processing = 'processing',
  gathering = 'gathering',
  zipped = 'zipped',
  uploaded = 'uploaded',
  failed = 'failed',
}

// export enum DatasetStatusesRU {
//   processing = 'формируется',
//   gathering = 'формируется',
//   zipped = 'формируется',
//   uploaded = 'сформирован',
//   failed = 'ошибка',
// }

export enum WorkspaceContextMenuOptions {
  Copy,
  Insert,
  Delete,
  ChangeClass,
  ChangeInstanceClass,
  ChangeAttribute,
  FirstPlan,
  LastPlan,
}

export enum PerPagePagination {
  AdminPerPage = 10,
}

export enum ImageTypes {
  ORIGINAL = 'orig',
  ZIP = 'zip',
  MINIATURE = 'miniatures',
}

export enum SidebarTabs {
  OBJECTS = 'Объекты',
  CLASSES = 'Классы',
  PICTURES = 'Изображения',
}

export enum PatternText {
  PatternBaseTitle = 'Участники',
  PatternsInProjectsTitle = 'Удаление паттернов',
  PatternsInProjectsDesc = 'Удаляемые паттерны уже были использованы при разметке. Чтобы удалить паттерн с разметки изображений проекта, выберите проект и нажмите “Продолжить”.',
  SelectedPatternsInProjectsDesc = 'Удаление паттернов затрагивает задачи внутри проектов. При необходимости назначьте таким задачам другой статус, разметчика и сверщика.',
}

export enum AreaType {
  Bbox = 'rect',
  Polygon = 'polygon',
  Keypoints = 'keypoints',
}

export enum ServiceType {
  MARKUP_SERVICE = 'MARKUP_SERVICE',
  NEURAL_NETWORK_TRAINING = 'NEURAL_NETWORK_TRAINING',
}
export enum SubscriptionPeriod {
  OneMonth = 1,
  ThreeMonth = 3,
  SixMonth = 6,
  TwelweMonth = 12,
}
