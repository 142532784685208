import { CheckupStatuses, MarkupStatuses, TaskStatuses } from '@/types/enums'
import {
  IProjectClass,
  IProjectClassStatistic,
  IProjectStatistic,
} from '@/types/interfaces'
import { ImageClassesStatistic, ImageStatistic } from '@/types/types'

export const useStatistic = () => {
  const createProjectStatisticData = (statistic: IProjectStatistic) => {
    const finishedTasks = statistic.task_statistic.find(
      (task) => task.status === TaskStatuses.Completed,
    )

    const finishedTaskCount =
      finishedTasks !== undefined ? finishedTasks.count : 0

    return [
      {
        title: 'Всего изображений',
        amount: statistic.images_count,
        type: 'project',
      },
      {
        title: 'Всего задач',
        amount: statistic.tasks_count,
        type: 'project',
      },
      {
        title: 'Завершено задач',
        amount: finishedTaskCount,
        type: 'project',
      },
      {
        title: 'Осталось задач',
        amount: statistic.tasks_count - finishedTaskCount,
        type: 'project',
      },
    ]
  }

  const createTaskStatisticData = (statisticData: ImageStatistic) => {
    const totalCount = statisticData.count[0].count
    const deletedTasks = statisticData.to_delete
    const problemTasks = statisticData.problem

    const findStatisticAmount = (
      array: {
        checkup_status?: CheckupStatuses
        markup_status?: MarkupStatuses
        count: number
      }[],
      status: MarkupStatuses | CheckupStatuses,
      type: 'markup' | 'checkup',
    ) => {
      let statisticObject

      if (type === 'markup') {
        statisticObject = array.find(
          (statistic) => statistic.markup_status === status,
        )

        return statisticObject !== undefined ? statisticObject.count : 0
      }

      statisticObject = array.find(
        (statistic) => statistic.checkup_status === status,
      )

      return statisticObject !== undefined ? statisticObject.count : 0
    }

    const checkupData = [
      {
        title: 'Проверенные',
        amount: findStatisticAmount(
          statisticData.checkup_statistic,
          CheckupStatuses.Checked,
          'checkup',
        ),
        type: 'task',
      },
      {
        title: 'Непроверенные',
        amount: findStatisticAmount(
          statisticData.checkup_statistic,
          CheckupStatuses.NotChecked,
          'checkup',
        ),
        type: 'task',
      },
      {
        title: 'Неверно размеченные',
        amount: findStatisticAmount(
          statisticData.checkup_statistic,
          CheckupStatuses.Wrong,
          'checkup',
        ),
        type: 'task',
      },
      {
        title: 'Удалённые',
        amount: deletedTasks,
        type: 'task',
      },
    ]

    const markupData = [
      {
        title: 'Всего изображений',
        amount: totalCount,
        type: 'task',
      },
      {
        title: 'Размеченные',
        amount: findStatisticAmount(
          statisticData.markup_statistic,
          MarkupStatuses.HaveMarkup,
          'markup',
        ),
        type: 'task',
      },
      {
        title: 'Неразмеченные',
        amount: findStatisticAmount(
          statisticData.markup_statistic,
          MarkupStatuses.NoMarkup,
          'markup',
        ),
        type: 'task',
      },
      {
        title: 'Проблемные',
        amount: problemTasks,
        type: 'task',
      },
    ]

    return { markupData, checkupData }
  }

  const calculateStatisticBarWidth = (
    statisticClassesArray:
      | {
          count: number
          name?: string | undefined
          color?: string | undefined
          project_id?: number | undefined
          id?: number | undefined
        }[]
      | undefined,
  ) => {
    if (statisticClassesArray) {
      const amountArray = statisticClassesArray.map((item) => item.count)

      const maxElementSize = Math.max.apply(null, amountArray)

      const result = statisticClassesArray.map((item) => {
        const width = Math.floor((item.count * 100) / maxElementSize)

        return {
          ...item,
          width,
        }
      })

      return result
    }
  }

  const createTaskImageClassesStatistic = (
    classesStatistic: ImageClassesStatistic | undefined,
    projectClasses: IProjectClass[] | undefined,
  ) => {
    if (projectClasses && classesStatistic) {
      const statisticWithCount = classesStatistic.image_classes_statistic.map(
        (statistic) => {
          const correspondedClass = projectClasses.find(
            (projectClass) => projectClass.name === statistic.name,
          )

          return {
            ...correspondedClass,
            count: statistic.count,
          }
        },
      )

      return calculateStatisticBarWidth(statisticWithCount)
    }

    return []
  }

  const createProjectImageClassesStatistic = (
    classesStatistic: IProjectClassStatistic[],
    projectClasses: IProjectClass[] | undefined,
  ) => {
    if (projectClasses) {
      const statisticWithCount = classesStatistic.map((statistic) => {
        const correspondedClass = projectClasses.find(
          (projectClass) => projectClass.name === statistic.name,
        )

        return {
          ...correspondedClass,
          count: statistic.count,
        }
      })

      return calculateStatisticBarWidth(statisticWithCount)
    }

    return []
  }

  return {
    createTaskImageClassesStatistic,
    createTaskStatisticData,
    createProjectStatisticData,
    createProjectImageClassesStatistic,
  }
}
