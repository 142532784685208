export const selectStyleConfig = {
  fontFamily: 'Inter',
  valueColor: '#2d3748',
  valueFontWeight: 400,
  placeholderColor: '#A0AEC0',
  placeholderFontSize: '14px',
  backgroundColor: '#ffffff',
  labelColor: '#a0aec0',
  labelFontWeight: 400,
  labelFontSize: '14px',
  helperTextColor: '#a0aec0',
  helperTextFontWeight: 400,
  borderColor: '#E2E8F0',
  borderRadius: '4px',
  focusBorderColor: '#76ACFB',
  filledBackgroundColor: '#e5f0ff',
  filledFontColor: '#3385ff',
  errorColor: '#f16063',
  arrowColor: '#cbd5e0',
  crossColor: '#a0aec0',
}
