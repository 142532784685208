// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function scrollToElement(containerId: string, elementId: string): void {
  const container: HTMLElement | null = document.getElementById(containerId)
  const element: HTMLElement | null = document.getElementById(elementId)

  if (container && element) {
    const containerRect: DOMRect = container.getBoundingClientRect()
    const elementRect: DOMRect = element.getBoundingClientRect()

    // Проверяем, виден ли элемент внутри контейнера
    const isElementVisible: boolean =
      elementRect.top >= containerRect.top &&
      elementRect.bottom <= containerRect.bottom
    // Если элемент виден, поднимаем его вверх
    if (isElementVisible) {
      container.scrollTop = elementRect.top - containerRect.top
    } else {
      // Если элемент не виден, прокручиваем контейнер так, чтобы элемент оказался в верхней части контейнера
      container.scrollTop = element.offsetTop
    }
  }
}
