export const inputStyleConfig = {
  fontFamily: 'Inter',
  valueColor: '#2d3748',
  valueFontWeight: 400,
  placeholderColor: '#A0AEC0',
  placeholderDisabledColor: '#CBD5E0',
  labelColor: '#A0AEC0',
  labelFontWeight: 400,
  helperTextColor: '#A0AEC0',
  helperTextFontWeight: 400,
  helperTextFontSize: '10px',
  borderColor: '#E2E8F0',
  borderRadius: '4px',
  backgroundColor: '#ffffff',
  backgroundDisabledColor: '#ffffff',
  focusBorderColor: '#76ACFB',
  filledBackgroundColor: '#e5f0ff',
  filledFontColor: '#3385ff',
  searchBackgroundColor: '#f7fafc',
  iconColor: '#cbd5e0',
  errorColor: '#f16063',
  successColor: '#66cb9f',
}

export const bottomBorderlessInputStyleConfing = {
  fontFamily: 'Inter',
  valueColor: '#2d3748',
  valueFontWeight: 400,
  placeholderColor: '#A0AEC0',
  placeholderDisabledColor: '#CBD5E0',
  labelColor: '#A0AEC0',
  labelFontWeight: 400,
  helperTextColor: '#A0AEC0',
  helperTextFontWeight: 400,
  helperTextFontSize: '10px',
  borderColor: '#E2E8F0',
  borderRadius: '4px 4px 0 0',
  backgroundColor: '#ffffff',
  backgroundDisabledColor: '#ffffff',
  focusBorderColor: '#76ACFB',
  filledBackgroundColor: '#e5f0ff',
  filledFontColor: '#3385ff',
  searchBackgroundColor: '#f7fafc',
  iconColor: '#cbd5e0',
  errorColor: '#f16063',
  successColor: '#66cb9f',
}
