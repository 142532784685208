import { useWorkspaceStore } from '@/store/workspaceStore'
import { MarkupStatuses, MarkupTypes } from '@/types/enums'

export const useWorkspaceMarkup = () => {
  const workspaceStore = useWorkspaceStore()

  const updateMarkup = async (data?: any) => {
    if (
      workspaceStore.currentTask.project?.markup_type === MarkupTypes.Binary
    ) {
      if (
        workspaceStore.currentBinaryObject &&
        workspaceStore.currentImage.markup_status === MarkupStatuses.NoMarkup
      ) {
        await workspaceStore.changeMarkupStatus(MarkupStatuses.HaveMarkup)

        return
      }
    } else if (
      workspaceStore.currentTask.project?.markup_type ===
        MarkupTypes.MultiClass ||
      workspaceStore.currentTask.project?.markup_type === MarkupTypes.MultiLabel
    ) {
      if (workspaceStore.currentImage.markup_status === MarkupStatuses.ToDelete)
        return
    } else if (
      (workspaceStore.currentTask.project?.markup_type ===
        MarkupTypes.Detection ||
        workspaceStore.currentTask.project?.markup_type ===
          MarkupTypes.Semantic ||
        workspaceStore.currentTask.project?.markup_type ===
          MarkupTypes.Panoptic ||
        workspaceStore.currentTask.project?.markup_type ===
          MarkupTypes.Instance) &&
      workspaceStore.currentImageObjects.length &&
      workspaceStore.currentImage.markup_status === MarkupStatuses.NoMarkup
    ) {
      workspaceStore.currentImage.markup_status = MarkupStatuses.HaveMarkup
      workspaceStore.segmentedImageList[
        workspaceStore.currentImageListSegmentIndex
      ][workspaceStore.currentImageIndexInSegment].markup_status =
        MarkupStatuses.HaveMarkup
      await workspaceStore.changeMarkupStatus(MarkupStatuses.HaveMarkup)
    } else if (
      !workspaceStore.currentImageObjects.length &&
      workspaceStore.currentImage.markup_status === MarkupStatuses.HaveMarkup
    ) {
      workspaceStore.currentImage.markup_status = MarkupStatuses.NoMarkup
      workspaceStore.segmentedImageList[
        workspaceStore.currentImageListSegmentIndex
      ][workspaceStore.currentImageIndexInSegment].markup_status =
        MarkupStatuses.NoMarkup
      await workspaceStore.changeMarkupStatus(MarkupStatuses.NoMarkup)

      return
    } else if (
      workspaceStore.currentImage.markup_status === MarkupStatuses.NoMarkup &&
      data && data.create_attributes.objects.length !== 0
    ) {
      await workspaceStore.changeMarkupStatus(MarkupStatuses.HaveMarkup)
    }
  }

  return { updateMarkup }
}
