import { AreaType } from '@/types/enums'

const getAreaTypeId = (areaType: string) => {
  if (areaType === AreaType.Bbox) return 1
  if (areaType === AreaType.Polygon) return 2
  if (areaType === AreaType.Keypoints) return 3
  return 1
}

export default getAreaTypeId
