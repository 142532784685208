import {ref, onMounted, onUnmounted} from 'vue'

export default function useClickOutside(elementRef: any) {
    const isOutside = ref(false)

    const handleClickOutside = (event: any) => {
        if (elementRef.value && !elementRef.value.contains(event.target)) {
            isOutside.value = true
        } else {
            isOutside.value = false
        }
    }

    onMounted(() => {
        document.addEventListener('click', handleClickOutside)
    })

    onUnmounted(() => {
        document.removeEventListener('click', handleClickOutside)
    })

    return isOutside
}