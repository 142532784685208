import { useWorkspaceStore } from '@/store/workspaceStore'
import { computed, onUnmounted, Ref, ref, watch, watchEffect } from 'vue'
import { Tools } from '@/types/enums'
import * as Annotorious from '@recogito/annotorious-openseadragon'
import OpenSeadragon from 'openseadragon'
import {
  IAnnotation,
  IAnnotationCoordinatesPolygon,
  IAnnotationCoordinatesRect,
  ILine,
  IPoint,
  ISelection,
} from '@/types/interfaces'
import { useDebounceFn } from '@vueuse/core'
import { useContextMenu } from '@/composables/useContextMenu'
import {
  drawSvg,
  extractViewBoxData,
  parseTransformString,
  returnTransformNumber,
  setEditPattern,
  setSizeCoefficient,
  patternTransform,
  setEditCircleRadiusSize,
} from '@/helpers/workWithSvgInViewerHelper'
import { injectEventBus } from '@/helpers/EventBus'
import { useRoute } from 'vue-router'

type TLastPosition = null | any

export const useWorkspaceAnnotations = async (
  viewer: OpenSeadragon.Viewer,
  onOpenContextMenuViewerCallback: (x: number, y: number) => void,
  onCloseContextMenuViewerCallback: (x: number, y: number) => void,
) => {
  const workspaceStore = useWorkspaceStore()
  const eventBus = injectEventBus()
  const lastShadeIndex = ref(0)
  const lastPosition = ref(1)
  const changePattern = ref<TLastPosition>(null)
  const isDragPattern = ref(false)
  const newPatternSvg = ref(null)
  const points: Ref<IPoint[]> = ref([])
  const lines: Ref<ILine[]> = ref([])
  const currentCircle = ref(null)
  let isNewSelected = false
  const isTargetChanging = ref(false)
  const isTargetChanged = ref(false)
  const isAnnotationsRendered = ref(false)

  const route = useRoute()

  const contextMenuOpenAnnotationCallback = (
    x: number,
    y: number,
    e: MouseEvent,
  ) => {
    if (!Object.keys(e).length) {
      return
    }

    const path = Array.from(e.composedPath()) as HTMLElement[]
    const clickedObjectElem = path.find((el) => {
      if (el.classList) {
        return el.classList.contains('a9s-annotation')
      }
    })

    if (clickedObjectElem) {
      const annotation = workspaceStore.currentImageObjects.find(
        (storedAnnotation) =>
          String(storedAnnotation.id) === clickedObjectElem.dataset.id,
      )

      workspaceStore.selectedContextMenuObject = annotation
        ? annotation
        : ({} as IAnnotation)
    } else {
      workspaceStore.selectedContextMenuObject = {} as IAnnotation
    }

    if (
      // @ts-ignore
      e?.target?.parentNode?.classList.contains('svg-pattern') ||
      // @ts-ignore
      e?.target?.parentNode?.parentNode?.classList.contains('svg-pattern')
    ) {
      onOpenContextMenuViewerCallback(e.x - 65, e.y - 113)
    } else {
      onOpenContextMenuViewerCallback(x, y)
    }
  }
  const contextMenuCloseAnnotationCallback = (x: number, y: number) => {
    onCloseContextMenuViewerCallback(x, y)
  }

  const deselectAnnotations = () => {
    isMultipleSelection = false
    workspaceStore.annotationsFlags.selectingAll = false
    workspaceStore.selectedObject = {} as IAnnotation
  }

  const contextMenu = useContextMenu(
    contextMenuOpenAnnotationCallback,
    contextMenuCloseAnnotationCallback,
  )

  const anno = Annotorious(viewer, {
    disableEditor: true,
    allowEmpty: true,
  })

  const AnnotationColorFormatter = (annotation: any) => {
    if (!annotation.bodies.length) {
      return
    }

    const annotationClassColor = annotation.bodies[5]?.value?.value
      ? annotation.bodies[5].value.value
      : annotation.bodies[1].value

    return {
      style: `--class-color: ${annotationClassColor};`,
    }
  }

  const AnnotationLabelFormatter = (annotation: any) => {
    if (!annotation.bodies.length) {
      return
    }

    const annotationClassName = annotation.bodies[0].value
    const annotationOrder = annotation.bodies[6]?.value || ''

    const foreignObject = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'foreignObject',
    )

    if (
      annotation.bodies[7]?.value &&
      annotation.target.selector.type === 'FragmentSelector'
    ) {
      setPattern(
        annotation,
        foreignObject,
        annotationClassName,
        annotationOrder,
      )
    } else {
      foreignObject.innerHTML = `<span class='annotation-label' xmlns='http://www.w3.org/1999/xhtml'>${annotationClassName} ${annotationOrder}</span>`
    }

    foreignObject.addEventListener('mousedown', function (event) {
      event.preventDefault()
      // @ts-ignore
      const clickedCircle = event.target.closest('circle')
      if (clickedCircle) {
        handlePointerDown(event, annotation, foreignObject, clickedCircle)
      }
    })

    return {
      element: foreignObject,
    }
  }

  const setPattern = (
    annotation: IAnnotation,
    foreignObject: any,
    annotationClassName: any,
    annotationOrder: any,
  ) => {
    // @ts-ignore
    const annotationPattern = drawSvg(
      annotation?.body[7]?.value,
      annotation?.body[1]?.value,
      // @ts-ignore
      viewer.viewport._contentSize.x,
    )
    patternTransform()

    const pattern: any =
      typeof annotation.body[7]?.value === 'string'
        ? JSON?.parse(annotation?.body[7]?.value)
        : annotation?.body[7]?.value

    const annotationPatternName = pattern && pattern.name ? pattern.name : ''

    const coords = extractViewBoxData(annotationPattern)
    foreignObject.innerHTML = `<span class='annotation-label' xmlns='http://www.w3.org/1999/xhtml'>${annotationClassName} ${annotationOrder} ${annotationPatternName}</span><span class='svg-pattern' xmlns='http://www.w3.org/1999/xhtml' style="width: ${coords?.width}px; height: ${coords?.height}px">${annotationPattern}</span>`
  }

  const handlePointerDown = (
    event: MouseEvent,
    annotation: IAnnotation,
    foreignObject: any,
    clickedCircle: any,
  ) => {
    isDragPattern.value = true
    // @ts-ignore
    currentCircle.value = clickedCircle
    if (currentCircle.value) {
      changePattern.value = changePattern.value
        ? changePattern.value
        : annotation.body[7]?.value

      points.value =
        // @ts-ignore
        typeof changePattern.value.coordinates === 'string'
          ? // @ts-ignore
            JSON?.parse(changePattern?.value?.coordinates)
          : // @ts-ignore
            changePattern?.value?.coordinates
      lines.value =
        // @ts-ignore
        typeof changePattern?.value?.point_links === 'string'
          ? // @ts-ignore
            JSON?.parse(changePattern?.value?.point_links)
          : // @ts-ignore
            changePattern?.value?.point_links

      const holst = document.querySelector('.a9s-annotationlayer')
      const g = holst?.querySelector('g')
      let transform = {
        translate: {
          x: 0,
          y: 0,
        },
        scale: {
          x: 1,
          y: 1,
        },
      }

      if (g) {
        const transformAttr = g.getAttribute('transform')
        if (transformAttr) transform = parseTransformString(transformAttr)
      }

      setTimeout(() => {
        foreignObject.addEventListener('mousemove', (evt: MouseEvent) =>
          handlePointerMove(
            evt,
            // @ts-ignore
            currentCircle.value.id,
            annotation,
            foreignObject,
            transform,
          ),
        )
        foreignObject.addEventListener('mouseup', () =>
          handlePointerUp(annotation),
        )
      }, 10)
    }
  }

  const handlePointerMove = (
    event: MouseEvent,
    draggingPointId: string,
    annotation: any,
    foreignObject: any,
    transform: any,
  ) => {
    if (isDragPattern.value && points.value && points.value.length) {
      const zoom = viewer.viewport.getZoom(true)

      const svgRect = (event.currentTarget as Element).getBoundingClientRect()

      points.value = points.value.map((point: any) => {
        if (
          point.id === draggingPointId &&
          // @ts-ignore
          point.id === currentCircle.value.id
        ) {
          let scaledX, scaledY
          if (zoom === 1) {
            scaledX = (event.clientX - svgRect.left - 6) / transform.scale.x
            scaledY = (event.clientY - svgRect.top - 6) / transform.scale.y
          } else {
            scaledX = (event.clientX - svgRect.left - 6) / transform.scale.x
            scaledY = (event.clientY - svgRect.top - 6) / transform.scale.y
          }
          return {
            ...point,
            x: scaledX < 0 ? 0 : scaledX,
            y: scaledY < 0 ? 0 : scaledY,
          }
        }
        return point
      })

      const updatePattern = {
        ...annotation.body[7]?.value,
        coordinates: JSON.stringify(points.value),
        svg: newPatternSvg.value
          ? newPatternSvg.value
          : annotation.body[7]?.value.svg,
      }

      const annotationPattern = drawSvg(
        updatePattern,
        annotation.body[1]?.value,
        // @ts-ignore
        viewer.viewport._contentSize.x,
      )
      patternTransform()

      const coords = extractViewBoxData(annotationPattern)
      // @ts-ignore
      foreignObject.innerHTML = `<span class='annotation-label' xmlns='http://www.w3.org/1999/xhtml'>${annotation.bodies[0].value} ${annotation.bodies[6].value}</span><span class='svg-pattern' xmlns='http://www.w3.org/1999/xhtml' style='width: ${coords?.width}px; height: ${coords?.height}px'>${annotationPattern}</span>`

      if (document.querySelector('.selected')?.classList.contains('edit')) {
        const circles = foreignObject
          ? foreignObject.querySelectorAll('circle')
          : []
        // @ts-ignore
        setEditCircleRadiusSize(viewer.viewport._contentSize.x, coords, circles)
      }

      checkHasPattersBeforeTransform(annotation)

      // @ts-ignore
      changePattern.value = {
        point_links: annotation.body[7]?.value.point_links,
        coordinates: JSON.stringify(points.value),
        svg: newPatternSvg.value ? newPatternSvg.value : annotationPattern,
        name: annotation.body[7]?.value.name,
        id: annotation.body[7]?.value.id,
      }
    }
  }

  const handlePointerUp = (annotation: IAnnotation) => {
    isDragPattern.value = false
    // @ts-ignore
    setTimeout(() => {
      if (anno && typeof anno.changeSelectionTarget === 'function') {
        anno.changeSelectionTarget(annotation.target)
      }
    }, 100)
  }

  anno.formatters = [AnnotationColorFormatter, AnnotationLabelFormatter]

  anno.on('startSelection', () => {
    workspaceStore.setShowDistance(false)
  })

  anno.on('createSelection', async (selection: ISelection) => {
    if (!workspaceStore.selectedClass.id) {
      return
    }

    const classObjects =
      workspaceStore.currentImageFromSegmentedList?.image_classes?.find(
        (item) => item.project_class_id === workspaceStore.selectedClass.id,
      )?.classification_objects
    const sortClassObjects = classObjects?.sort((a, b) =>
      Number(a.id) > Number(b.id) ? 1 : 0,
    )

    if (sortClassObjects) {
      const lastIndex =
        // @ts-ignore
        sortClassObjects[sortClassObjects.length - 1]?.located_objects[0]?.shade
          ?.index

      // @ts-ignore
      lastShadeIndex.value = lastIndex === undefined ? 0 : (lastIndex + 1) % 9
    }

    if (classObjects) {
      lastPosition.value = classObjects.length + 1
    }

    workspaceStore.dropSelectedObjects()
    // @ts-ignore
    selection.body = [
      {
        type: 'TextualBody',
        value: workspaceStore.selectedClass.name,
        format: 'classifying',
      },
      {
        type: 'TextualBody',
        value: workspaceStore?.selectedClass.color,
        format: 'classifying',
      },
      {
        type: 'TextualBody',
        value: workspaceStore.selectedClass.id,
        format: 'classifying',
      },
      {
        type: 'TextualBody',
        purpose: 'displaying',
        value: true,
      },
      {
        type: 'TextualBody',
        purpose: 'locking',
        value: false,
      },
      {
        type: 'TextualBody',
        value: workspaceStore?.selectedClass?.project_class_shades
          ? workspaceStore?.selectedClass?.project_class_shades[
              lastShadeIndex.value
            ]
          : { index: 0, value: workspaceStore?.selectedClass.color },
        format: 'classifying',
      },
      {
        type: 'TextualBody',
        value: lastPosition.value,
        format: 'classifying',
      },
      {
        type: 'TextualBody',
        // @ts-ignore
        // value: pattern ? pattern?.svg : '',
        value: '',
        format: 'classifying',
      },
      {
        type: 'TextualBody',
        // @ts-ignore
        // value: '',
        value: '',
        format: 'classifying',
      },
    ]
    await anno.updateSelected(selection)
    anno.saveSelected()
    anno.setDrawingEnabled(true)
  })

  anno.on('createAnnotation', async (annotation: IAnnotation) => {
    if (!workspaceStore?.selectedClass?.id) {
      return
    }

    if (workspaceStore.selectedTool === Tools.Identificator) {
      if (!workspaceStore.isHasImageClass()) {
        // @ts-ignore
        await workspaceStore.createImageClasses([
          workspaceStore?.selectedClass?.id,
        ])
      }

      const annotationCoords = workspaceStore.convertCoordinatesToCreateRect(
        annotation.target.selector.value,
      )

      const bboxCoords = [
        Math.floor(annotationCoords.xmin),
        Math.floor(annotationCoords.ymin),
        Math.floor(annotationCoords.width),
        Math.floor(annotationCoords.height),
      ]
      const data = {
        coordinates: bboxCoords,
        method: 'identifier',
        id: annotation.id,
      }
      await workspaceStore.segmentObjectCreate(data)
    } else {
      await workspaceStore.currentImageObjects.push(annotation)
      await workspaceStore.createHook([annotation.id as string])
      workspaceStore.createObjectLoader = false
      await workspaceStore.saveChanges()
    }

    if (
      workspaceStore.selectedTool === Tools.Pentagon ||
      workspaceStore.selectedTool === Tools.Identificator ||
      workspaceStore.selectedTool === Tools.Interactor
    ) {
      workspaceStore.selectedTool = Tools.Arrow
    }
  })

  anno.on('updateAnnotation', async (annotation: IAnnotation) => {
    checkHasPattersBeforeTransform(annotation)
    const updatedObjectIndex = workspaceStore.currentImageObjects.findIndex(
      (object) => object.id === annotation.id,
    )

    workspaceStore.currentImageObjects[updatedObjectIndex] = annotation
    workspaceStore.selectedObject = {} as IAnnotation

    if (!isTargetChanged.value) {
      workspaceStore.updateHook([annotation.id])
    }
    isTargetChanged.value = false
    savePatternChange(annotation)
    checkHasPattersBeforeTransform(annotation)
  })

  const selectedObjectIndex = computed(() => {
    return workspaceStore.currentImageObjects.findIndex(
      (object) => object.id === workspaceStore.selectedObject.id,
    )
  })

  const debouncedUpdateHook = useDebounceFn(async (target: any) => {
    isTargetChanging.value = true
    isTargetChanged.value = true

    workspaceStore.selectedObject.target.selector.value = target.selector.value
    workspaceStore.currentImageObjects[
      selectedObjectIndex.value
    ].target.selector.value = target.selector.value
    if (target.selector.type === 'FragmentSelector') {
      startCoords = workspaceStore.convertCoordinatesToCreateRect(
        target.selector.value,
      )
    } else if (target.selector.type === 'SvgSelector') {
      startCoords = workspaceStore.convertCoordinatesToCreatePolygon(
        target.selector.value,
      )
    }
    if (workspaceStore.selectedObjectGroup.length) {
      updateObjectGroup()
    } else {
      workspaceStore.updateHook([workspaceStore.selectedObject.id])
    }
    return
  }, 200)

  anno.on('changeSelectionTarget', async (target: any) => {
    checkHasPattersBeforeTransform(workspaceStore.selectedObject)
    if (
      workspaceStore.selectedObject.id &&
      workspaceStore.selectedObjectGroup.length
    ) {
      if (target.selector.type === 'FragmentSelector') {
        redrawAnnotations(
          workspaceStore.convertCoordinatesToCreateRect(target.selector.value),
        )
      } else if (target.selector.type === 'SvgSelector') {
        redrawAnnotations(
          workspaceStore.convertCoordinatesToCreatePolygon(
            target.selector.value,
          ),
        )
      }
    }

    if (workspaceStore.selectedObject.body[7]?.value) {
      resizePatternBBox(target)
    }
    await debouncedUpdateHook(target)
    checkHasPattersBeforeTransform(workspaceStore.selectedObject)
    setTimeout(async () => {
      if (anno && typeof anno.updateAnnotation === 'function') {
        await anno.updateAnnotation(workspaceStore.selectedObject)
      }
    }, 100)
  })

  const resizePatternBBox = (target: any) => {
    const annotationCoords = workspaceStore.convertCoordinatesToCreateRect(
      target.selector.value,
    )

    const selected = document.querySelector('.selected')

    if (selected) {
      const initAnnotationCoords =
        workspaceStore.convertCoordinatesToCreateRect(
          workspaceStore.selectedObject.target.selector.value,
        )
      const foreignObject = selected.querySelector('foreignObject')
      const svgElement = selected.querySelector('.svg-pattern')

      if (
        annotationCoords.width !== initAnnotationCoords.width ||
        annotationCoords.height !== initAnnotationCoords.height
      ) {
        if (svgElement) {
          const coefficient = returnTransformNumber(
            48,
            // @ts-ignore
            workspaceStore.currentImage.width,
          )

          svgElement.setAttribute(
            'style',
            `width: ${String(
              annotationCoords.width - coefficient,
            )}px; height: ${String(annotationCoords.height - coefficient)}px`,
          )

          const svgView = svgElement.querySelector('svg')

          if (svgView) {
            const viewBox = `0 0 ${annotationCoords.width - coefficient} ${
              annotationCoords.height - coefficient
            }`

            svgView.setAttribute('viewBox', String(viewBox))
            // @ts-ignore
            newPatternSvg.value = svgElement.innerHTML

            // @ts-ignore
            const parentSvgG = svgElement.parentNode.parentNode
            // @ts-ignore
            if (svgElement && parentSvgG.tagName == 'g') {
              // @ts-ignore
              const gTransform = parentSvgG.getAttribute('transform')
              const scaleValue = gTransform.match(/scale\((.*?)\)/)[1]
              const reverseScale = 1 / parseFloat(scaleValue)
              // @ts-ignore
              const styleValue = svgElement
                .getAttribute('style')
                .replace(/transform: scale\(.+?\);/g, '')

              svgElement.setAttribute(
                'style',
                `${styleValue}; transform: scale(${reverseScale})`,
              )

              updateCoordinates(
                workspaceStore.selectedObject,
                foreignObject,
                annotationCoords.width,
                annotationCoords.height,
              )
            }
          }
        }
      }
    }
  }

  const updateCoordinates = (
    annotation: any,
    foreignObject: any,
    newWidth: number,
    newHeight: number,
  ) => {
    const coords = extractViewBoxData(annotation.body[7]?.value.svg)
    // Получаем все элементы, которые имеют атрибуты с координатами (например, circles, rects и т.д.)
    points.value =
      typeof annotation?.body[7]?.value.coordinates === 'string'
        ? JSON?.parse(annotation.body[7]?.value.coordinates)
        : annotation?.body[7]?.value.coordinates
    lines.value =
      typeof annotation?.body[7]?.value.point_links === 'string'
        ? JSON?.parse(annotation?.body[7]?.value.point_links)
        : annotation?.body[7]?.value.point_links

    const coefficient =
      // @ts-ignore
      48 * setSizeCoefficient(workspaceStore.currentImage.width)

    points.value = points.value.map((point) => {
      const newX = Math.min(
        newWidth - coefficient,
        // @ts-ignore
        Math.max(0, ((newWidth - coefficient) * point.x) / coords?.width),
      )

      const newY = Math.min(
        newHeight - coefficient,
        // @ts-ignore
        Math.max(0, ((newHeight - coefficient) * point.y) / coords?.height),
      )

      return {
        x: newX,
        y: newY,
        id: point.id,
        name: point.name,
      }
    })

    const updatePattern = {
      ...annotation.body[7]?.value,
      coordinates: JSON.stringify(points.value),
      svg: newPatternSvg.value
        ? newPatternSvg.value
        : annotation.body[7]?.value.svg,
    }

    const annotationPattern = drawSvg(
      updatePattern,
      annotation.body[1]?.value,
      // @ts-ignore
      viewer.viewport._contentSize.x,
    )
    patternTransform()

    // @ts-ignore
    changePattern.value = {
      point_links: annotation.body[7]?.value.point_links,
      coordinates: JSON.stringify(points.value),
      svg: newPatternSvg.value ? newPatternSvg.value : annotationPattern,
      name: annotation.body[7]?.value.name,
      id: annotation.body[7]?.value.id,
    }

    const newCoords = extractViewBoxData(annotationPattern)
    patternTransform()
    // @ts-ignore
    foreignObject.innerHTML = `<span class='annotation-label' xmlns='http://www.w3.org/1999/xhtml'>${annotation.body[0].value} ${annotation.body[6].value}</span><span class='svg-pattern' xmlns='http://www.w3.org/1999/xhtml' style='width: ${newCoords?.width}px; height: ${newCoords?.height}px'>${annotationPattern}</span>`
    if (document.querySelector('.selected')?.classList.contains('edit')) {
      const circles = foreignObject
        ? foreignObject.querySelectorAll('circle')
        : []
      // @ts-ignore
      setEditCircleRadiusSize(
        // @ts-ignore
        viewer.viewport._contentSize.x,
        newCoords,
        circles,
      )
    }

    checkHasPattersBeforeTransform(annotation)
  }

  eventBus.$on('savePattern', () => {
    // @ts-ignore
    if (workspaceStore.selectedObject) {
      // @ts-ignore
      if (
        workspaceStore.selectedObject.body &&
        // @ts-ignore
        workspaceStore.selectedObject.body[7].value
      ) {
        checkHasPattersBeforeTransform(workspaceStore.selectedObject)
        savePatternChange(workspaceStore.selectedObject)
      }
    }
  })

  eventBus.$on('deselectPattern', () => {
    // @ts-ignore
    if (workspaceStore.selectedObject) {
      // @ts-ignore
      if (
        workspaceStore.selectedObject.body &&
        // @ts-ignore
        workspaceStore.selectedObject.body[7].value
      ) {
        checkHasPattersBeforeTransform(workspaceStore.selectedObject)
        if (changePattern.value || newPatternSvg.value) {
          savePatternChangeToAnnotation(workspaceStore.selectedObject)
          workspaceStore.updateHook([workspaceStore.selectedObject.id])
          deselectAnnotations()
          anno.cancelSelected(workspaceStore.selectedObject)
          changePattern.value = null
          newPatternSvg.value = null
        }
      }
    }
  })

  eventBus.$on('resetPatternChange', () => {
    changePattern.value = null
    newPatternSvg.value = null
  })

  eventBus.$on('cancelSelectPatternChange', () => {
    deselectAnnotations()
    anno.cancelSelected(workspaceStore.selectedObject)
    changePattern.value = null
    newPatternSvg.value = null
    patternTransform()
  })

  const savePatternChange = (annotation: IAnnotation) => {
    if (changePattern.value || newPatternSvg.value) {
      workspaceStore.updateHook([annotation.id])
      savePatternChangeToAnnotation(annotation)
      // @ts-ignore
      workspaceStore.saveChanges()
      changePattern.value = null
      newPatternSvg.value = null
    }
  }

  const savePatternChangeToAnnotation = (annotation: IAnnotation) => {
    if (changePattern.value || newPatternSvg.value) {
      annotation.body[7] = {
        type: 'TextualBody',
        // @ts-ignore
        value: {
          // @ts-ignore
          coordinates: changePattern.value
            ? changePattern.value.coordinates
            : // @ts-ignore
              annotation.body[7].value.coordinates,
          // @ts-ignore
          point_links: annotation.body[7].value.point_links,
          // @ts-ignore
          svg: newPatternSvg.value
            ? newPatternSvg.value
            : // @ts-ignore
              annotation.body[7].value.svg,
          name: annotation.body[7]?.value.name,
          id: annotation.body[7]?.value.id,
        },
        format: 'classifying',
      }
    }
  }

  anno.on('clickAnnotation', (annotation: IAnnotation) => {
    if (workspaceStore.selectedObject.id === annotation.id) {
      // @ts-ignore
      if (annotation.body[8]?.value !== annotation.target.selector.value) {
        workspaceStore.updateHook([annotation.id])
        // @ts-ignore
        savePatternChange(workspaceStore.selectedObject)
        workspaceStore.saveChanges()
        setTimeout(() => patternTransform(), 15)
      }
    }

    if (!isNewSelected) {
      checkHasPattersBeforeTransform(annotation)
      savePatternChange(workspaceStore.selectedObject)
    }
    // Во всех случаях должен быть зажат ctrl и присутствовать главный объект выделения
    if (
      isCtrlPressed.value &&
      !annotation.body[4].value &&
      Object.keys(workspaceStore.selectedObject).length !== 0
    ) {
      selectMultipleAnnotation(annotation)
    }
    setTimeout(() => {
      if (anno && typeof anno.changeSelectionTarget === 'function') {
        anno.changeSelectionTarget(annotation.target)
      }
      patternTransform()
    }, 10)
  })

  anno.on('selectAnnotation', (annotation: IAnnotation) => {
    workspaceStore.selectedObject = annotation
    if (isCtrlPressed.value) {
      anno.disableSelect = true
    }
    workspaceStore.annotationsFlags.classChanging = false
    workspaceStore.annotationsFlags.instanceClassChanging = false
    workspaceStore.annotationsFlags.attributesChanging = false
    isNewSelected = true

    setTimeout(() => {
      checkHasPattersBeforeTransform(annotation)
      const selected = document.querySelector('.selected')
      if (selected) {
        selected.addEventListener('dblclick', () => {
          const svg = changePattern.value
            ? changePattern.value.svg
            : // @ts-ignore
              annotation?.body[7].value.svg
          // @ts-ignore
          setEditPattern(viewer?.viewport?._contentSize?.x as string, svg)
        })
      }
    }, 0)
  })

  anno.on('cancelSelected', async (annotation: IAnnotation) => {
    workspaceStore.selectedPattern = null
    if (isNewSelected) {
      isNewSelected = false
      checkHasPattersBeforeTransform(annotation)
      savePatternChange(annotation)
      return
    }
    setTimeout(() => patternTransform(), 10)
    savePatternChange(annotation)
  })

  const checkHasPattersBeforeTransform = (annotation: IAnnotation) => {
    // @ts-ignore
    if (annotation.body[7].value) {
      patternTransform()
    }
  }

  anno.on('mouseEnterAnnotation', (annotation: IAnnotation) => {
    if (isMultipleSelection) return
    anno.disableSelect = annotation.body[4].value
  })

  const setReceivedAnnotations = async (annotations: IAnnotation[]) => {
    isAnnotationsRendered.value = false

    annotations.forEach((annotation) => {
      const filteredObjectsByClass = annotations.filter(
        (object) => object.body[2].value === annotation.body[2].value,
      )

      const correspondedObject = filteredObjectsByClass.find(
        (object) => object.id === annotation.id,
      )

      if (!correspondedObject) {
        return
      }

      annotation.body[6] = {
        type: 'TextualBody',
        value: filteredObjectsByClass.indexOf(correspondedObject) + 1,
        format: 'classifying',
      }

      // @ts-ignore
      annotation.body[7] = {
        type: 'TextualBody',
        // @ts-ignore
        value: annotation.body[7].value ?? '',
        format: 'classifying',
      }
    })

    await anno.setAnnotations(annotations)
    initContextMenu()
    isAnnotationsRendered.value = true
    patternTransform()
  }

  const initContextMenu = () => {
    const annotationLayer = Array.from(
      document.getElementsByClassName('openseadragon-canvas'),
    ) as HTMLElement[]

    const annotations = Array.from(annotationLayer[0].children) as HTMLElement[]
    contextMenu.removeListeners()
    contextMenu.initListeners(annotations)
  }

  if (
    workspaceStore.selectedTool === Tools.BoundingBox ||
    workspaceStore.selectedTool === Tools.Identificator ||
    workspaceStore?.selectedTool === Tools.Pentagon
  ) {
    anno.setDrawingEnabled(true)
  } else {
    anno.setDrawingEnabled(false)
  }

  //multiselect
  const isCtrlPressed: Ref<boolean> = ref(false)
  let isMultipleSelection = false
  let startCoords:
    | IAnnotationCoordinatesRect
    | IAnnotationCoordinatesPolygon[] = {} as
    | IAnnotationCoordinatesRect
    | IAnnotationCoordinatesPolygon[]

  const selectMultipleAnnotation = (annotation: IAnnotation) => {
    if (checkAnnotationsNotDuplicate(annotation)) {
      workspaceStore.selectedObjectGroup.push(annotation)
      isNewSelected = true
    } else {
      const duplicateIndex = workspaceStore.selectedObjectGroup.findIndex(
        (storeObject) => storeObject.id === annotation.id,
      )
      workspaceStore.selectedObjectGroup.splice(duplicateIndex, 1)
    }
  }

  const checkAnnotationsNotDuplicate = (annotation: IAnnotation) => {
    return !workspaceStore.selectedObjectGroup.some(
      (storedAnnotation) => storedAnnotation.id === annotation.id,
    )
  }

  const addSelectedState = (el: HTMLElement, value: boolean) => {
    if (value) {
      el.classList.add('grouped')
    } else {
      el.classList.remove('grouped')
    }
  }

  const selectedElements = computed(() => {
    return getAllElements().filter((el) =>
      workspaceStore.selectedObjectGroup.some(
        (storedObject) => String(storedObject.id) === el.dataset['id'],
      ),
    )
  })

  const getAllElements = () => {
    return Array.from(
      document.getElementsByClassName('a9s-annotation'),
    ) as HTMLElement[]
  }

  // Изменяем размеры группы элементов
  const redrawAnnotations = (
    targetCoords: IAnnotationCoordinatesRect | IAnnotationCoordinatesPolygon[],
  ) => {
    // Принимаем координаты главного объекта
    selectedElements.value.forEach((selected) => {
      const el = selected
      const rect = el.querySelector('rect') // У нас есть в группе квадрат
      const polygon = el.querySelector('polygon') // У нас есть в группе полигон

      // Элементы, из которых состоит объект
      const inner = el.getElementsByClassName('a9s-inner')[0]
      const outer = el.getElementsByClassName('a9s-outer')[0]
      const label = el.getElementsByClassName('a9s-formatter-el')[0]

      // Поиск перетаскиваемого элемента
      const annotationData = workspaceStore.currentImageObjects.find(
        (annotation) => el.dataset['id'] === String(annotation.id),
      )

      if (!annotationData) return

      // Разница между координатами "перетаскивания" и начальными
      let deltaX: number
      let deltaY: number

      if ('width' in targetCoords && 'width' in startCoords) {
        deltaX = targetCoords.xmin - startCoords.xmin
        deltaY = targetCoords.ymin - startCoords.ymin
      } else {
        deltaX = targetCoords[0].x - startCoords[0].x
        deltaY = targetCoords[0].y - startCoords[0].y
      }

      // Наш перетаскиваемый элемент - квадрат
      if (rect) {
        // Преобразуем строку с координатами и параметрами в объект
        const annotationCoords = workspaceStore.convertCoordinatesToCreateRect(
          annotationData.target.selector.value,
        )

        // У квадрата помимо начальных координат, есть длина и ширина
        let deltaW
        let deltaH

        if ('width' in startCoords && 'width' in targetCoords) {
          deltaW = targetCoords.width - startCoords.width
          deltaH = targetCoords.height - startCoords.height

          annotationCoords.width += deltaW
          annotationCoords.height += deltaH
        }

        annotationCoords.xmin += deltaX
        annotationCoords.ymin += deltaY

        // Перемещение на одинаковые дельты
        const attributes = [
          { name: 'x', value: annotationCoords.xmin },
          { name: 'y', value: annotationCoords.ymin },
          { name: 'width', value: annotationCoords.width },
          { name: 'height', value: annotationCoords.height },
        ]

        const elements = [inner, outer, label]
        elements.forEach((element) => {
          attributes.forEach(({ name, value }) => {
            element.setAttribute(name, String(value))
          })
        })

        checkHasPattersBeforeTransform(workspaceStore.selectedObject)
      }

      // Наш перетаскиваемый элемент - полигон
      else if (polygon) {
        // Преобразуем строку с координатами и параметрами в объект
        const annotationCoords =
          workspaceStore.convertCoordinatesToCreatePolygon(
            annotationData.target.selector.value,
          )
        // Вычисляем новые координаты для перемещения на основе дельты
        const newCoords: IAnnotationCoordinatesPolygon[] = annotationCoords.map(
          (point) => {
            return {
              x: point.x + deltaX,
              y: point.y + deltaY,
            }
          },
        )
        // Джоиним координаты в строку, как было раньше
        const stringCoordData = newCoords
          .map((coord) => `${coord.x},${coord.y}`)
          .join(' ')

        // Записываем их обратно в элементы группы
        inner.setAttribute('points', stringCoordData)
        outer.setAttribute('points', stringCoordData)
        label.setAttribute('points', stringCoordData)
      }
    })
  }

  // Чтобы сохранять размеры, когда изменили их у группы элементов
  const updateObjectGroup = () => {
    selectedElements.value.forEach((el) => {
      const rect = el.querySelector('rect') // У нас есть в группе квадрат
      const polygon = el.querySelector('polygon') // У нас есть в группе полигон

      const inner = el.getElementsByClassName('a9s-inner')[0]

      const annotationData = workspaceStore.currentImageObjects.find(
        (annotation) => el.dataset['id'] === String(annotation.id),
      )
      const groupAnnotationData = workspaceStore.selectedObjectGroup.find(
        (annotation) => el.dataset['id'] === String(annotation.id),
      )

      if (!annotationData || !groupAnnotationData) return

      if (rect) {
        const coords: IAnnotationCoordinatesRect = {
          xmin: Number(inner.getAttribute('x')),
          ymin: Number(inner.getAttribute('y')),
          width: Number(inner.getAttribute('width')),
          height: Number(inner.getAttribute('height')),
        }

        annotationData.target.selector.value =
          workspaceStore.convertRectCoordinatesToAnnotationFormat(coords)
        groupAnnotationData.target.selector.value =
          workspaceStore.convertRectCoordinatesToAnnotationFormat(coords)
        savePatternChangeToAnnotation(workspaceStore.selectedObject)
      } else if (polygon) {
        const stringData = inner.getAttribute('points')

        annotationData.target.selector.value =
          workspaceStore.convertPolygonCoordinatesToAnnotationFormat(stringData)
        groupAnnotationData.target.selector.value =
          workspaceStore.convertPolygonCoordinatesToAnnotationFormat(stringData)
      }
    })

    const groupObjectIds = workspaceStore.selectedObjectGroup.map(
      (object) => object.id,
    )

    workspaceStore.updateHook([
      workspaceStore.selectedObject.id,
      ...groupObjectIds,
    ])
  }
  const keyEventHandler = (e: KeyboardEvent, value: boolean) => {
    if (e.key === 'Control') {
      isCtrlPressed.value = value
      isMultipleSelection = value
      anno.disableSelect = value
    }
  }

  document.addEventListener('keydown', (e: KeyboardEvent) =>
    keyEventHandler(e, true),
  )
  document.addEventListener('keyup', (e: KeyboardEvent) =>
    keyEventHandler(e, false),
  )

  onUnmounted(() => {
    document.removeEventListener('keydown', (e: KeyboardEvent) =>
      keyEventHandler(e, true),
    )
    document.removeEventListener('keyup', (e: KeyboardEvent) =>
      keyEventHandler(e, false),
    )
  })

  watch(isCtrlPressed, (value) => {
    // Если зажат ctrl и нет главного объекта выделения, то я должен его выделить
    if (value && Object.keys(workspaceStore.selectedObject).length === 0) {
      anno.disableSelect = false
    }
  })

  watch(route, () => {
    eventBus.$emit('savePattern')
  })

  watchEffect(() => {
    if (
      workspaceStore.selectedTool === Tools.Pentagon ||
      workspaceStore.selectedTool === Tools.BoundingBox ||
      workspaceStore.selectedTool === Tools.Identificator
    ) {
      anno.setDrawingEnabled(true)
    } else {
      anno.setDrawingEnabled(false)
    }
  })

  watchEffect(() => {
    // по другому watchEffect почему-то не хочет отслеживать изменения
    const objects = workspaceStore.currentImageObjects
    if (
      isTargetChanging.value &&
      !(
        workspaceStore.annotationsFlags.copied ||
        workspaceStore.annotationsFlags.deleted ||
        workspaceStore.annotationsFlags.classChanging ||
        workspaceStore.annotationsFlags.instanceClassChanging ||
        workspaceStore.annotationsFlags.attributesChanging ||
        workspaceStore.annotationsFlags.visibilityChanging
      )
    ) {
      isTargetChanging.value = false
      return
    }

    workspaceStore.annotationsFlags.copied = false
    workspaceStore.annotationsFlags.deleted = false
    workspaceStore.annotationsFlags.visibilityChanging = false

    const visibleObject = objects.filter((object) => object.body[3].value)
    setReceivedAnnotations(visibleObject)

    workspaceStore.imageList.forEach((item) => {
      if (item.id === workspaceStore.currentImage.id) {
        item.markup_status = workspaceStore.currentImage.markup_status
      }
    })
    workspaceStore.filteredImageList.forEach((item) => {
      if (item.id === workspaceStore.currentImage.id) {
        item.markup_status = workspaceStore.currentImage.markup_status
      }
    })
  })

  // Переключаем инструменты в зависимости от нашего выбора
  watchEffect(() => {
    if (workspaceStore.selectedTool === Tools.Pentagon) {
      anno.setDrawingTool('polygon')
    } else if (
      workspaceStore.selectedTool === Tools.BoundingBox ||
      workspaceStore.selectedTool === Tools.Identificator
    ) {
      anno.setDrawingTool('rect')
    }
  })

  watch(
    () => workspaceStore.selectedObject,
    () => {
      if (
        workspaceStore.selectedObjectGroup.length &&
        !workspaceStore.annotationsFlags.selectingAll
      ) {
        updateObjectGroup()
        selectedElements.value.forEach((annotation) => {
          addSelectedState(annotation, false)
        })
        workspaceStore.selectedObjectGroup = []
        setReceivedAnnotations(workspaceStore.currentImageObjects)
      }
      workspaceStore.annotationsFlags.selectingAll = false

      isNewSelected = true

      anno.selectAnnotation(workspaceStore.selectedObject)
      if (workspaceStore.selectedObject.id) {
        if (
          workspaceStore.selectedObject.target.selector.type ===
          'FragmentSelector'
        ) {
          startCoords = workspaceStore.convertCoordinatesToCreateRect(
            workspaceStore.selectedObject.target.selector.value,
          )
        } else if (
          workspaceStore.selectedObject.target.selector.type === 'SvgSelector'
        ) {
          startCoords = workspaceStore.convertCoordinatesToCreatePolygon(
            workspaceStore.selectedObject.target.selector.value,
          )
        }
      }
    },
  )

  watch(
    () => [
      workspaceStore.isImageRendered,
      workspaceStore.annotationsFlags.readyForAnnotationSetting,
      isAnnotationsRendered.value,
    ],
    () => {
      if (
        workspaceStore.isImageRendered &&
        workspaceStore.annotationsFlags.readyForAnnotationSetting &&
        isAnnotationsRendered.value
      )
        anno.setVisible(true)
    },
  )

  watch(
    () => workspaceStore.selectedObjectGroup.length,
    () => {
      if (workspaceStore.selectedObjectGroup.length) {
        isMultipleSelection = true
      }

      workspaceStore.currentImageObjects.forEach((object) => {
        const el = getAllElements().find(
          (el) => el.dataset['id'] === String(object.id),
        )

        if (!el) return

        if (
          workspaceStore.selectedObjectGroup.some(
            (groupObject) => groupObject.id === object.id,
          )
        ) {
          addSelectedState(el, true)
        } else {
          addSelectedState(el, false)
        }
      })
    },
  )

  watch(
    () => workspaceStore.annotationsFlags.clearSelectedAnnotations,
    () => {
      workspaceStore.annotationsFlags.clearSelectedAnnotations = false
      deselectAnnotations()
    },
  )
  return anno
}
