import { EgalActionConstructor } from '@/helpers/EgalActionConstructor'
import { APIModels, Microservices } from '@/types/enums'
import { logout } from '@/helpers/logout'

class CommentaryAPIModel {
  async createComment(commentaryData: {
    image_id: number
    text: string
  }): Promise<any> {
    return EgalActionConstructor.create(
      Microservices.Core,
      APIModels.Commentary,
      commentaryData,
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async deleteComment(commentId: number): Promise<any> {
    return EgalActionConstructor.delete(
      Microservices.Core,
      APIModels.Commentary,
      { id: commentId },
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async updateComment(commentId: number, commentText: string): Promise<any> {
    return EgalActionConstructor.update(
      Microservices.Core,
      APIModels.Commentary,
      { id: commentId, text: commentText },
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }
}

export const CommentaryApi = new CommentaryAPIModel()
