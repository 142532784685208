import PatternBase from '@/components/Pattern/RemovePatternInOrganisation/PatternBase.vue'
import PatternsInProjects from '@/components/Pattern/RemovePatternInOrganisation/PatternsInProjects.vue'
import RemovePatternInOrganizationTasks from '@/components/Pattern/RemovePatternInOrganisation/PatternInProjectsAndTasks.vue'
import RemovePatternInProjectTasks from '@/components/Pattern/RemovePatternInProject/PatternInProjectsAndTasks.vue'
import { Component, markRaw, ref, Ref, watchEffect } from 'vue'
import { usePatternStore } from '@/store/patternStore'

type IDynamicComponent = Ref<Component | null>

export default function useCurrentStageComponent(): IDynamicComponent {
  const patternStore = usePatternStore()

  const dynamicComponent: IDynamicComponent = ref(null)
  const refString: Ref<string> = ref(patternStore.modalContentCurrentStage)

  watchEffect(() => {
    refString.value = patternStore.modalContentCurrentStage

    switch (refString.value) {
      case 'patternBase':
        dynamicComponent.value = markRaw(PatternBase)
        break
      case 'patternsInProjects':
        dynamicComponent.value = markRaw(PatternsInProjects)
        break
      case 'patternInProjectsAndTasks':
        dynamicComponent.value = markRaw(RemovePatternInOrganizationTasks)
        break
      case 'patternInProjectTasks':
        dynamicComponent.value = markRaw(RemovePatternInProjectTasks)
        break
      default:
        dynamicComponent.value = null
        break
    }
  })

  return dynamicComponent
}
