import { ref, Ref } from 'vue'

interface RGBColor {
  r: number
  g: number
  b: number
}

function hexToRgb(hex: string): RGBColor {
  // Убираем символ # из HEX и конвертируем в RGB
  const bigint = parseInt(hex?.slice(1), 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255
  return { r, g, b }
}

export function generateShades(hexColor: string): Ref<string[]> {
  const shades: Ref<string[]> = ref([])

  let currentColor: string

  switch (hexColor) {
    case '#FF0000':
    case '#ff0000':
      currentColor = '#F50A0A'
      break
    case '#FFFFFF':
    case '#ffffff':
      currentColor = '#F5F5F5'
      break
    default:
      currentColor = hexColor
      break
  }

  const { r, g, b } = hexToRgb(currentColor)
  const maxChannelValue = Math.max(r, g, b)

  for (let i = 0; i <= 9; i++) {
    const newR = Math.floor(r + (i * (255 - maxChannelValue)) / 9)
    const newG = Math.floor(g + (i * (255 - maxChannelValue)) / 9)
    const newB = Math.floor(b + (i * (255 - maxChannelValue)) / 9)

    // Преобразуем RGB обратно в HEX
    const shadeHex = `#${((1 << 24) | (newR << 16) | (newG << 8) | newB)
      .toString(16)
      .slice(1)}`

    shades.value.push(shadeHex)
  }

  switch (hexColor) {
    case '#FF0000':
    case '#ff0000':
      shades.value.unshift('#FF0000')
      break
    case '#FFFFFF':
    case '#ffffff':
      shades.value.unshift('#FFFFFF')
      break
  }

  shades.value = shades.value.slice(0, 9)

  return shades
}

export function getContrastColor(
  background: string,
  colorContrastDark: null | string = null,
  colorContrastLight: null | string = null,
): string {
  // Конвертируем HEX в RGB
  const { r, g, b } = hexToRgb(background)

  // Вычисляем яркость фона с использованием формулы YIQ
  const brightness = (r * 299 + g * 587 + b * 114) / 1000

  // Выбираем контрастный цвет текста в зависимости от яркости фона
  return brightness > 128
    ? colorContrastDark
      ? colorContrastDark
      : '#000000'
    : colorContrastLight
    ? colorContrastLight
    : '#ffffff'
}
export function getContrastColorClass(
  background: string,
  colorContrastDark: null | string = null,
  colorContrastLight: null | string = null,
): string {
  // Конвертируем HEX в RGB
  const { r, g, b } = hexToRgb(background)

  // Вычисляем яркость фона с использованием формулы YIQ
  const brightness = (r * 299 + g * 587 + b * 114) / 1000

  // Выбираем контрастный цвет текста в зависимости от яркости фона
  return brightness > 128
    ? colorContrastDark
      ? colorContrastDark
      : 'dark'
    : colorContrastLight
    ? colorContrastLight
    : 'light'
}
