import { defineStore } from 'pinia'
import { IHeaderTab } from '@/types/interfaces'
import { UserAPI } from '@/api/UserAPI'

interface GlobalStoreState {
  headerLinks: IHeaderTab[]
  isDatasetListModalShowing: boolean
  isProfileModalShowing: boolean
  isHelpModalShowing: boolean
  captchaSiteKey: string
}

export const useGlobalStore = defineStore('globalStore', {
  state: (): GlobalStoreState => {
    return {
      headerLinks: [
        {
          name: 'Организации',
          to: '/',
          linkedRouteNames: ['Create organization', 'Organization'],
        },
        {
          name: 'Проекты',
          to: '/projects',
          linkedRouteNames: ['Create project', 'Project'],
        },
        {
          name: 'Задачи',
          to: '/tasks',
          linkedRouteNames: ['Create task'],
        },
        {
          name: 'Ваши тарифы',
          to: '/statistics-page',
          linkedRouteNames: ['Statistics Page'],
        },
        {
          name: 'Услуги',
          to: '/service-plans',
          linkedRouteNames: ['Service Page'],
        },
      ],
      isDatasetListModalShowing: false,
      isProfileModalShowing: false,
      isHelpModalShowing: false,
      captchaSiteKey: 'sitekey  ',
    }
  },

  actions: {
    async getCaptcha(): Promise<void> {
      return UserAPI.getCaptcha().then((response) => {
        this.captchaSiteKey = response
      })
    },

    async validateCaptcha(token: string): Promise<void> {
      return UserAPI.validateCaptcha(token)
        .then((response) => {
          return response
        })
        .catch((error) => {
          throw error
        })
    },

    formAdminLinks() {
      this.headerLinks = [
        {
          name: 'Пользователи',
          to: '/admin-users',
          linkedRouteNames: [],
        },
        {
          name: 'Организации',
          to: '/admin-organizations',
          linkedRouteNames: [],
        },
        {
          name: 'Проекты',
          to: '/admin-projects',
          linkedRouteNames: [],
        },
        {
          name: 'Задачи',
          to: '/admin-tasks',
          linkedRouteNames: [],
        },
        {
          name: 'Услуги',
          to: '/service-plans',
          linkedRouteNames: ['Service Page'],
        },
      ]
    },
  },
})
