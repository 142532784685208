import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useUserStore } from '@/store/userStore'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/SignIn.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/SignUp.vue'),
  },
  {
    path: '/restore-password',
    name: 'Restore password',
    component: () => import('../views/RestorePassword.vue'),
  },
  {
    path: '/',
    name: 'Organizations',
    component: () => import('../views/Organizations.vue'),
  },
  {
    path: '/create-organization',
    name: 'Create organization',
    component: () => import('../views/CreateOrganization.vue'),
  },
  {
    path: '/organization/:id',
    name: 'Organization',
    component: () => import('../views/OrganizationView.vue'),
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('../views/ProjectsView.vue'),
  },
  {
    path: '/create-project',
    name: 'Create project',
    component: () => import('../views/CreateProject.vue'),
  },
  {
    path: '/project/:id',
    name: 'Project',
    component: () => import('../views/ProjectView.vue'),
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: () => import('../views/TasksView.vue'),
  },
  {
    path: '/create-task',
    name: 'Create task',
    component: () => import('../views/CreateTask.vue'),
  },
  {
    path: '/markup-workspace/:id',
    name: 'Workspace',
    component: () => import('../views/MarkupWorkspace.vue'),
  },
  {
    path: '/admin-users',
    name: 'Admin users',
    component: () => import('../views/AdminUsers.vue'),
  },
  {
    path: '/admin-organizations',
    name: 'Admin-organizations',
    component: () => import('../views/AdminOrganizations.vue'),
  },
  {
    path: '/admin-projects',
    name: 'Admin projects',
    component: () => import('../views/AdminProjects.vue'),
  },
  {
    path: '/admin-tasks',
    name: 'Admin tasks',
    component: () => import('../views/AdminTasks.vue'),
  },
  {
    path: '/subscription-plans',
    name: 'Subscription plans',
    component: () => import('../views/SubscriptionPlans.vue'),
  },
  {
    path: '/service-plans',
    name: 'Service plans',
    component: () => import('../views/ServicePlans.vue'),
  },
  {
    path: '/services-page',
    name: 'Services Page',
    component: () => import('../views/SubscriptionPlans.vue'),
  },
  {
    path: '/statistics-page',
    name: 'Statistics',
    component: () => import('../views/StatisticsPage.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to) => {
  const userStore = useUserStore()

  if (
    !to.path.includes('auth') &&
    !userStore.checkAuth() &&
    to.name !== 'Login' &&
    to.name !== 'Register' &&
    to.name !== 'Restore password'
  ) {
    return { name: 'Login' }
  }
})

export default router
