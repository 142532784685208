import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import DefaultLayout from './layouts/DefaultLayout.vue'
// @ts-ignore
import egalWidgets from '@egalteam/widget-library'
import '@egalteam/widget-library/dist/style.css'
import VueSnip from 'vue-snip'
import { createPinia } from 'pinia'
import VueCookies from 'vue3-cookies'
// @ts-ignore
import ColorInput from 'vue-color-input'
import './registerServiceWorker'

const pinia = createPinia()
const app = createApp(App)

app
  .component('default-layout', DefaultLayout)
  .use(router)
  .use(egalWidgets)
  .use(VueSnip)
  .use(pinia)
  .use(VueCookies)
  .use(ColorInput)
  .mount('#app')
