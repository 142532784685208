import { computed, ref, watch } from 'vue'
import { useWorkspaceStore } from '@/store/workspaceStore'
import { useDebounceFn } from '@vueuse/core'
import { useWorkspaceMarkup } from '@/composables/useWorkspaceMarkup'
import { IImageClass } from '@/types/interfaces'
import { MarkupStatuses, MarkupTypes, Tools } from '@/types/enums'
import { MAX_COPY_OBJECTS } from '@/helpers/globalConst'
import { injectEventBus } from '@/helpers/EventBus'

export const useWorkspaceImagePlayer = (withWatcher = false) => {
  const IMAGE_LIST_LENGTH_OFFSET = 20

  const workspaceStore = useWorkspaceStore()
  const eventBus = injectEventBus()
  const { updateMarkup } = useWorkspaceMarkup()
  const processingImagesAmount = ref(0)

  const saveObjects = () => {
    return workspaceStore.saveChanges(false)
  }

  const copyObjectsFromPrev = async (prevIndex: number, currIndex: number) => {
    const prevSegmentIndex = Math.floor(
      prevIndex / workspaceStore.segmentedImageListOptions.perPage,
    )
    const prevImageInSegmentIndex =
      prevIndex % workspaceStore.segmentedImageListOptions.perPage

    const currSegmentIndex = Math.floor(
      currIndex / workspaceStore.segmentedImageListOptions.perPage,
    )
    const currImageInSegmentIndex =
      currIndex % workspaceStore.segmentedImageListOptions.perPage

    // Получение массива уникальных объектов на основании ключа (в данном случае координат)
    // Note: берёт последний дублирующийся объект, поэтому тут важен порядок, чтобы брать актуальные данные, поэтому приоритет будет такой:
    // 1. workspaceStore.currentImageObjects,
    // 2. workspaceStore.autoCopiedObjects,
    // 3. workspaceStore.getObjectsFromImageList(prevIndex),
    // TODO: Вынести в миксин/composable (учесть вложенные свойства)
    workspaceStore.autoCopiedObjects = [
      ...new Map(
        [
          ...workspaceStore.getObjectsFromImageList(
            prevSegmentIndex,
            prevImageInSegmentIndex,
          ),
          ...workspaceStore.autoCopiedObjects,
          ...workspaceStore.currentImageObjects,
        ].map((object) => [object.target.selector.value, object]),
      ).values(),
    ]

    // Доп. фильтрация для обновлённых объектов
    // Обновлённые объекты не учитываются в предыдущей операции, из-за чего объект дублируется
    workspaceStore.autoCopiedObjects = [
      ...new Map(
        workspaceStore.autoCopiedObjects.map((object) => [object.id, object]),
      ).values(),
    ]

    workspaceStore.autoCopiedObjects = workspaceStore.autoCopiedObjects.filter(
      (copiedObject) =>
        !workspaceStore
          .getObjectsFromImageList(currSegmentIndex, currImageInSegmentIndex)
          .some(
            (nextImageObject) =>
              copiedObject.target.selector.value ===
              nextImageObject.target.selector.value,
          ) &&
        !workspaceStore.objectActionsHistory.delete.some(
          (deletedObjectId) => deletedObjectId === copiedObject.id,
        ),
    )

    const formattedObjects = workspaceStore.autoCopiedObjects.map(
      (prevObject) => {
        // @ts-ignore
        const getFoundLocatedObject = workspaceStore &&
          workspaceStore?.segmentedImageList[0] &&
          workspaceStore?.segmentedImageList[0][prevIndex] &&
          workspaceStore.segmentedImageList[0][prevIndex].image_classes &&
          // @ts-ignore
          workspaceStore.segmentedImageList[0][prevIndex].image_classes
            .map((imageClass: IImageClass) => imageClass.classification_objects)
            .flat()
            .find((item) =>
              item.located_objects.find((locatedObj) => locatedObj.id === prevObject.id),
            )

        return {
          ...prevObject,
          id: `${prevObject.id}-copy-${Date.now()}`,
          attributes:
            //@ts-ignore
            getFoundLocatedObject ? getFoundLocatedObject?.located_objects[0].located_object_attributes.map(item => {
              return {
                project_class_attribute_id: item.project_class_attribute_id,
                value: item.value
              }
            }) : undefined,
        }
      },
    )

    updateObjectsAmount(
      currIndex,
      workspaceStore.getObjectsFromImageList(
        currSegmentIndex,
        currImageInSegmentIndex,
      ).length + formattedObjects.length,
    )

    debouncedClearAutoCopiedObjects()

    workspaceStore.isCopyOperationsComplete = false
    workspaceStore.isMaxCopyObjects = false
    processingImagesAmount.value++

    await workspaceStore.saveAutocopiedChanges(
      formattedObjects,
      workspaceStore.segmentedImageList[currSegmentIndex][
        currImageInSegmentIndex
      ].image_classes as IImageClass[],
      currImageInSegmentIndex,
    )

    await workspaceStore.changeMarkupStatus(
      workspaceStore.getObjectsFromImageList(
        currSegmentIndex,
        currImageInSegmentIndex,
      ).length + formattedObjects.length
        ? MarkupStatuses.HaveMarkup
        : MarkupStatuses.NoMarkup,
      workspaceStore.segmentedImageList[currSegmentIndex][
        currImageInSegmentIndex
      ].id,
    )
    processingImagesAmount.value--
  }

  const updateObjectsAmount = (prevIndex: number, amount: number) => {
    const imageId = workspaceStore.getImages[prevIndex].id

    const imageIndexFromFilteredList =
      workspaceStore.filteredImageList.findIndex(
        (image) => image.id === imageId,
      )

    if (imageIndexFromFilteredList === -1) return

    workspaceStore.filteredImageList[
      imageIndexFromFilteredList
    ].objects_amount = amount
  }

  const lastImageIndex = computed(() => {
    return (workspaceStore.segmentedImageListOptions?.totalCount as number) - 1
  })

  const isFirstPage = computed(() => {
    return workspaceStore.currentImageIndex <= 0
  })
  const isLastPage = computed(() => {
    return workspaceStore.currentImageIndex >= lastImageIndex.value
  })

  const toNextPage = () => {
    setTimeout(() => (workspaceStore.isImageRendered = false), 0)
    if (!workspaceStore.isNoObjectsChanges) {
      workspaceStore.isCheckSaveWhenSwitchingToTheNextImage = true
      workspaceStore.сheckSaveWhenSwitchingImageIndex =
        workspaceStore.currentImageIndex
    }
    if (
      (workspaceStore.currentImageObjects.length > MAX_COPY_OBJECTS &&
        workspaceStore.copyObjectsFromPrev) ||
      isLastPage.value
    ) {
      workspaceStore.isMaxCopyObjects = true
      return
    }

    workspaceStore.currentImageIndex++
    workspaceStore.resetBinaryObjectHistory()
  }

  const toPrevPage = () => {
    if (!workspaceStore.isNoObjectsChanges) {
      workspaceStore.isCheckSaveWhenSwitchingToThePrevImage = true
      workspaceStore.сheckSaveWhenSwitchingImageIndex =
        workspaceStore.currentImageIndex
    }
    if (isFirstPage.value) {
      return
    }
    workspaceStore.isImageRendered = false
    workspaceStore.currentImageIndex--
    workspaceStore.copyObjectsFromPrev = false
    workspaceStore.resetBinaryObjectHistory()
  }

  const toFirstPage = () => {
    setTimeout(() => (workspaceStore.isImageRendered = false), 0)
    if (!workspaceStore.isNoObjectsChanges) {
      workspaceStore.isCheckSaveWhenSwitchingToThePrevImage = true
      workspaceStore.сheckSaveWhenSwitchingImageIndex =
        workspaceStore.currentImageIndex
    }
    workspaceStore.currentImageIndex = 0
    workspaceStore.copyObjectsFromPrev = false
    workspaceStore.isCopyOperationsComplete = true
    workspaceStore.resetBinaryObjectHistory()
  }

  const toLastPage = () => {
    setTimeout(() => (workspaceStore.isImageRendered = false), 0)
    if (!workspaceStore.isNoObjectsChanges) {
      workspaceStore.isCheckSaveWhenSwitchingToTheNextImage = true
      workspaceStore.сheckSaveWhenSwitchingImageIndex =
        workspaceStore.currentImageIndex
    }
    workspaceStore.currentImageIndex = lastImageIndex.value
    workspaceStore.copyObjectsFromPrev = false
    workspaceStore.isCopyOperationsComplete = true
    workspaceStore.resetBinaryObjectHistory()
  }

  const toSelectedPage = (index: number) => {
    if (workspaceStore.currentImageIndex !== index) {
      setTimeout(() => (workspaceStore.isImageRendered = false), 0)
      if (!workspaceStore.isNoObjectsChanges) {
        workspaceStore.isCheckSaveWhenSwitchingToTheNextImage = true
        workspaceStore.сheckSaveWhenSwitchingImageIndex =
          workspaceStore.currentImageIndex
      }
      workspaceStore.copyObjectsFromPrev = false
      workspaceStore.currentImageIndex = index
      workspaceStore.setShowDistance(false)
      workspaceStore.selectedTool = Tools.Arrow

      if (workspaceStore.isMaxCopyObjects) {
        workspaceStore.copyObjectsFromPrev = false
        workspaceStore.isMaxCopyObjects = false
        workspaceStore.resetBinaryObjectHistory()
      }
    }
  }

  const debouncedClearAutoCopiedObjects = useDebounceFn(() => {
    workspaceStore.autoCopiedObjects = []
  }, 1500)

  const debouncedImageChange = useDebounceFn(async (currIndex: number) => {
    workspaceStore.setCurrInd(currIndex)
    if (!workspaceStore.isNoObjectsChanges) {
      eventBus.$emit('deselectPattern')
      await saveObjects()
    }

    if (
      workspaceStore.currentTask.project?.markup_type ===
        MarkupTypes.Detection ||
      workspaceStore.currentTask.project?.markup_type ===
        MarkupTypes.Semantic ||
      workspaceStore.currentTask.project?.markup_type ===
        MarkupTypes.Panoptic ||
      workspaceStore.currentTask.project?.markup_type === MarkupTypes.Instance
    ) {
      updateObjectsAmount(currIndex, workspaceStore.currentImageObjects.length)
    }

    workspaceStore.currentImageObjects = []
    workspaceStore.annotationsFlags.readyForAnnotationSetting = true
    // await workspaceStore.getImage(workspaceStore.getImages[currIndex].id)
  }, 0)

  if (withWatcher) {
    watch(
      () => workspaceStore.currentImageIndex,
      async (currIndex, prevIndex) => {
        workspaceStore.annotationsFlags.readyForAnnotationSetting = false
        setTimeout(() => (workspaceStore.isImageRendered = false), 0)

        if (
          workspaceStore.currentTask.project?.markup_type !==
          MarkupTypes.Detection
        ) {
          await updateMarkup()
          updateObjectsAmount(
            prevIndex,
            workspaceStore.createdBinaryObjects.length,
          )
        }

        if (workspaceStore.isCurrentImageListSegmentEmpty) {
          workspaceStore.isImageListUpdating = true
          await updateImageListSegment()
          workspaceStore.isImageListUpdating = false
        }

        if (workspaceStore.copyObjectsFromPrev && currIndex > prevIndex) {
          await copyObjectsFromPrev(prevIndex, currIndex)
        }

        if (
          currIndex >=
            workspaceStore.currentImageListSegment *
              workspaceStore.segmentedImageListOptions.perPage -
              IMAGE_LIST_LENGTH_OFFSET &&
          !workspaceStore.isImageListUpdating &&
          workspaceStore.checkImageListSegmentIsEmpty(
            Math.min(
              workspaceStore.currentImageListSegmentIndex + 1,
              workspaceStore.segmentsCount - 1,
            ),
          )
        ) {
          workspaceStore.isImageListUpdating = true
          workspaceStore.segmentedImageList[
            workspaceStore.currentImageListSegmentIndex + 1
          ] = await workspaceStore.getImageListSegment(
            workspaceStore.currentTask.id,
            workspaceStore.currentImageListSegment + 1,
          )
          workspaceStore.isImageListUpdating = false
        }

        await debouncedImageChange(currIndex)
      },
    )

    watch(
      () => workspaceStore.copyObjectsFromPrev,
      (value) => {
        if (value) workspaceStore.autoCopiedObjects = []
      },
    )

    // debounce равен debounce сохранения при переходе.
    // иначе текущие объекты перезаписываются и изображение неправильно сохраняется
    watch(
      () => processingImagesAmount.value,
      useDebounceFn(async (value) => {
        if (value === 0) {
          workspaceStore.currentImageObjects =
            await workspaceStore.getImageObjects(
              workspaceStore.segmentedImageList[
                workspaceStore.currentImageListSegmentIndex
              ][workspaceStore.currentImageIndexInSegment].id,
            )
          workspaceStore.isCopyOperationsComplete = true
        }
      }, 400),
    )
  }

  const updateImageListSegment = async () => {
    workspaceStore.segmentedImageList[
      workspaceStore.currentImageListSegmentIndex
    ] = await workspaceStore.getImageListSegment(
      workspaceStore.currentTask.id,
      workspaceStore.currentImageListSegment,
    )
  }

  return {
    isFirstPage,
    isLastPage,
    toNextPage,
    toPrevPage,
    toFirstPage,
    toLastPage,
    toSelectedPage,
  }
}
