import { useWorkspaceMarkup } from '@/composables/useWorkspaceMarkup'
import { LocatedObjectAPI } from '@/api/LocatedObjectAPI'
import { ImageAPI } from '@/api/ImageAPI'
import { IAnnotation, IPoint } from '@/types/interfaces'
import {AreaType, MarkupTypes, Tools} from '@/types/enums'
import { ref } from 'vue'
import { useWorkspaceStore } from '@/store/workspaceStore'
import getAreaTypeId from '@/helpers/getAreaTypeId'
import { findRightMostTextWidth, setSizeCoefficient } from '@/helpers/workWithSvgInViewerHelper'

const useCreateNewAnnotation = () => {
  const workspaceStore = useWorkspaceStore()
  const lastShadeIndex = ref(0)
  const lastPosition = ref(1)

  const getLastIndex = () => {
    const classObjects =
      workspaceStore.currentImageFromSegmentedList?.image_classes?.find(
        (item) => item.project_class_id === workspaceStore.selectedClass.id,
      )?.classification_objects
    const sortClassObjects = classObjects?.sort((a, b) =>
      Number(a.id) > Number(b.id) ? 1 : 0,
    )
    const lastIndex =
      sortClassObjects &&
      // @ts-ignore
      sortClassObjects[sortClassObjects.length - 1]?.located_objects[0]?.shade
        ?.index

    // @ts-ignore
    lastShadeIndex.value = lastIndex === undefined ? 0 : (lastIndex + 1) % 9
    if (classObjects) lastPosition.value = classObjects.length + 1
  }

  const extractViewBoxData = (svgString: string) => {
    const pattern = /viewBox="(-?\d+\.?\d*(?:e[+-]\d+)?) (-?\d+\.?\d*(?:e[+-]\d+)?) (-?\d+\.?\d*(?:e[+-]\d+)?) (-?\d+\.?\d*(?:e[+-]\d+)?)"/
    const match = svgString.match(pattern)
    if (match) {
      const [, x, y, width, height] = match.map(Number)
      return { x, y, width, height }
    } else {
      return null
    }
  }

  const mergeWithNames = (pattern: any) => {
    // Создаем словарь имен для быстрого доступа
    // @ts-ignore
    const namesDictionary = pattern.names.reduce((dict, { id, name }) => {
      dict[id] = name
      return dict
    }, {})

    // Создаем новый объект pattern с добавлением имени в coordinates
    return {
      ...pattern,
      coordinates: pattern.coordinates.map((coordinate: IPoint) => {
        const { id } = coordinate
        if (id in namesDictionary) {
          return {
            ...coordinate,
            name: namesDictionary[id]
          };
        }
        return coordinate
      })
    }
  }

  const isAddIndex = () => {
    const markupType = workspaceStore.currentTask.project?.markup_type

    return (
      markupType === MarkupTypes.Instance ||
      markupType === MarkupTypes.Panoptic
    )
  }

  const createNewAnnotation = async (pattern: any) => {
    const data = pattern.names ? mergeWithNames(pattern) : pattern

    if (!workspaceStore.isHasImageClass()) {
      // @ts-ignore
      await workspaceStore.createImageClasses([
        // @ts-ignore
        workspaceStore?.selectedClass?.id,
      ])
    }
    const imageClass = workspaceStore.imageClasses.length
      ? workspaceStore.imageClasses.find(
          (imageClass) =>
            imageClass.project_class_id === workspaceStore.selectedClass.id,
        )
      : workspaceStore.projectClasses[0]

    if (workspaceStore.currentImageFromSegmentedList?.image_classes?.length)
      getLastIndex()

    const changedObjectsIds = [...workspaceStore.objectActionsHistory.update]

    const changedObjects = workspaceStore.currentImageObjects.filter((object) =>
      // @ts-ignore
      changedObjectsIds.includes(object.id),
    )

    const formattedObjects =
      workspaceStore.formatObjectDataForRequest(changedObjects)

    if (!workspaceStore.isHasImageClass()) {
      // @ts-ignore
      await workspaceStore.createImageClasses([workspaceStore?.selectedClass?.id])
    }

    const coords = extractViewBoxData(data.svg)

    // @ts-ignore
    const X =
      // @ts-ignore
      workspaceStore.currentImageFromSegmentedList?.width / 2 -
      // @ts-ignore
      coords?.width / 2
    // @ts-ignore
    const Y =
      // @ts-ignore
      workspaceStore.currentImageFromSegmentedList?.height / 2 -
      // @ts-ignore
      coords?.height / 2

    // @ts-ignore
    const coefficient = 48 * setSizeCoefficient(workspaceStore.currentImage.width)

    // @ts-ignore
    const dataForRequest = {
      create_attributes: {
        objects: [
          {
            area_type_id: getAreaTypeId(AreaType.Keypoints),
            id: '#adc4ebea-bbb0-4eae-9f7a-e6d100f206f7',
            // @ts-ignore
            coordinates: `{"xmin":"${X}","ymin":"${Y}","width":"${coords?.width + coefficient }","height":"${coords?.height + coefficient}"}`,
            image_class_id: imageClass?.id,
            type: 'rect',
            position: lastPosition.value,
            displaying: true,
            locking: false,
            shade_index: isAddIndex() ? lastShadeIndex.value : undefined,
            pattern_id: data.id,
            keypoint: JSON.stringify({
              coordinates: data.coordinates,
              point_links: data.point_links,
              svg: data.svg,
              name: data.name,
              id: data.id,
            }),
          },
        ],
      },
      update_attributes: {
        objects: formattedObjects.filter((object) =>
          workspaceStore.objectActionsHistory.update.includes(
            Number(object.id),
          ),
        ),
      },
      delete_attributes: {
        objects: workspaceStore.objectActionsHistory.delete,
      },
    }

    const { updateMarkup } = useWorkspaceMarkup()

    // eslint-disable-next-line no-useless-catch
    try {
      if (workspaceStore.createObjectLoader) {
        return
      }
      workspaceStore.createObjectLoader = true
      await LocatedObjectAPI.saveObjectChanges(dataForRequest)
      workspaceStore.createObjectLoader = false

      await updateMarkup(dataForRequest)
      workspaceStore.segmentedImageList[
        workspaceStore.currentImageListSegmentIndex
      ][workspaceStore.currentImageIndexInSegment] = await ImageAPI.getImage(
        workspaceStore.currentImage.id,
      )
      workspaceStore.currentImageObjects =
        workspaceStore.getObjectsFromImageList(
          workspaceStore.currentImageListSegmentIndex,
          workspaceStore.currentImageIndexInSegment,
        )

      workspaceStore.updateObjectInFilterImageList()

      workspaceStore.selectedObjectGroup = []
      workspaceStore.selectedObject = {} as IAnnotation

      workspaceStore.clearChanges()
      workspaceStore.objectActionsHistory.update = []
      workspaceStore.selectedTool = Tools.Arrow
    } catch (error) {
      throw error
    }
  }

  return { createNewAnnotation }
}

export default useCreateNewAnnotation
