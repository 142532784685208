export const fileUploaderStyleConfig = {
  labelColor: '#A0AEC0',
  uploaderLabelsFontWeight: 400,

  // fontFamily: 'Open Sans',
  // fileBackgroundColor: '#f7fafc',
  // fileBorderRadius: '4px',
  // fileIconColor: '#a0aec0',
  // fileNameFontWeight: 500,
  // fileNameFontSize: '12px',
  // fileNameColor: '#2d3748',
  // fileSizeFontWeight: 500,
  // fileSizeColor: '#a0aec0',
  // labelColor: '#4a5568',
  // labelFontWeight: 500,
  // labelFontSize: '14px',
  // helperTextColor: '#a0aec0',
  // helperTextFontWeight: 500,
  // helperTextFontSize: '12px',
  // borderColor: '#cbd5e0',
  // borderRadius: '4px',
  // iconColor: '#718096',
  // dropLabelColor: '#718096',
  // browseLabelColor: '#3385ff',
  // uploaderLabelsFontWeight: 500,
  // uploderLabelsFontSize: '14px',
}
