import { EgalActionConstructor } from '@/helpers/EgalActionConstructor'
import { APIModels, Microservices } from '@/types/enums'
import { IDataset, IDatasetForCreation } from '@/types/interfaces'
import { logout } from '@/helpers/logout'

class DatasetAPIModel {
  async getDatasets(): Promise<IDataset[]> {
    return EgalActionConstructor.getItems(Microservices.Core, APIModels.Dataset)
      .setPagination(100, 1)
      .order([['created_at', 'desc']])
      .call()
      .then((response) => {
        return response.items
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }

  async getDataset(datasetId: number): Promise<IDataset> {
    return EgalActionConstructor.getItem(
      Microservices.Core,
      APIModels.Dataset,
      datasetId,
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        {
          logout(error)
          throw error
        }
      })
  }

  async createDataset(datasetInfo: IDatasetForCreation): Promise<IDataset> {
    return EgalActionConstructor.create(
      Microservices.Core,
      APIModels.Dataset,
      datasetInfo,
    )
      .call()
      .then((response) => {
        return response
      })
      .catch((error) => {
        logout(error)
        throw error
      })
  }
}

export const DatasetApi = new DatasetAPIModel()
