import { useDebounceFn, useMagicKeys } from '@vueuse/core'
import { onUnmounted, ref, Ref, watch } from 'vue'
import { useWorkspaceImagePlayer } from '@/composables/useWorkspaceImagePlayer'
import { useWorkspaceStore } from '@/store/workspaceStore'
import { useWorkspaceContextMenu } from '@/composables/useWorkspaceContextMenu'
import { MarkupTypes, Tools } from '@/types/enums'
import OpenSeadragon from 'openseadragon'

export const useWorkspaceHotkeys = (viewer: Ref<OpenSeadragon.Viewer>) => {
  const imagePlayer = useWorkspaceImagePlayer()
  const workspaceStore = useWorkspaceStore()
  const contextMenu = useWorkspaceContextMenu(viewer)

  let middleClickPosition: OpenSeadragon.Point | null
  const isCtrlPressed = ref(false)
  const mousePosition = {
    x: 0,
    y: 0,
  }

  const {
    ctrl,
    ctrl_shift_left,
    ctrl_shift_right,
    ctrl_left,
    ctrl_right,
    ctrl_z,
    ctrl_shift_z,
    ctrl_s,
    ctrl_c,
    ctrl_v,
    Delete,
    ctrl_shift_r,
    v,
    h,
    ctrl_a,
    c,
    ctrl_o,
    ctrl_space,
    ctrl_shift_space,
    r,
    p,
    i,
    n,
    d,
  } = useMagicKeys({
    passive: false,
    onEventFired(e) {
      if (
        (e.ctrlKey && e.key === 's' && e.type === 'keydown') ||
        (e.ctrlKey && e.shiftKey && e.key === 'R' && e.type === 'keydown') ||
        (e.ctrlKey && e.key === 'a' && e.type === 'keydown') ||
        (e.ctrlKey && e.key === 'o' && e.type === 'keydown')
      )
        e.preventDefault()
    },
  })

  // Для пересекающихся комбинаций, таких как ctrl + shit + left и ctrl + left
  let isAlternateComboPressed = false

  watch(
    () => viewer.value,
    (value) => {
      if (Object.keys(value).length) {
        new OpenSeadragon.MouseTracker({
          element: viewer.value.element,
          moveHandler: (event: any) => {
            mousePosition.x = event.position.x
            mousePosition.y = event.position.y

            if (middleClickPosition) {
              dragByMiddleClick(event)
            }
          },
          leaveHandler: () => {
            middleClickPosition = null
          },
        })

        viewer.value.addHandler('canvas-nonprimary-press', onMiddlePress)
        viewer.value.addHandler('canvas-nonprimary-release', onMiddleRelease)
      }
    },
  )
  onUnmounted(() => {
    viewer.value.removeHandler('canvas-nonprimary-press', onMiddlePress)
    viewer.value.removeHandler('canvas-nonprimary-release', onMiddleRelease)
    viewer.value.removeHandler('canvas-scroll', onScroll)
  })

  // General
  watch(ctrl_shift_left, (value) => {
    if (value && !imagePlayer.isFirstPage.value) {
      imagePlayer.toFirstPage()
      workspaceStore.resetBinaryObjectHistory()
    }
    isAlternateComboPressed = true
  })
  watch(ctrl_shift_right, (value) => {
    if (value && !imagePlayer.isLastPage.value) {
      imagePlayer.toLastPage()
      workspaceStore.resetBinaryObjectHistory()
    }
    isAlternateComboPressed = true
  })
  let ctrlLeftTimeout: NodeJS.Timeout | null = null // Переменная для хранения таймаута
  let isCtrlLeftExecuting = false // Переменная для отслеживания состояния выполнения функции

  watch(ctrl_left, (value) => {
    if (isCtrlLeftExecuting) {
      return // Если функция уже выполняется, прерываем выполнение
    }
    isCtrlLeftExecuting = true // Устанавливаем флаг выполнения функции
    // @ts-ignore
    clearTimeout(ctrlLeftTimeout) // Очищаем предыдущий таймаут

    ctrlLeftTimeout = setTimeout(() => {
      if (value && !imagePlayer.isFirstPage.value && !isAlternateComboPressed) {
        imagePlayer.toPrevPage()
        workspaceStore.resetBinaryObjectHistory()
      }
      isAlternateComboPressed = false

      isCtrlLeftExecuting = false // Сбрасываем флаг выполнения функции
    }, 800)
  })

  let ctrlRightTimeout: NodeJS.Timeout | null = null // Переменная для хранения таймаута
  let isCtrlRightExecuting = false // Переменная для отслеживания состояния выполнения функции

  watch(ctrl_right, (value) => {
    if (isCtrlRightExecuting) {
      return // Если функция уже выполняется, прерываем выполнение
    }

    isCtrlRightExecuting = true // Устанавливаем флаг выполнения функции

    // @ts-ignore
    clearTimeout(ctrlRightTimeout) // Очищаем предыдущий таймаут

    ctrlRightTimeout = setTimeout(() => {
      if (
        value &&
        !imagePlayer.isLastPage.value &&
        !isAlternateComboPressed &&
        workspaceStore.canGoToNextImage
      ) {
        imagePlayer.toNextPage()
        workspaceStore.resetBinaryObjectHistory()
      }
      isAlternateComboPressed = false

      isCtrlRightExecuting = false // Сбрасываем флаг выполнения функции
    }, 800)
  })
  watch(ctrl_z, (value) => {
    setTimeout(() => {
      // drop selected if exists. on next handling go to prev history record
      if (
        'id' in workspaceStore.selectedObject ||
        workspaceStore.selectedObjectGroup.length
      ) {
        workspaceStore.dropSelectedObjects()
        return
      }
      if (value && workspaceStore.hasPrevStates && !isAlternateComboPressed) {
        workspaceStore.goToPrevState()
      }
      isAlternateComboPressed = false
    })
  })
  watch(ctrl_shift_z, (value) => {
    if (value && workspaceStore.hasNextStates) {
      workspaceStore.goToNextState()
    }
    isAlternateComboPressed = true
  })
  watch(ctrl_s, (value) => {
    if (
      value &&
      workspaceStore.currentTask.project?.markup_type === MarkupTypes.Detection
    ) {
      workspaceStore.saveChanges().then()
    }
  })
  watch(ctrl_c, (value) => {
    if (value && Object.keys(workspaceStore.selectedObject).length) {
      contextMenu.copyObjects()
    }
    isAlternateComboPressed = true
  })
  watch(ctrl_v, (value) => {
    if (value) contextMenu.insertCopiedObject(mousePosition.x, mousePosition.y)
  })
  watch(Delete, (value) => {
    if (value && Object.keys(workspaceStore.selectedObject).length) {
      contextMenu.deleteObject(workspaceStore.selectedObject)
    }
  })
  watch(ctrl_shift_r, (value) => {
    if (value && Object.keys(workspaceStore.selectedObject).length) {
      workspaceStore.selectedContextMenuObject = workspaceStore.selectedObject
      workspaceStore.annotationsFlags.classChanging = true
    }
    isAlternateComboPressed = true
  })

  // Tools
  watch(p, (value) => {
    if (value && workspaceStore.selectedTool !== Tools.Dots) {
      const markupType = workspaceStore.currentTask.project?.markup_type
      if (
        [
          MarkupTypes.Semantic,
          MarkupTypes.Instance,
          MarkupTypes.Panoptic,
          // @ts-ignore
        ].includes(markupType)
      ) {
        workspaceStore.selectedTool = Tools.Pentagon
      }
    }
  })
  watch(i, (value) => {
    if (value && workspaceStore.selectedTool !== Tools.Dots) {
      const markupType = workspaceStore.currentTask.project?.markup_type
      if (
        [
          MarkupTypes.Semantic,
          MarkupTypes.Instance,
          MarkupTypes.Panoptic,
          // @ts-ignore
        ].includes(markupType)
      ) {
        workspaceStore.selectedTool = Tools.Identificator
      }
    }
  })
  watch(n, (value) => {
    if (value && workspaceStore.selectedTool !== Tools.Dots) {
      const markupType = workspaceStore.currentTask.project?.markup_type
      if (
        [
          MarkupTypes.Semantic,
          MarkupTypes.Instance,
          MarkupTypes.Panoptic,
          // @ts-ignore
        ].includes(markupType)
      ) {
        workspaceStore.selectedTool = Tools.Interactor
      }
    }
  })
  watch(d, (value) => {
    if (value && workspaceStore.selectedTool !== Tools.Dots) {
      const markupType = workspaceStore.currentTask.project?.markup_type
      const validMarkupTypes = [
        MarkupTypes.Keypoints,
        MarkupTypes.Detection,
        MarkupTypes.Semantic,
        MarkupTypes.Instance,
        MarkupTypes.Panoptic,
      ]

      if (validMarkupTypes.includes(<MarkupTypes>markupType)) {
        workspaceStore.selectedTool = Tools.Dots
      }
    }
  })
  watch(v, (value) => {
    if (value && workspaceStore.selectedTool !== Tools.Dots) {
      workspaceStore.selectedTool = Tools.Arrow
    }
  })
  watch(h, (value) => {
    if (value && workspaceStore.selectedTool !== Tools.Dots) {
      workspaceStore.selectedTool = Tools.Hand
    }
  })
  watch(ctrl_a, (value) => {
    if (value && workspaceStore.selectedTool !== Tools.Dots) {
      workspaceStore.selectedTool = Tools.SelectAll
    }
  })

  let prevTool: Tools | null
  watch(c, (value) => {
    setTimeout(() => {
      if (workspaceStore.selectedTool !== Tools.Dots) {
        if (value && !isAlternateComboPressed) {
          if (workspaceStore.selectedTool !== Tools.Commentary) {
            prevTool = workspaceStore.selectedTool
            workspaceStore.selectedTool = Tools.Commentary
          } else {
            workspaceStore.selectedTool = prevTool as Tools
            prevTool = null
          }
        }
        isAlternateComboPressed = false
      }
    })
  })
  watch(ctrl_o, (value) => {
    if (value) {
      workspaceStore.selectedTool = Tools.FullSize
    }
  })
  watch(ctrl_space, (value) => {
    setTimeout(() => {
      if (value && !isAlternateComboPressed) {
        viewer.value.viewport.zoomTo(
          viewer.value.viewport.getZoom() *
            workspaceStore.viewerProperties.zoomMagnifier,
        )

        workspaceStore.updateZoomLevel(1)
      }
      isAlternateComboPressed = false
    })
  })
  watch(ctrl_shift_space, (value) => {
    if (value) {
      viewer.value.viewport.zoomTo(
        viewer.value.viewport.getZoom() /
          workspaceStore.viewerProperties.zoomMagnifier,
      )

      workspaceStore.updateZoomLevel(-1)
    }
    isAlternateComboPressed = true
  })
  watch(r, (value) => {
    if (
      workspaceStore.selectedTool !== Tools.Dots &&
      value &&
      !isAlternateComboPressed
    ) {
      const markupType = workspaceStore.currentTask.project?.markup_type

      if (markupType === MarkupTypes.Detection) {
        workspaceStore.selectedTool = Tools.BoundingBox
      }
    }
    isAlternateComboPressed = false
  })

  // Image hotkeys
  watch(ctrl, (value) => {
    isCtrlPressed.value = value
  })

  const onMiddlePress = (event: OpenSeadragon.CanvasNonPrimaryButtonEvent) => {
    if (event.button === 1) {
      middleClickPosition = event.position.clone()
    }
  }
  const onMiddleRelease = (
    event: OpenSeadragon.CanvasNonPrimaryButtonEvent,
  ) => {
    if (event.button === 1) {
      middleClickPosition = null
    }
  }

  const dragByMiddleClick = (event: OpenSeadragon.PointerMouseTrackerEvent) => {
    if (!middleClickPosition) return

    const deltaPixels = middleClickPosition.minus(event.position)
    const deltaPoints = viewer.value.viewport.deltaPointsFromPixels(deltaPixels)
    viewer.value.viewport.panBy(deltaPoints)
    middleClickPosition = event.position.clone()
  }

  watch(isCtrlPressed, () => {
    if (isCtrlPressed.value) {
      viewer.value.addHandler('canvas-scroll', onScroll)
    } else {
      viewer.value.removeHandler('canvas-scroll', onScroll)
    }
  })

  const onScroll = (event: OpenSeadragon.CanvasScrollEvent) => {
    const isZoomIn = event.scroll > 0

    if (workspaceStore.viewerProperties.zoomLevel === 0 && !isZoomIn) {
      return
    }

    viewer.value.viewport.zoomTo(
      isZoomIn
        ? viewer.value.viewport.getZoom() *
            workspaceStore.viewerProperties.zoomMagnifier
        : viewer.value.viewport.getZoom() /
            workspaceStore.viewerProperties.zoomMagnifier,
    )
    workspaceStore.updateZoomLevel(isZoomIn ? 1 : -1)
  }
}
