import { defineStore } from 'pinia'
import {
  IImage,
  IOrganizationMember,
  IProject,
  ITask,
  ITasksWithProject,
} from '@/types/interfaces'
import { TaskAPI } from '@/api/TaskAPI'
import { OrganizationAPI } from '@/api/OrganizationAPI'
import { TaskStatuses } from '@/types/enums'
import { ImageAPI } from '@/api/ImageAPI'
import { useCrypto } from '@/composables/useCrypto'
import { useFileConverter } from '@/composables/useFileConverter'
import { useImageProcessor } from '@/composables/useImageProcessor'
import { ImageClassesStatistic, ImageStatistic } from '@/types/types'
import UTIF from 'utif'
import { IMAGES_UPLOAD_PROGRESS } from '@/helpers/globalConst'
import router from '@/router'
import { errorDictionary } from '@/helpers/errorDictionary'

type TaskProject = Pick<IProject, 'id' | 'name' | 'organization_id'>

const { base64ToSHA256 } = useCrypto()
const { fileToBlob, blobToBase64 } = useFileConverter()
const { preprocessBeforeUpload, compressImage } = useImageProcessor()

interface TaskStoreState {
  projectWithTasks: ITasksWithProject[]
  createTaskData: Partial<ITask>
  createTaskDataset: any[]
  createTaskDatasetApiErrors: string[]
  createTaskRequestDataset: any[]
  uploadedImagesCount: number
  currentTask: ITask
  organizationExecutors: IOrganizationMember[]
  totalCount: number
  perPage: number
  page: number
  taskStatistic: ImageStatistic
  taskClassesStatistic: ImageClassesStatistic
  taskLogo: any
  lastUploadedBlockIndex: number
  isTasksLoad: boolean
  isTaskLoadingMore: boolean
}

export const useTaskStore = defineStore('taskStore', {
  state: (): TaskStoreState => {
    return {
      projectWithTasks: [],
      createTaskData: {},
      createTaskDataset: [],
      createTaskDatasetApiErrors: [],
      createTaskRequestDataset: [],
      uploadedImagesCount: 0,
      currentTask: {} as ITask,
      organizationExecutors: [],
      totalCount: 0,
      perPage: 40,
      page: 1,
      taskStatistic: {
        checkup_statistic: [],
        markup_statistic: [],
        count: [{ count: 0 }],
        to_delete: 0,
        problem: 0,
      },
      taskClassesStatistic: { image_classes_statistic: [] },
      taskLogo: null,
      lastUploadedBlockIndex: 0,
      isTasksLoad: false,
      isTaskLoadingMore: false,
    }
  },

  actions: {
    clearCurrentTask() {
      this.currentTask = {} as ITask
    },
    async getTasks(
      taskName = '',
      filterByStatus: TaskStatuses | '' = '',
    ): Promise<void> {
      this.isTasksLoad = true
      const filter = [{ field: 'name', operator: 'coi', value: taskName }]

      if (filterByStatus) {
        filter.push({ field: 'status', operator: 'eq', value: filterByStatus })
      }

      await TaskAPI.getTasks(
        {
          page: this.page,
          perPage: this.perPage,
        },
        filter,
      )
        .then((response) => {
          const projects = this.getProjectsFromTasks(response.items)
          this.projectWithTasks = this.formatTaskData(projects, response.items)
          this.totalCount = response.total_count
          this.isTasksLoad = false
        })
        .catch((error) => {
          console.log(error)
        })
    },

    async getTask(id: number): Promise<void> {
      return TaskAPI.getTask(id)
        .then((response) => {
          this.currentTask = response
        })
        .catch((error) => {
          throw error
        })
    },

    getProjectsFromTasks(tasks: ITask[]): TaskProject[] {
      const projects: TaskProject[] = []

      tasks.forEach((task) => {
        const isUnique = !projects.some(
          (project) => project.id === task.project_id,
        )

        if (isUnique) {
          projects.push({
            id: task.project_id,
            name: task.project?.name || '',
            organization_id:
              task.project?.organization_id || (undefined as unknown as number),
          })
        }
      })

      return projects
    },

    formatTaskData(
      projects: TaskProject[],
      tasks: ITask[],
    ): ITasksWithProject[] {
      return projects.map((project) => {
        const filteredTasks = tasks.filter(
          (task) => task.project_id === project.id,
        )

        return {
          project: project,
          tasks: filteredTasks,
        }
      })
    },

    async loadMoreTasks(
      taskName = '',
      filterByStatus: TaskStatuses | '' = '',
    ): Promise<void> {
      const filter = [{ field: 'name', operator: 'coi', value: taskName }]
      this.isTaskLoadingMore = true
      if (filterByStatus) {
        filter.push({ field: 'status', operator: 'eq', value: filterByStatus })
      }
      await TaskAPI.getTasks(
        {
          page: this.page,
          perPage: this.perPage,
        },
        filter,
      )
        .then((response) => {
          const projects = this.getProjectsFromTasks(response.items)
          this.projectWithTasks.push(
            ...this.formatTaskData(projects, response.items),
          )
          this.totalCount = response.total_count
          this.isTaskLoadingMore = false
        })
        .catch((error) => {
          console.log(error)
        })
    },

    async createTaskFirstStep(taskData: Partial<ITask>): Promise<number> {
      const taskDataPayload = { ...taskData }
      taskDataPayload.task_type = taskDataPayload.task_type?.toLowerCase()

      return await TaskAPI.createTask(taskDataPayload)
        .then(async (resp) => {
          await this.uploadLogo(resp.uploadLink)

          return resp.id
        })
        .catch((error) => {
          throw error
        })
    },

    async updateTaskFirstStep(taskData: Partial<ITask>): Promise<number> {
      const taskDataPayload = { ...taskData }
      taskDataPayload.task_type = taskDataPayload.task_type?.toLowerCase()

      return await TaskAPI.updateTask(taskDataPayload)
        .then(async (resp) => {
          await this.uploadLogo(resp.uploadLink)
          return resp.id
        })
        .catch((error) => {
          throw error
        })
    },

    async uploadLogo(link: string) {
      if (this.taskLogo) {
        const logoObj = {
          image: await preprocessBeforeUpload(this.taskLogo.file.file),
          link: link,
        }

        await ImageAPI.uploadImageToCloud(logoObj.link, logoObj.image)

        this.taskLogo = null
      }
    },

    async createTaskSecondStep(taskData: Partial<ITask>): Promise<number> {
      const taskDataPayload = taskData
      taskDataPayload.task_type = taskDataPayload.task_type?.toLowerCase()

      return await TaskAPI.updateTask(taskData)
        .then((resp) => {
          return resp.id
        })
        .catch((error) => {
          throw error
        })
    },

    async updateTaskSecondStep(taskData: Partial<ITask>): Promise<number> {
      const taskDataPayload = taskData
      taskDataPayload.task_type = taskDataPayload.task_type?.toLowerCase()

      return await TaskAPI.updateTask(taskData)
        .then((resp) => {
          return resp.id
        })
        .catch((error) => {
          throw error
        })
    },

    // TODO: Добавить тип для ответа с CustomUploader
    splitImagesToBlocks(images: any[]) {
      const BLOCK_SIZE = 25
      const blocksAmount = Math.ceil(images.length / BLOCK_SIZE)
      const blocks: any[][] = []

      for (let blockIndex = 0; blockIndex < blocksAmount; blockIndex++) {
        blocks.push([])
        for (
          let imageIndex = blockIndex * BLOCK_SIZE;
          imageIndex < blockIndex * BLOCK_SIZE + BLOCK_SIZE &&
          imageIndex < images.length;
          imageIndex++
        ) {
          blocks[blockIndex].push(images[imageIndex])
        }
      }

      return blocks
    },

    async createManyImagesInNewTask(images: any) {
      const formattedImages = await this.formatImagesForRequest(images)

      await ImageAPI.createImages(formattedImages)
        .then(async (response) => {
          const imagesForUploading: {
            id: number
            originalImage: Blob
            zipImage: Blob
            miniatureImage: Blob
            originalLink: string
            zipLink: string
            miniatureLink: string
          }[] = await Promise.all(
            response.map(async (image: IImage, index: number) => {
              const preprocessedImage = await preprocessBeforeUpload(
                images[index].file,
              )
              return {
                id: image.id,
                originalImage: images[index].file,
                zipImage: preprocessedImage,
                miniatureImage: await compressImage(preprocessedImage, {
                  width: 36,
                  height: 36,
                  resize: 'cover',
                }),
                originalLink: image.uploadLink,
                zipLink: image.uploadZipLink,
                miniatureLink: image.uploadMiniatureLink,
              }
            }),
          )

          await this.uploadImagesToCloud(imagesForUploading)
          return
        })
        .catch((error) => {
          throw error
        })
    },

    async createTaskThirdStep(blocks: any[][]): Promise<any> {
      this.lastUploadedBlockIndex = 0

      for (
        this.lastUploadedBlockIndex;
        this.lastUploadedBlockIndex < blocks.length;
        this.lastUploadedBlockIndex++
      ) {
        const images = blocks[this.lastUploadedBlockIndex]

        if (!images.length) continue

        await this.createManyImagesInNewTask(images).catch(async (error) => {
          if (error.code === 405) {
            this.createTaskDatasetApiErrors = []
            this.createTaskDatasetApiErrors.push(...JSON?.parse(error.message))

            if (!this.createTaskDataset[this.lastUploadedBlockIndex]) {
              return
            }

            this.createTaskDataset[this.lastUploadedBlockIndex] =
              this.createTaskDataset[this.lastUploadedBlockIndex].filter(
                (file: any) => {
                  return !this.createTaskDatasetApiErrors.includes(
                    file.name.replaceAll(' ', '_'),
                  )
                },
              )

            const images = this.createTaskDataset[this.lastUploadedBlockIndex]

            if (images.length === 0) {
              return
            }

            await this.createManyImagesInNewTask(images).catch(() => {
              return
            })
          }
        })
      }
    },

    async formatImagesForRequest(images: any[]) {
      const requestData = []

      for (let i = 0; i < images.length; i++) {
        let width = 0
        let height = 0

        const blob = await fileToBlob(images[i].file)

        let base64 = (await blobToBase64(blob)) as string
        base64 = base64.split(',')[1]

        if (images[i].type === 'image/tiff') {
          const blob: Blob = await fileToBlob(images[i].file)
          const arrayBuffer = await blob.arrayBuffer()
          const ifd = UTIF.decode(arrayBuffer)
          UTIF.decodeImage(arrayBuffer, ifd[0])

          width = ifd[0].width
          height = ifd[0].height
        } else {
          const dimensions = await this.getImageDimensions(base64)
          width = dimensions.width
          height = dimensions.height
        }

        // const hash = await base64ToSHA256(base64)
        const hash = await base64ToSHA256(images[i].name)

        requestData.push({
          name: images[i].name,
          description: '',
          hash: hash,
          width: width,
          height: height,
          task_id: this.createTaskData.id,
          image_size: images[i].size,
        })
      }

      return requestData
    },

    async uploadImageToCloud(image: { link: string; image: Blob }) {
      return ImageAPI.uploadImageToCloud(image.link, image.image).catch(
        (error) => {
          throw error
        },
      )
    },

    async uploadImagesToCloud(
      images: {
        id: number
        originalImage: Blob
        zipImage: Blob
        miniatureImage: Blob
        originalLink: string
        zipLink: string
        miniatureLink: string
      }[],
    ) {
      for (const image of images) {
        await Promise.all([
          await ImageAPI.uploadImageToCloud(
            image.originalLink,
            image.originalImage,
          ),
          await ImageAPI.uploadImageToCloud(image.zipLink, image.zipImage),
          await ImageAPI.uploadImageToCloud(
            image.miniatureLink,
            image.miniatureImage,
          ),
        ])
        await ImageAPI.updateImage({
          id: image.id,
          is_uploaded: true,
        })

        this.uploadedImagesCount++
      }
    },

    async getImageDimensions(
      base64: string,
    ): Promise<{ width: number; height: number }> {
      return new Promise((resolve) => {
        const image = new Image()
        image.src = 'data:image/*;base64, ' + base64
        image.onload = () => {
          resolve({
            width: image.naturalWidth,
            height: image.naturalHeight,
          })
        }
      })
    },

    async getExecutorsFromOrganization(organizationId: number): Promise<void> {
      return OrganizationAPI.getOrganization(organizationId).then((resp) => {
        this.organizationExecutors = resp.executors
      })
    },

    async sendInviteToExecutor(
      organizationId: number,
      executorEmail: string,
    ): Promise<void> {
      return OrganizationAPI.addExecutor(organizationId, executorEmail).then(
        () => {
          this.getExecutorsFromOrganization(organizationId)
        },
      )
    },

    async getTaskStatistic(taskId: number) {
      await TaskAPI.getTaskStatistic(taskId).then((response) => {
        this.taskStatistic = response.image_statistic
        this.taskClassesStatistic = response.image_classes_statistic
      })
    },

    async changeTaskStatus(id: number, status: TaskStatuses): Promise<void> {
      return TaskAPI.updateTask({ id, status }).catch((error) => {
        throw error
      })
    },

    async deleteTask(id: number) {
      return await TaskAPI.deleteTask(id)
        .then(() => {
          return
        })
        .catch((error) => {
          throw error
        })
    },
  },

  getters: {
    getUploadProgress(state) {
      const progress =
        (state.uploadedImagesCount / state.createTaskDataset.flat().length) *
        100
      const { organizationId, projectId } = router.currentRoute.value.query

      if (progress === 100 || isNaN(progress)) {
        localStorage.removeItem(IMAGES_UPLOAD_PROGRESS)
      } else {
        localStorage.setItem(
          IMAGES_UPLOAD_PROGRESS,
          JSON.stringify({
            progress: progress.toString(),
            route: `/create-task?mode=edit&taskId=${state.createTaskData.id}&organizationId=${organizationId}&projectId=${projectId}`,
          }),
        )
      }

      return progress
    },
    getTasksLoad: (state) => state.isTasksLoad,
    getTasksLoadingMore: (state) => state.isTaskLoadingMore,
  },
})
