export const textAreaStyleConfig = {
  fontFamily: 'Inter',
  valueColor: '#2d3748',
  valueFontWeight: 400,
  placeholderColor: '#a0aec0',
  backgroundColor: '#ffffff',
  labelColor: '#A0AEC0',
  labelFontWeight: 400,
  helperTextColor: '#a0aec0',
  helperTextFontWeight: 400,
  borderColor: '#edf2f7',
  //  TODO: border-radius не меняется. Баг виджетов. Нужно issue
  borderRadius: '4px',
  focusBorderColor: '#76ACFB',
  errorColor: '#f16063',
}
